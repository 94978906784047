import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {Presenter} from '../support/presenter/presenter';
import {Macro, MacroExternalType} from '../appraising/models/macro';
import {CompositeSubscription} from '../support/composite_subscription';
import {MacroInteractor} from '../appraising/business/macro_interactor';

export type ReasoningType = {
    reasoning: string;
    nearOffice: boolean;
    nearLiving: boolean;
};

export class ReasoningInputPresenter implements Presenter {
    @observable private reasoning: ReasoningType | null = null;
    @observable.ref public macros?: Macro[];

    @observable.ref private defaultValue: ReasoningType | null;

    private subscriptions = new CompositeSubscription();

    constructor(
        private macroInteractor: MacroInteractor,
        defaultValue: ReasoningType | null,
        private onChangeProp: (value: ReasoningType) => void
    ) {
        this.defaultValue = defaultValue;

        makeObservable(this);
    }

    public mount() {
        this.subscriptions.add(
            this.macroInteractor
                .macrosForExternalType(MacroExternalType.LOCAL_KNOWLEDGE_REASONING)
                .subscribe((macros) => {
                    runInAction(() => {
                        this.macros = macros;
                    });
                })
        );
    }

    public unmount() {
        this.subscriptions.clear();
    }

    @action
    public onUpdatedProps(defaultValue: ReasoningType | null): void {
        this.defaultValue = defaultValue;
    }

    @action
    public onChange(value: Partial<ReasoningType>) {
        if (this.reasoning === null) {
            this.reasoning = {
                reasoning: this.value?.reasoning ?? '',
                nearOffice: this.value?.nearOffice ?? false,
                nearLiving: this.value?.nearLiving ?? false,
                ...value,
            };
        } else {
            this.reasoning = {
                ...this.reasoning,
                ...value,
            };
        }

        this.onChangeProp(this.reasoning);
    }

    @computed
    public get value() {
        if (this.reasoning !== null) {
            return this.reasoning;
        } else {
            return this.defaultValue;
        }
    }

    public onAddAsMacroClick = async () => {
        if (this.value?.reasoning) {
            try {
                await this.macroInteractor.storeExternal(
                    this.value?.reasoning,
                    MacroExternalType.LOCAL_KNOWLEDGE_REASONING
                );
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };

    public onRemoveMacroClick = async (toBeRemovedMacro: Macro) => {
        if (this.macros !== undefined) {
            try {
                const macro = this.macros.find((m) => m.id === toBeRemovedMacro.id);
                if (macro !== undefined) {
                    await this.macroInteractor.destroy(macro.id);
                }
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };
}
