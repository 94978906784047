import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../support/composite_subscription';
import {Presenter} from '../support/presenter/presenter';
import {FlashMessageBroadcaster, Type} from '../appraising/business/flash_message_broadcaster';
import {DistrictInfo} from '../appraising/models/neighborhood';
import {NeighborhoodApi} from '../appraising/network/neighborhood_api';
import {AppraiserDistrictsPresenter} from './appraiser_districts_presenter';
import {ReasoningType} from './reasoning_input_presenter';
import {getUserAddress} from './user_address';

export interface RangeAddressOptions {
    type: 'user' | 'custom';
    postalCode: string;
    houseNumber: string;
    letter: string;
}

export class RangeModalPresenter implements Presenter {
    private _subscriptions = new CompositeSubscription();

    @observable public range = 20;
    @observable public addressOptions: RangeAddressOptions;

    @observable public foundDistricts: DistrictInfo[] | null = null;
    @observable public isSearching = false;
    @observable public excludedDistricts: Set<string> = new Set();
    @observable public reasoningValue: ReasoningType | null = null;
    @observable public reasoningValidationErrorShown = false;

    @observable
    private searchTimeout?: NodeJS.Timeout;

    @observable
    public addedCodes: Set<string>;

    constructor(
        private neighborhoodApi: NeighborhoodApi,
        private flashMessageBroadcaster: FlashMessageBroadcaster,
        addedCodes: Set<string>
    ) {
        this.addedCodes = addedCodes;

        const userAddress = getUserAddress();

        this.addressOptions = userAddress
            ? {
                  type: 'user',
                  postalCode: userAddress.postalCode,
                  houseNumber: userAddress.houseNumber,
                  letter: userAddress.letter ?? '',
              }
            : {
                  type: 'custom',
                  postalCode: '',
                  houseNumber: '',
                  letter: '',
              };

        makeObservable(this);
    }

    public mount(): void {
        // Trigger immediate search based on defaults
        this.updateRange();
    }

    public unmount(): void {
        this._subscriptions.clear();
    }

    @action
    public onUpdatedProps(addedCodes: Set<string>): void {
        this.addedCodes = addedCodes;
    }

    @computed
    public get foundDistrictsByMunicipality():
        | {
              code: string;
              name: string;
              newCodes: string[];
              districts: DistrictInfo[];
          }[]
        | null {
        if (!this.foundDistricts) {
            return null;
        }

        const districtsByMunicipality = new Map<string, {name: string; districts: DistrictInfo[]}>();

        for (const info of this.foundDistricts) {
            const municipalityCode = info.gemeentecode;
            const municipality = districtsByMunicipality.get(municipalityCode);

            if (municipality) {
                municipality.districts.push(info);
            } else {
                districtsByMunicipality.set(municipalityCode, {
                    name: info.gemeentenaam,
                    districts: [info],
                });
            }
        }

        const result = Array.from(districtsByMunicipality.entries()).map(([code, {name, districts}]) => ({
            code,
            name,
            districtsCount: districts.length,
            newCodes: districts
                .filter((district) => !this.addedCodes.has(district.wijkcode))
                .map((district) => district.wijkcode),
            districts,
        }));

        result.sort((a, b) => a.name.localeCompare(b.name));

        for (const entry of result) {
            entry.districts.sort((a, b) => a.wijknaam.localeCompare(b.wijknaam));
        }

        return result;
    }

    @computed
    public get reasoningDefaultValue() {
        if (this.reasoningValue !== null) {
            return this.reasoningValue;
        }

        if (this.addressOptions.type === 'user' && this.range) {
            return {
                reasoning: `Taxateur verklaart kundig en plaatselijk bekend te zijn met de directe omgeving van het te taxeren object. Taxateur is tevens gevestigd binnen een straal van ${this.range} km van de wijk van het te taxeren object, en is van mening over voldoende informatie te beschikken om een waardering van het object te kunnen vaststellen.`,
                nearOffice: true,
                nearLiving: false,
            };
        }

        return null;
    }

    @computed
    public get isSearchPending() {
        return this.searchTimeout !== undefined;
    }

    @computed
    public get codesToAdd(): string[] {
        return this.foundDistricts
            ? this.foundDistricts
                  .filter(
                      (district) =>
                          !this.excludedDistricts.has(district.wijkcode) && !this.addedCodes.has(district.wijkcode)
                  )
                  .map((district) => district.wijkcode)
            : [];
    }

    @action
    public toggleDistrictExclude(code: string): void {
        if (this.excludedDistricts.has(code)) {
            this.excludedDistricts.delete(code);
        } else {
            this.excludedDistricts.add(code);
        }
    }

    @action
    public updateRange(address?: Partial<RangeAddressOptions>, range?: number): void {
        if (range !== undefined) {
            this.range = range;
        }

        if (address) {
            if (address.type) {
                const userAddress = getUserAddress();

                if (address.type === 'custom') {
                    this.addressOptions = {
                        type: 'custom',
                        postalCode: '',
                        houseNumber: '',
                        letter: '',
                    };
                } else if (address.type === 'user' && userAddress) {
                    this.addressOptions = {
                        type: 'user',
                        postalCode: userAddress.postalCode,
                        houseNumber: userAddress.houseNumber,
                        letter: userAddress.letter ?? '',
                    };
                }
            } else {
                this.addressOptions = {
                    ...this.addressOptions,
                    ...address,
                };
            }
        }

        const search = () => {
            runInAction(() => {
                this.isSearching = true;
                clearTimeout(this.searchTimeout);
                this.searchTimeout = undefined;
            });
            this.neighborhoodApi
                .findByRange(
                    this.addressOptions.postalCode,
                    this.addressOptions.houseNumber,
                    this.addressOptions.letter || null,
                    this.range,
                    AppraiserDistrictsPresenter.COLUMNS,
                    ['district']
                )
                .then((result) => {
                    runInAction(() => {
                        result.districts.sort((a, b) => {
                            return a.gemeentenaam.localeCompare(b.gemeentenaam) || a.wijknaam.localeCompare(b.wijknaam);
                        });
                        this.foundDistricts = result.districts;
                    });
                })
                .catch((error) => {
                    console.warn(error);
                    this.flashMessageBroadcaster.broadcast(
                        'Er is een fout opgetreden bij het zoeken naar gemeenten en wijken.',
                        Type.Danger
                    );
                })
                .finally(() => {
                    runInAction(() => {
                        this.isSearching = false;
                    });
                });
        };

        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = undefined;
        }

        if (this.addressOptions.postalCode && this.addressOptions.houseNumber) {
            this.searchTimeout = setTimeout(search, 500);
        }

        this.foundDistricts = null;
    }

    @action
    public onChangeReasoning(value: ReasoningType): void {
        this.reasoningValue = value;
        this.reasoningValidationErrorShown = false;
    }

    @action
    public showReasoningValidationError() {
        this.reasoningValidationErrorShown = true;
    }
}
