declare const USER_ADDRESS: {
    postal_code: string | null;
    house_number: string | null;
    letter: string | null;
    latitude: number | null;
    longitude: number | null;
};

export function getUserAddress() {
    if (
        USER_ADDRESS.postal_code === null ||
        USER_ADDRESS.house_number === null ||
        USER_ADDRESS.latitude === null ||
        USER_ADDRESS.longitude === null
    ) {
        return null;
    }

    return {
        postalCode: USER_ADDRESS.postal_code,
        houseNumber: USER_ADDRESS.house_number,
        letter: USER_ADDRESS.letter,
        latitude: USER_ADDRESS.latitude,
        longitude: USER_ADDRESS.longitude,
    };
}
