import {action, computed, makeObservable, observable, reaction, runInAction} from 'mobx';
import {NeighborhoodApi} from '../appraising/network/neighborhood_api';
import {CompositeSubscription} from '../support/composite_subscription';
import {Presenter} from '../support/presenter/presenter';
import {AppraiserDistrictsPresenter} from './appraiser_districts_presenter';
import {debounceTime, Subject} from 'rxjs';
import {DistrictInfo} from '../appraising/models/neighborhood';

export class DistrictsMapPresenter implements Presenter {
    private readonly subscriptions = new CompositeSubscription();
    private readonly mapSubscriptions = new CompositeSubscription();

    private readonly latLongStream = new Subject<google.maps.LatLngBoundsLiteral>();

    private searchAbort?: AbortController;
    private map: google.maps.Map | null = null;

    private addedFeatures: google.maps.Data.Feature[] = [];

    @observable private addedCodes: Set<string>;
    @observable private searchPromise: Promise<unknown> | null = null;
    @observable public selectedDistrict: DistrictInfo | null = null;
    @observable public isTooFarZoomedOut = false;

    constructor(private neighborhoodApi: NeighborhoodApi, addedCodes: Set<string>) {
        this.addedCodes = addedCodes;

        makeObservable(this);
    }

    @computed
    public get isSearching() {
        return this.searchPromise !== null;
    }

    public mount(): void {
        this.subscriptions.add(
            this.latLongStream.pipe(debounceTime(500)).subscribe(({south, north, west, east}) => {
                this.search(south, north, west, east);
            })
        );

        this.subscriptions.add(
            reaction(
                () => this.addedCodes,
                (addedCodes) => {
                    this.addedFeatures.forEach((feature) => {
                        const isAdded = addedCodes.has(feature.getProperty('code') as string);
                        feature.setProperty('isAdded', isAdded);
                    });
                }
            )
        );
    }

    @action
    public onUpdatedProps(addedCodes: Set<string>) {
        this.addedCodes = addedCodes;
    }

    public loadMap(map: google.maps.Map, maps: typeof google.maps) {
        if (this.map) {
            this.unloadMap();
        }

        this.map = map;

        const onBoundsUpdate = () => {
            const zoom = map.getZoom();
            if (!zoom || zoom < 10) {
                this.clearFeatures();

                if (this.searchAbort) {
                    this.searchAbort.abort('New search started');
                    this.searchAbort = undefined;
                }

                this.searchPromise = null;

                runInAction(() => {
                    this.isTooFarZoomedOut = true;
                });

                return;
            }

            runInAction(() => {
                this.isTooFarZoomedOut = false;
            });

            const bounds = map.getBounds()?.toJSON();
            if (bounds) {
                this.latLongStream.next(bounds);
            }
        };

        const boundsListener = maps.event.addListener(map, 'bounds_changed', onBoundsUpdate);

        this.mapSubscriptions.add(() => boundsListener.remove());

        onBoundsUpdate();

        map.data.setStyle((feature) => {
            return {
                fillColor: feature.getProperty('isSelected')
                    ? '#32fae6'
                    : feature.getProperty('isAdded')
                    ? 'lightseagreen'
                    : 'gray',
                strokeColor: '#444',
                strokeWeight: 2,
            };
        });

        const hoverListener = map.data.addListener('mouseover', (ev: {feature: google.maps.Data.Feature}) => {
            map.data.revertStyle();
            map.data.overrideStyle(ev.feature, {
                fillColor: ev.feature.getProperty('isSelected') ? '#02e3e3' : 'teal',
                strokeWeight: 4,
            });
        });

        this.mapSubscriptions.add(() => hoverListener.remove());

        const mouseOutListener = map.data.addListener('mouseout', () => {
            map.data.revertStyle();
        });

        this.mapSubscriptions.add(() => mouseOutListener.remove());

        const clickListener = map.data.addListener('click', (ev: {feature: google.maps.Data.Feature}) => {
            const district = ev.feature.getProperty('district') as DistrictInfo;
            runInAction(() => {
                if (this.selectedDistrict === district) {
                    this.selectedDistrict = null;
                } else {
                    this.selectedDistrict = district;
                    map.data.overrideStyle(ev.feature, {
                        fillColor: '#02e3e3',
                        strokeWeight: 4,
                    });
                }
            });
            this.updateSelectedFeature();
        });

        this.mapSubscriptions.add(() => clickListener.remove());
    }

    public unloadMap() {
        this.mapSubscriptions.clear();

        this.map = null;
        this.addedFeatures = [];
    }

    public unmount(): void {
        this.unloadMap();

        this.subscriptions.clear();
    }

    public deselectDistrict() {
        this.selectedDistrict = null;
        this.updateSelectedFeature();
    }

    @action
    private search(minLat: number, maxLat: number, minLng: number, maxLng: number) {
        if (this.searchAbort) {
            this.searchAbort.abort('New search started');
        }

        this.searchAbort = new AbortController();

        const searchPromise = this.neighborhoodApi
            .findByBounds(
                minLat,
                maxLat,
                minLng,
                maxLng,
                [...AppraiserDistrictsPresenter.COLUMNS, 'geom'],
                ['municipality', 'district'],
                this.searchAbort.signal
            )
            .then((result) => {
                if (this.searchPromise !== searchPromise) {
                    // Result is outdated
                    return;
                }

                this.clearFeatures();

                this.addedFeatures =
                    this.map?.data.addGeoJson({
                        type: 'FeatureCollection',
                        features: result.districts.map((district) => ({
                            type: 'Feature',
                            properties: {
                                code: district.wijkcode,
                                isAdded: this.addedCodes.has(district.wijkcode),
                                isSelected: this.selectedDistrict?.wijkcode === district.wijkcode,
                                district,
                            },
                            geometry: district.geom,
                        })),
                    }) ?? [];
            })
            .catch((error) => {
                console.error('Failed to search districts', error);
            })
            .finally(() => {
                if (this.searchPromise === searchPromise) {
                    runInAction(() => {
                        this.searchPromise = null;
                    });
                }
            });

        this.searchPromise = searchPromise;
    }

    private clearFeatures() {
        this.addedFeatures.forEach((feature) => {
            this.map?.data.remove(feature);
        });
        this.addedFeatures = [];
    }

    private updateSelectedFeature() {
        this.addedFeatures.forEach((feature) => {
            const isSelected = feature.getProperty('code') === this.selectedDistrict?.wijkcode;
            if (isSelected !== feature.getProperty('isSelected')) {
                feature.setProperty('isSelected', isSelected);
            }
        });
    }
}
