import React, {useMemo} from 'react';
import GoogleMapReact from 'google-map-react';
import {mapStyles} from '../create_appraisal/address_geo_map/components/map_styles';
import {ApiGlobal} from '../business/global_provider';
import {getUserAddress} from './user_address';
import {usePresenter} from '../support/presenter/use_presenter';
import {DistrictsMapPresenter} from './districts_map_presenter';
import {observer} from 'mobx-react';
import {Loader} from '../appraising/appraise/ui/components/loader';
import {ReasoningType} from './reasoning_input_presenter';
import {DistrictInfo} from '../appraising/models/neighborhood';

interface OwnProps {
    addedCodes: Set<string>;
    addedDistricts: {district: DistrictInfo; reasoning: ReasoningType}[];
    editReasoningForCodes: (codes: string[], title: string) => void;
    removeCodes: (codes: string[]) => void;
}

declare const GLOBAL: ApiGlobal;

export const AppraiserDistrictsMap: React.FC<OwnProps> = observer(
    ({addedCodes, addedDistricts, editReasoningForCodes, removeCodes}: OwnProps) => {
        const presenter = usePresenter(
            (container) => new DistrictsMapPresenter(container.network.neighborhoodApi, addedCodes),
            [addedCodes]
        );

        const userAddress = useMemo(() => getUserAddress(), []);

        if (GLOBAL.google_maps_api_key === undefined || GLOBAL.google_maps_api_key === null || userAddress === null) {
            return null;
        }

        const createMapOptions = (maps: GoogleMapReact.Maps) => {
            return {
                styles: mapStyles,
                zoomControlOptions: {
                    position: maps.ControlPosition.RIGHT_CENTER,
                    style: maps.ZoomControlStyle.SMALL,
                },
                mapTypeControl: false,
                panControl: true,
                disableDefaultUI: false,
                draggable: true,
                fullscreenControl: false,
                gestureHandling: 'greedy',
            };
        };

        const addedDistrictData = presenter.selectedDistrict
            ? addedDistricts.find((d) => d.district.wijkcode === presenter.selectedDistrict?.wijkcode)
            : null;

        return (
            <div className="appraiser-districts-map-container">
                <div className="appraiser-districts-map-title">
                    <h2>Wijkkaart</h2>
                </div>
                <div className="appraiser-districts-map-details-container">
                    {presenter.isSearching ? (
                        <div className="empty-message mt-0 mb-2">
                            <div style={{margin: '-15px 0'}}>
                                <Loader isSmall />
                            </div>
                            <h3 className="mt-0">Kaartgegevens laden...</h3>
                        </div>
                    ) : (
                        <ul className="list-circles list-appraiser-districts mb-0 ml-0">
                            {presenter.isTooFarZoomedOut ? (
                                <li className="list-item ml-0">
                                    <span className="list-avatar ion-md-search" />
                                    <span className="list-title">
                                        <strong>Zoom in</strong>
                                    </span>
                                    <small className="list-details">
                                        <span>Zoom in op de kaart om wijken te bekijken</span>
                                    </small>
                                </li>
                            ) : presenter.selectedDistrict ? (
                                addedDistrictData ? (
                                    <li className="list-item ml-0">
                                        <span className="list-avatar ion-md-locate" />
                                        <span className="list-title">
                                            <strong>{presenter.selectedDistrict.wijknaam}</strong> in gemeente{' '}
                                            {presenter.selectedDistrict.gemeentenaam}
                                        </span>
                                        <small className="list-details">
                                            <span>
                                                <strong>Onderbouwing:</strong> {addedDistrictData.reasoning.reasoning}
                                            </span>
                                        </small>

                                        <span className="list-options mr-1">
                                            <a
                                                className="ion-md-create"
                                                onClick={() =>
                                                    editReasoningForCodes(
                                                        [addedDistrictData.district.wijkcode],
                                                        `Pas de onderbouwing aan voor de wijk ${addedDistrictData.district.wijknaam} in de gemeente ${addedDistrictData.district.gemeentenaam}`
                                                    )
                                                }
                                            ></a>
                                            <a
                                                className="ion-md-trash mr-2"
                                                onClick={() => {
                                                    removeCodes([addedDistrictData.district.wijkcode]);
                                                    presenter.deselectDistrict();
                                                }}
                                            ></a>
                                        </span>
                                    </li>
                                ) : (
                                    <li className="list-item ml-0">
                                        <span className="list-avatar ion-md-locate" />
                                        <span className="list-title">
                                            <strong>{presenter.selectedDistrict.wijknaam}</strong> in gemeente{' '}
                                            {presenter.selectedDistrict.gemeentenaam}
                                        </span>
                                        <small className="list-details">
                                            <span>Deze wijk is nog niet toegevoegd aan je werkgebied</span>
                                        </small>
                                        <span className="list-options mr-1">
                                            <a
                                                className="ion-md-add"
                                                onClick={() =>
                                                    presenter.selectedDistrict &&
                                                    editReasoningForCodes(
                                                        [presenter.selectedDistrict.wijkcode],
                                                        `Voeg een onderbouwing toe voor de wijk ${presenter.selectedDistrict.wijknaam} in de gemeente ${presenter.selectedDistrict.gemeentenaam}`
                                                    )
                                                }
                                            ></a>
                                        </span>
                                    </li>
                                )
                            ) : (
                                <li className="list-item ml-0">
                                    <span className="list-avatar ion-md-locate" />
                                    <span className="list-title">
                                        <strong>Selecteer een wijk</strong>
                                    </span>
                                    <small className="list-details">
                                        <span>Klik op de kaart om een wijk te selecteren</span>
                                    </small>
                                </li>
                            )}
                        </ul>
                    )}
                </div>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: GLOBAL.google_maps_api_key,
                        language: 'nl',
                    }}
                    options={createMapOptions}
                    defaultZoom={10}
                    defaultCenter={{
                        lat: userAddress.latitude,
                        lng: userAddress.longitude,
                    }}
                    yesIWantToUseGoogleMapApiInternals={true}
                    shouldUnregisterMapOnUnmount={true}
                    onGoogleApiLoaded={({map, maps}) => presenter.loadMap(map, maps)}
                />
            </div>
        );
    }
);
