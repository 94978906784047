import * as React from 'react';

import {simpleQuestionPresenterFactory, SimpleQuestionPresenterProps} from './simple_question_presenter';

import {AlternativeAdaptedDefaultAnswer} from '../components/alternative_adapted_default_answer';
import {Label} from '../components/label';
import {ValidationMessages} from '../components/validation_messages';
import {classNames} from '../../../../../../support/classnames';
import {filterValidationMessages} from '../../../support/filter_validation_messages';
import {observer} from 'mobx-react';
import {CompactEditable} from '../../../components/compact_editable';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {QuestionExtensionType} from '../../../../../enum/question_extension_type';
import {MaxCharactersQuestionExtension} from '../../../../../models/question_extension';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const OpenQuestionCompact: React.FC<OwnProps> = observer(function OpenQuestionCompact(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));

    if (presenter.isHidden) {
        return null;
    }

    const maxCharactersExtension = props.question.extensions.find(
        (ext) => ext.type === QuestionExtensionType.MAX_CHARACTERS
    ) as MaxCharactersQuestionExtension | null;

    return (
        <div
            className={classNames('form-group', presenter.filledByAutomator, {
                'automation-filled': presenter.filledByAutomator !== null,
            })}
            data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
        >
            <CompactEditable
                renderingContext={props.renderingContext}
                compact={() => (
                    <>
                        <Label
                            for={presenter.name}
                            question={props.question}
                            parentAnswerUuid={props.parentAnswerUuid}
                            iteration={props.iteration}
                            appraisal={props.appraisal}
                            questionSet={props.questionSet}
                            disabled={props.disabled || presenter.isDisabled}
                            disableHistory
                            hideLabel={props.hideLabel}
                            showLabel={props.question.showLabel}
                            renderingContext={props.renderingContext}
                        />
                        <div>{presenter.answer?.contents || <i>Niet ingevuld</i>}</div>
                    </>
                )}
                question={props.question}
                answer={presenter.answer}
            >
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <AlternativeAdaptedDefaultAnswer
                    adaptedDefaultValue={props.adaptedDefaultValue}
                    currentAnswer={presenter.answer}
                    disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                    onChange={(value) => presenter.onChange(value)}
                />
                <input
                    type="text"
                    id={presenter.name}
                    name={presenter.name}
                    className="form-control"
                    disabled={props.disabled || presenter.isDisabled}
                    value={presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : ''}
                    onChange={(e) => presenter.onChange(e.target.value, false)}
                    maxLength={maxCharactersExtension?.maxCharacters ?? undefined}
                    onBlur={() => presenter.onTouched()}
                />
            </CompactEditable>
            <ValidationMessages
                disabled={props.disabled || presenter.isDisabled}
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={presenter.answer}
                validationMessages={filterValidationMessages(
                    props.question,
                    presenter.answer,
                    props.validationMessages,
                    presenter.validationMessages
                )}
            />
        </div>
    );
});
