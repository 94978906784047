import {AreaConclusion} from '../../../../../../../../models/area_conclusion';
import {EnergyConclusion} from '../../../../../../../../models/energy_conclusion';
import {ReferenceSaleImage} from '../../v1/models/reference_sale_image';

export interface ReferenceObject {
    // Shared data with Sale class
    id: string;
    source: Source | string | null;
    postalCode: string;
    street: string;
    houseNumber: string;
    letter: string;
    city: string;
    priceRange: string | null;
    indexedPriceRange: string | null;
    saleQuarter: string | null;
    normalizedSaleDate: {
        month: number;
        year: number;
    } | null;
    transportDate: string | null;
    objectType: string | null;
    plotArea: number | null;
    floorArea: number | null;
    buildYear: number | null;
    volume: number | null;
    latitude: number;
    longitude: number;
    energyLabel: string | null;
    energyLabelIsPreliminary: boolean;
    energyLabelValidDate: string | null;
    daysOpenForSale: string | null;
    hasWarning: boolean;
    warning: string | null;
    ownership: string | null;
    brochureUrl: string | null;
    frontview: ReferenceSaleImage | null;
    images: ReferenceSaleImage[];
    rooms: number | null;
    complete: boolean | null;
    rejectReason: string | null;

    // Specific data
    highlights: string[];
    overview: Record<string, string> | null;
    installationDates: Record<string, string> | null;
    matchingPercentage: number | null;
    additionalPropertiesByCategory: Record<string, Record<string, string>> | null;
    saleText: string | null;
}

export enum Source {
    ORTEC = 'Ortec',
    ARCHIEF_TAXATEUR = 'Archief taxateur',
    ARCHIEF_NVM = 'Archief NVM',
    ARCHIEF_VASTGOEDPRO = 'Archief VastgoedPRO',
    ARCHIEF_VBO = 'Archief VBO',
    ARCHIEF_BRANCHEVERENIGING = 'Archief branchevereniging',
}

export function sourceToClassName(source: string | null) {
    if (source === null) {
        return 'ortec';
    }
    switch (source.toLowerCase()) {
        case 'vastgoedpro':
        case 'archief vastgoedpro':
        case 'vastgoedpro vip':
            return 'vastgoedpro';
        default:
            return source.toLowerCase();
    }
}

export interface ExtraReferenceSaleSet {
    id: string;
    name: string;
    valuation: string;
    buildYear: number | null;
    areaConclusion: AreaConclusion;
    energyConclusion: EnergyConclusion;
    referenceObjects: ReferenceObject[];
}
