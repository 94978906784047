import {ReferenceObjectAnswer, referenceObjectAnswerFromSale} from '../../models/reference_object_answer';
import {makeObservable, observable, runInAction} from 'mobx';

import {Presenter} from '../../../../../../../../../../support/presenter/presenter';
import {ReferenceObject} from '../../models/reference_object';
import {ReferenceObjectProvider} from '../../../../../../../../../business/reference_object_provider';
import {SetDefinition} from '../../reference_objects_question_presenter';
import {ReferenceObjectsMetadataProvider} from '../../../../../../../../../business/reference_objects_metadata/reference_objects_metadata_provider';

export class ReferenceObjectTilePresenter implements Presenter {
    @observable public isAdding = false;

    constructor(
        private setDefinition: SetDefinition,
        private onAddReferenceSale: (referenceObjectAnswer: ReferenceObjectAnswer) => Promise<void>,
        private referenceObjectInteractor: ReferenceObjectProvider,
        private referenceObjectsMetadataProvider: ReferenceObjectsMetadataProvider
    ) {
        makeObservable(this);
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public async onAdd(referenceObject: ReferenceObject) {
        try {
            runInAction(() => {
                this.isAdding = true;
            });
            const sale = await this.referenceObjectInteractor.getSale(referenceObject.id, this.setDefinition.type);

            const referenceObjectAnswer = referenceObjectAnswerFromSale(
                sale,
                referenceObject.saleQuarter,
                referenceObject.images
            );
            await this.onAddReferenceSale(referenceObjectAnswer);
        } finally {
            runInAction(() => {
                this.isAdding = false;
            });
        }
    }

    public undoReject(referenceObject: ReferenceObject) {
        if (!this.setDefinition.groupTree.item.answer) {
            return;
        }
        for (const pair of this.referenceObjectsMetadataProvider.getAllMetadataByAnswerUuid(
            this.setDefinition.groupTree.item.question.uuid,
            this.setDefinition.groupTree.item.answer.uuid
        )) {
            const index = pair.metadata.rejectedObjects.findIndex(
                (rejectedObject) =>
                    rejectedObject.id === referenceObject.id && rejectedObject.source === referenceObject.source
            );
            if (index === -1) {
                continue;
            }

            pair.metadata.rejectedObjects.splice(index, 1);

            this.referenceObjectsMetadataProvider.storeMetadata(pair);
        }
    }
}
