import React from 'react';
import RcSlider, {SliderProps} from 'rc-slider';
import {classNames} from '../../../../support/classnames';

type CurveDefinition = {
    function: (x: number) => number;
    inverse: (x: number) => number;
};

export interface NonLinearSliderProps extends SliderProps {
    curve: CurveDefinition;

    min: number;
    max: number;
    resolution: number;
    marks: Record<number, React.ReactNode>;
}

export const NonLinearSlider: React.FunctionComponent<NonLinearSliderProps> = ({
    curve,
    className,
    min,
    max,
    marks,
    resolution,
    defaultValue,
    handleRender,
    onChangeComplete,
    ...restProps
}) => {
    const curvedMarks = Object.fromEntries(
        Object.entries(marks).map(([key, label]) => [curve.inverse(Number(key)), label])
    );

    return (
        <RcSlider
            {...restProps}
            className={classNames(className, 'form-rc-slider')}
            min={curve.inverse(min)}
            max={curve.inverse(max)}
            marks={curvedMarks}
            step={(curve.inverse(max) - curve.inverse(min)) / resolution}
            defaultValue={
                Array.isArray(defaultValue)
                    ? defaultValue.map(curve.inverse)
                    : defaultValue !== undefined
                    ? curve.inverse(defaultValue)
                    : undefined
            }
            handleRender={
                handleRender
                    ? (origin, props) =>
                          handleRender(origin, {...props, value: Math.round(curve.function(props.value))})
                    : undefined
            }
            onChangeComplete={
                onChangeComplete
                    ? (value) =>
                          onChangeComplete(
                              Array.isArray(value)
                                  ? value.map((val) => Math.round(curve.function(val)))
                                  : Math.round(curve.function(value))
                          )
                    : undefined
            }
        />
    );
};
