import * as React from 'react';
import {createRoot} from 'react-dom/client';

import {AppraisalControllerForm} from './form/appraisal_controller_form';
import {SalutationType} from '../appraising/enum/salutation_type';

const container = document.getElementById('form-controller');
if (container !== undefined && container !== null) {
    const salutation =
        container.getAttribute('data-salutation') ?? '' in SalutationType
            ? (container.getAttribute('data-salutation') as SalutationType)
            : null;

    const root = createRoot(container);
    root.render(
        <AppraisalControllerForm
            salutation={salutation}
            initials={container.getAttribute('data-initials')}
            firstName={container.getAttribute('data-first-name')}
            lastName={container.getAttribute('data-last-name')}
            rtNumber={container.getAttribute('data-rt-number')}
            companyName={container.getAttribute('data-company-name')}
            city={container.getAttribute('data-city')}
            email={container.getAttribute('data-email')}
        />
    );
}
