import * as React from 'react';

import {Appraisal} from '../../../models/appraisal';
import {CameraProgress} from '../content/camera_presenter';
import {ConstructionCostCounter} from './construction_cost_counter';
import {FlashMessage} from '../container/flash_message';
import {History} from 'history';
import {PagePartsSet} from '../../../models/page_parts_set';
import {QuestionSet} from '../../../models/question_set';
import {SurfaceAreaCounter} from './surface_area_counter';
import {UploadingImagesList} from '../content/uploading_images_list/uploading_images_list';
import {ValidationError} from '../../../models/validation_error';
import {createPortal} from 'react-dom';
import {RenderingContextType} from '../../../enum/rendering_context_type';

interface OwnProps {
    history: History;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet | null;
    validationErrors: ValidationError[];
    renderingContext: RenderingContextType;
    cameraProgress: CameraProgress | null;
}

export class MessagesStack extends React.Component<OwnProps> {
    public render() {
        const stack = (
            <div className="messages-stack">
                <ConstructionCostCounter
                    history={this.props.history}
                    appraisal={this.props.appraisal}
                    questionSet={this.props.questionSet}
                    pagePartsSet={this.props.pagePartsSet}
                    validationErrors={this.props.validationErrors}
                    renderingContext={this.props.renderingContext}
                />
                <SurfaceAreaCounter
                    history={this.props.history}
                    appraisal={this.props.appraisal}
                    questionSet={this.props.questionSet}
                    pagePartsSet={this.props.pagePartsSet}
                    validationErrors={this.props.validationErrors}
                    renderingContext={this.props.renderingContext}
                />
                <UploadingImagesList
                    appraisal={this.props.appraisal}
                    questionSet={this.props.questionSet}
                    progress={this.props.cameraProgress}
                />
                <FlashMessage />
            </div>
        );

        const messagesContainer = document.getElementById('messages-container');
        if (!messagesContainer) {
            return stack;
        }

        return createPortal(stack, messagesContainer);
    }
}
