import React, {useMemo} from 'react';
import {observer} from 'mobx-react';
import {MacroExternalType} from '../appraising/models/macro';
import {usePresenter} from '../support/presenter/use_presenter';
import {ExternalMacroInputPresenter} from './external_macro_input_presenter';
import {Macros} from '../appraising/appraise/ui/content/questions/advanced/macros/macros';
import {macroIsActive, toggleMacro} from '../appraising/business/toggle_macro';
import {createRoot} from 'react-dom/client';

export interface OwnProps {
    externalType: MacroExternalType;
    originalElement: HTMLTextAreaElement;
}

export const ExternalMacroInput: React.FC<OwnProps> = observer(function ExternalMacroInput(props) {
    const presenter = usePresenter(
        (container) =>
            new ExternalMacroInputPresenter(
                props.originalElement,
                props.externalType,
                container.business.macroInteractor(null)
            )
    );

    const value = presenter.value;
    const macros = presenter.macros ?? [];

    const attributes = useMemo<React.TextareaHTMLAttributes<HTMLTextAreaElement>>(() => {
        return {
            id: props.originalElement.id,
            type: 'text',
            onChange: (e) => presenter.onChange(e.target.value),
            className: props.originalElement.className,
            placeholder: props.originalElement.placeholder,
            name: props.originalElement.name,
            required: props.originalElement.required,
            disabled: props.originalElement.disabled,
            rows: props.originalElement.rows,
            cols: props.originalElement.cols,
            value,
        };
    }, [value]);

    return (
        <div className={macros.length !== 0 ? 'form-group has-macros' : 'form-group'}>
            {presenter.macros && (
                <Macros
                    externalType={props.externalType}
                    parentAnswerUuid={null}
                    questionSet={null}
                    appraisal={null}
                    isActive={(macro) => macroIsActive(macro, value)}
                    onUseMacro={(macro) => presenter.onChange(toggleMacro(macro, value))}
                    onAdd={presenter.onAddAsMacroClick}
                    onRemove={(macro) => presenter.onRemoveMacroClick(macro)}
                    showAddButton={value !== '' && !presenter.macros.some((m) => m.contents === value)}
                    hasAdvancedOptions={false}
                    allowRemoveSystemMacros={false}
                />
            )}
            <textarea {...attributes} />
        </div>
    );
});

export function renderExternalMacroInputInto(textArea: HTMLTextAreaElement) {
    const externalType = textArea.getAttribute('data-external-macro-type');
    if (externalType === null) {
        console.error('data-external-macro-type attribute is required');
        return;
    }

    const newContainer = document.createElement('div');

    createRoot(newContainer).render(
        <ExternalMacroInput externalType={externalType as MacroExternalType} originalElement={textArea} />
    );

    textArea.replaceWith(newContainer);
}
