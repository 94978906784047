import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../support/composite_subscription';
import {Presenter} from '../support/presenter/presenter';
import {FlashMessageBroadcaster, Type} from '../appraising/business/flash_message_broadcaster';
import {NeighborhoodApi} from '../appraising/network/neighborhood_api';
import {AppraiserDistrictsPresenter} from './appraiser_districts_presenter';
import {NeighborhoodResult} from '../appraising/models/neighborhood';
import {ReasoningType} from './reasoning_input_presenter';

export class SearchModalPresenter implements Presenter {
    private _subscriptions = new CompositeSubscription();

    @observable public searchQuery: string | null = null;
    @observable public searchResult: NeighborhoodResult | null = null;
    @observable public selectedCodes: string[] = [];
    @observable public isSearching = false;
    @observable public reasoningValue: ReasoningType | null = null;
    @observable public reasoningValidationErrorShown = false;

    @observable public addedCodes: Set<string>;

    @observable
    private searchTimeout?: NodeJS.Timeout;

    constructor(
        private neighborhoodApi: NeighborhoodApi,
        private flashMessageBroadcaster: FlashMessageBroadcaster,
        addedCodes: Set<string>
    ) {
        this.addedCodes = addedCodes;

        makeObservable(this);
    }

    public mount(): void {
        // Unused
    }

    public unmount(): void {
        this._subscriptions.clear();
    }

    @action
    public onUpdatedProps(addedCodes: Set<string>): void {
        this.addedCodes = addedCodes;
    }

    @computed
    public get isSearchPending() {
        return this.searchTimeout !== undefined;
    }

    @action
    public onSearchAppraisal(query?: string, force = false) {
        if (query !== undefined) {
            this.searchQuery = query;
        }

        const search = () => {
            runInAction(() => {
                this.isSearching = true;
                clearTimeout(this.searchTimeout);
                this.searchTimeout = undefined;
            });

            if (this.searchQuery === null || this.searchQuery.length < 3) {
                runInAction(() => {
                    this.isSearching = false;
                });
                return;
            }

            this.neighborhoodApi
                .search(this.searchQuery, AppraiserDistrictsPresenter.COLUMNS, ['district', 'municipality'])
                .then((result) => {
                    runInAction(() => {
                        this.searchResult = result;
                    });
                })
                .catch((error) => {
                    console.warn(error);
                    this.flashMessageBroadcaster.broadcast(
                        'Er is een fout opgetreden bij het zoeken naar gemeenten en wijken.',
                        Type.Danger
                    );
                })
                .finally(() => {
                    runInAction(() => {
                        this.isSearching = false;
                    });
                });
        };

        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = undefined;
        }

        if (force) {
            search();
        } else {
            this.searchTimeout = setTimeout(search, 500);
        }
    }

    @action
    public toggleCode(code: string) {
        if (this.selectedCodes.includes(code)) {
            this.selectedCodes = this.selectedCodes.filter((c) => c !== code);
        } else {
            this.selectedCodes.push(code);
        }
    }

    @action
    public onChangeReasoning(value: ReasoningType): void {
        this.reasoningValue = value;
        this.reasoningValidationErrorShown = false;
    }

    @action
    public showReasoningValidationError() {
        this.reasoningValidationErrorShown = true;
    }
}
