import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';
import {isEmpty, isNumeric} from '../../../../../../support/util';

import {AlternativeAdaptedDefaultAnswer} from '../components/alternative_adapted_default_answer';
import {Label} from '../components/label';
import {ValidationMessages} from '../components/validation_messages';
import {classNames} from '../../../../../../support/classnames';
import {filterValidationMessages} from '../../../support/filter_validation_messages';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import NumberFormat from 'react-number-format';
import {QuestionExtensionType} from '../../../../../enum/question_extension_type';
import {MaxCharactersQuestionExtension} from '../../../../../models/question_extension';

interface OwnProps extends SimpleQuestionPresenterProps {
    negative: boolean;
}

export const NumberQuestion: React.FC<OwnProps> = observer(function NumberQuestion(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));
    if (presenter.isHidden) {
        return null;
    }

    const maxCharactersExtension = props.question.extensions.find(
        (ext) => ext.type === QuestionExtensionType.MAX_CHARACTERS
    ) as MaxCharactersQuestionExtension | null;

    return (
        <div
            className={classNames('form-group', presenter.filledByAutomator, {
                'automation-filled': presenter.filledByAutomator !== null,
            })}
            data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
        >
            <Label
                for={presenter.name}
                question={props.question}
                parentAnswerUuid={props.parentAnswerUuid}
                iteration={props.iteration}
                appraisal={props.appraisal}
                questionSet={props.questionSet}
                disabled={props.disabled || presenter.isDisabled}
                hideLabel={props.hideLabel}
                showLabel={props.question.showLabel}
                renderingContext={props.renderingContext}
            />
            <AlternativeAdaptedDefaultAnswer
                adaptedDefaultValue={props.adaptedDefaultValue}
                currentAnswer={presenter.answer}
                disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                onChange={(value) => presenter.onChange(value)}
            />
            <NumberFormat
                id={presenter.name}
                name={presenter.name}
                value={
                    presenter.answer && presenter.answer.contents !== null
                        ? presenter.answer.contents.replace('.', ',')
                        : ''
                }
                decimalSeparator=","
                displayType="input"
                type="text"
                className="form-control"
                decimalScale={2}
                disabled={props.disabled || presenter.isDisabled}
                allowNegative={props.negative}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value?.replace(',', '.'); // Replace comma with dot to get a valid number
                    if (!isEmpty(value)) {
                        if (isNumeric(value)) {
                            presenter.onChange(value, false);
                        } else if (value === '-') {
                            presenter.onChange(value, false);
                        }
                    } else {
                        presenter.onChange('', false);
                    }
                }}
                onBlur={() => presenter.onTouched()}
                maxLength={maxCharactersExtension?.maxCharacters ?? undefined}
            />
            <ValidationMessages
                disabled={props.disabled || presenter.isDisabled}
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={presenter.answer}
                validationMessages={filterValidationMessages(
                    props.question,
                    presenter.answer,
                    props.validationMessages,
                    presenter.validationMessages
                )}
            />
        </div>
    );
});
