import {Sale} from '../../models/sale';
import {
    ApiReferenceSaleImage,
    apiReferenceSaleImagesToReferenceSaleImages,
    apiReferenceSaleImageToReferenceSaleImage,
} from './api_reference_sale_image';

export interface ApiSale {
    id: string;
    source: string | null;
    postal_code: string;
    street: string;
    house_number: string;
    letter: string;
    city: string;
    purchase_price: number | null;
    indexed_purchase_price: number | null;
    sale_date: string | null;
    sale_summary: string | null;
    transport_date: string | null;
    object_type: string | null;
    plot_area: number | null;
    floor_area: number | null;
    build_year: number | null;
    volume: number | null;
    latitude: number;
    longitude: number;
    energy_label: string | null;
    energy_label_is_preliminary: boolean;
    energy_label_valid_date: string | null;
    days_open_for_sale: string | null;
    rooms: number | null;
    extra: string | null;
    has_warning: boolean;
    warning: string | null;
    ownership: string | null;
    brochure_url: string | null;
    matching_percentage: number | null;
    front_view: ApiReferenceSaleImage | null;
    images?: ApiReferenceSaleImage[];
    asking_price: number | null;
    original_asking_price: number | null;
    offered_since: string | null;
    keywords: Record<string, string> | null;
}

export function apiSaleToSale(apiSale: ApiSale): Sale {
    return {
        id: apiSale.id,
        source: apiSale.source,
        postalCode: apiSale.postal_code,
        street: apiSale.street,
        houseNumber: apiSale.house_number,
        letter: apiSale.letter,
        city: apiSale.city,
        purchasePrice: apiSale.purchase_price,
        indexedPurchasePrice: apiSale.indexed_purchase_price,
        saleDate: apiSale.sale_date,
        saleSummary: apiSale.sale_summary,
        transportDate: apiSale.transport_date,
        objectType: apiSale.object_type,
        plotArea: apiSale.plot_area,
        floorArea: apiSale.floor_area,
        buildYear: apiSale.build_year,
        volume: apiSale.volume,
        latitude: apiSale.latitude,
        longitude: apiSale.longitude,
        energyLabel: apiSale.energy_label,
        energyLabelIsPreliminary: apiSale.energy_label_is_preliminary,
        energyLabelValidDate: apiSale.energy_label_valid_date,
        daysOpenForSale: apiSale.days_open_for_sale,
        rooms: apiSale.rooms,
        extra: apiSale.extra,
        hasWarning: apiSale.has_warning,
        warning: apiSale.warning,
        ownership: apiSale.ownership,
        brochureUrl: apiSale.brochure_url,
        matchingPercentage: apiSale.matching_percentage,
        frontview: apiSale.front_view ? apiReferenceSaleImageToReferenceSaleImage(apiSale.front_view) : null,
        images: apiSale.images ? apiReferenceSaleImagesToReferenceSaleImages(apiSale.images) : undefined,
        askingPrice: apiSale.asking_price,
        originalAskingPrice: apiSale.original_asking_price,
        offeredSince: apiSale.offered_since,
        keywords: apiSale.keywords,
    };
}
