import React, {useState} from 'react';
import {Modal} from '../appraising/appraise/ui/content/questions/components/modal';
import 'react-day-picker/style.css';
import {usePresenter} from '../support/presenter/use_presenter';
import {RangeModalPresenter} from './range_modal_presenter';
import {observer} from 'mobx-react';
import {Loader} from '../appraising/appraise/ui/components/loader';
import {SimpleTooltip} from '../appraising/components/tooltip';
import {ReasoningInput} from './reasoning_input';
import {ReasoningType} from './reasoning_input_presenter';
import {Collapsible} from '../appraising/appraise/ui/components/collapsible';
import {NonLinearSlider} from '../appraising/appraise/ui/components/nonlinear_slider';

export const RangeSearchModal = observer(function RangeSearchModal({
    onClose,
    addedCodes,
}: {
    onClose: (codes: string[], reasoning: ReasoningType | null) => Promise<void>;
    addedCodes: Set<string>;
}) {
    const presenter = usePresenter(
        (component) =>
            new RangeModalPresenter(
                component.network.neighborhoodApi,
                component.business.flashMessageBroadcaster,
                addedCodes
            ),
        [addedCodes]
    );

    const [isClosing, setIsClosing] = useState(false);

    const close = (addedCodes: string[]) => {
        const value = presenter.reasoningValue ?? presenter.reasoningDefaultValue;

        if (addedCodes.length > 0 && (value === null || value.reasoning === '')) {
            presenter.showReasoningValidationError();
            return;
        }

        setIsClosing(true);
        onClose(addedCodes, value)
            .then(() => setIsClosing(false))
            .catch(() => setIsClosing(false));
    };

    return (
        <Modal visible>
            <div className="modal-header">
                <h2>Kies op basis van afstand</h2>
            </div>
            <div className="modal-body">
                <p>Zoek naar alle wijken en gemeenten die binnen een bepaalde straal van een adres liggen.</p>
                <div className="form-group">
                    <label>Zoekadres</label>
                    <div className="row">
                        <div className="col-6">
                            <div className="input-group">
                                <input
                                    id="user_address"
                                    type="radio"
                                    className="form-control"
                                    name="mode"
                                    value="default"
                                    checked={presenter.addressOptions.type === 'user'}
                                    onChange={() =>
                                        presenter.updateRange({
                                            type: 'user',
                                        })
                                    }
                                />
                                <label htmlFor="user_address">Kantooradres</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="input-group">
                                <input
                                    id="custom_address"
                                    type="radio"
                                    className="form-control"
                                    name="mode"
                                    value="custom"
                                    checked={presenter.addressOptions.type === 'custom'}
                                    onChange={() =>
                                        presenter.updateRange({
                                            type: 'custom',
                                        })
                                    }
                                />
                                <label htmlFor="custom_address">Anders</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-checklist">
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <span className="label-container">
                                    <label htmlFor="address_postal_code">
                                        <span>Postcode</span>
                                    </label>
                                </span>
                                <input
                                    type="text"
                                    id="address_postal_code"
                                    name="address_postal_code"
                                    className="form-control"
                                    value={presenter.addressOptions.postalCode}
                                    disabled={presenter.addressOptions.type !== 'custom'}
                                    onChange={(ev) =>
                                        presenter.updateRange({
                                            postalCode: ev.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <span className="label-container">
                                    <label htmlFor="adress_house_number">
                                        <span>Huisnummer</span>
                                    </label>
                                </span>
                                <input
                                    type="text"
                                    id="adress_house_number"
                                    name="adress_house_number"
                                    className="form-control"
                                    value={presenter.addressOptions.houseNumber}
                                    disabled={presenter.addressOptions.type !== 'custom'}
                                    onChange={(ev) =>
                                        presenter.updateRange({
                                            houseNumber: ev.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <span className="label-container">
                                    <label htmlFor="adress_letter">
                                        <span>Toevoeging</span>
                                    </label>
                                </span>
                                <input
                                    type="text"
                                    id="adress_letter"
                                    name="adress_letter"
                                    className="form-control"
                                    value={presenter.addressOptions.letter}
                                    disabled={presenter.addressOptions.type !== 'custom'}
                                    onChange={(ev) =>
                                        presenter.updateRange({
                                            letter: ev.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group mb-5">
                    <label>Straal</label>
                    <div className="col-12">
                        <NonLinearSlider
                            handleRender={(origin, props) =>
                                React.cloneElement<React.HTMLAttributes<HTMLDivElement>>(origin, {
                                    children: `${props.value} km`,
                                })
                            }
                            defaultValue={presenter.range}
                            onChangeComplete={(val) => presenter.updateRange(undefined, val as number)}
                            min={1}
                            max={500}
                            resolution={100}
                            marks={{
                                1: '1 km',
                                20: '20 km',
                                50: '50 km',
                                100: '100 km',
                                250: '200 km',
                                500: '500 km',
                            }}
                            curve={{
                                function: (x) => Math.pow(x, 5),
                                inverse: (x) => Math.pow(x, 1 / 5),
                            }}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>Wijken</label>
                    <div className="form-checklist">
                        {presenter.foundDistrictsByMunicipality?.map(
                            ({code: municipalityCode, name: municipalityName, districts, newCodes}) => (
                                <Collapsible
                                    key={municipalityCode}
                                    alwaysRenderChildren={false}
                                    title={
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>
                                                <strong>{municipalityName}</strong>
                                                <span className="thin pl-1">
                                                    ({newCodes.length} nieuwe wijk{newCodes.length !== 1 ? 'en' : ''})
                                                </span>
                                            </span>
                                            <div className="d-flex align-items-center" style={{height: 20}}>
                                                {newCodes.length === 0 ? (
                                                    <SimpleTooltip
                                                        content={'Alle wijken binnen deze gemeente zijn al toegevoegd'}
                                                        contentStyle={{
                                                            zIndex: 1200,
                                                        }}
                                                    >
                                                        <div className="form-group mb-0">
                                                            <input
                                                                type="checkbox"
                                                                id={`municipality-${municipalityCode}`}
                                                                name={`municipality-${municipalityCode}`}
                                                                checked
                                                                disabled
                                                            />
                                                            <label
                                                                htmlFor={`municipality-${municipalityCode}`}
                                                                style={{height: 24}}
                                                            />
                                                        </div>
                                                    </SimpleTooltip>
                                                ) : (
                                                    <div className="form-group mb-0">
                                                        <input
                                                            type="checkbox"
                                                            id={`municipality-${municipalityCode}`}
                                                            name={`municipality-${municipalityCode}`}
                                                            checked={
                                                                !newCodes.some((code) =>
                                                                    presenter.excludedDistricts.has(code)
                                                                )
                                                            }
                                                            onChange={(e) => {
                                                                e.stopPropagation();
                                                                const unselectedCodes = newCodes.filter((code) =>
                                                                    presenter.excludedDistricts.has(code)
                                                                );
                                                                if (unselectedCodes.length > 0) {
                                                                    unselectedCodes.forEach((code) =>
                                                                        presenter.toggleDistrictExclude(code)
                                                                    );
                                                                } else {
                                                                    newCodes.forEach((code) =>
                                                                        presenter.toggleDistrictExclude(code)
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        <label
                                                            htmlFor={`municipality-${municipalityCode}`}
                                                            style={{height: 24}}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                >
                                    <div className="row">
                                        {districts.map((district) => (
                                            <div className="col-12 col-md-6" key={district.wijkcode}>
                                                {presenter.addedCodes.has(district.wijkcode) ? (
                                                    <SimpleTooltip
                                                        content={'Deze wijk is al toegevoegd'}
                                                        contentStyle={{
                                                            zIndex: 1200,
                                                        }}
                                                    >
                                                        <div className="form-group">
                                                            <input
                                                                type="checkbox"
                                                                id={`district-${district.wijkcode}`}
                                                                name={`district-${district.wijkcode}`}
                                                                checked
                                                                disabled
                                                            />
                                                            <label htmlFor={`district-${district.wijkcode}`}>
                                                                {district.wijknaam}
                                                            </label>
                                                        </div>
                                                    </SimpleTooltip>
                                                ) : (
                                                    <button className="tooltip-trigger text-left">
                                                        <div className="form-group">
                                                            <input
                                                                type="checkbox"
                                                                id={`district-${district.wijkcode}`}
                                                                name={`district-${district.wijkcode}`}
                                                                checked={
                                                                    !presenter.excludedDistricts.has(district.wijkcode)
                                                                }
                                                                onChange={() =>
                                                                    presenter.toggleDistrictExclude(district.wijkcode)
                                                                }
                                                            />
                                                            <label htmlFor={`district-${district.wijkcode}`}>
                                                                {district.wijknaam}
                                                            </label>
                                                        </div>
                                                    </button>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </Collapsible>
                            )
                        ) ?? (
                            <div className="col-12">
                                <div className="empty-message mt-5 mb-3">
                                    {presenter.isSearchPending || presenter.isSearching ? (
                                        <>
                                            <Loader />
                                            <h3>Zoeken naar wijken...</h3>
                                        </>
                                    ) : (
                                        <>
                                            <h3>Er zijn geen wijken gevonden</h3>
                                            <span className="spacer-gradient"></span>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {presenter.codesToAdd.length > 0 && (
                    <ReasoningInput
                        defaultValue={presenter.reasoningDefaultValue}
                        onChange={(value) => presenter.onChangeReasoning(value)}
                        showRequiredError={presenter.reasoningValidationErrorShown}
                    />
                )}
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary ion-md-close" type="button" onClick={() => close([])}>
                    Sluiten
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() => close(presenter.codesToAdd)}
                    disabled={presenter.codesToAdd.length === 0}
                >
                    {presenter.isSearching || isClosing ? (
                        <Loader isSmall />
                    ) : (
                        <>
                            {presenter.codesToAdd.length} wijk
                            {presenter.codesToAdd.length === 1 ? '' : 'en'} toevoegen
                        </>
                    )}
                </button>
            </div>
        </Modal>
    );
});
