import * as React from 'react';

import {observer} from 'mobx-react';
import {usePresenter} from '../support/presenter/use_presenter';
import {ReasoningInputPresenter, ReasoningType} from './reasoning_input_presenter';
import {Macros} from '../appraising/appraise/ui/content/questions/advanced/macros/macros';
import {MacroExternalType} from '../appraising/models/macro';
import {macroIsActive, toggleMacro} from '../appraising/business/toggle_macro';

interface OwnProps {
    defaultValue: ReasoningType | null;
    onChange: (value: ReasoningType) => void;
    showRequiredError: boolean;
}

export const ReasoningInput: React.FC<OwnProps> = observer(function ReasoningInput(props) {
    const presenter = usePresenter(
        (container) =>
            new ReasoningInputPresenter(container.business.macroInteractor(null), props.defaultValue, props.onChange),
        [props.defaultValue]
    );

    const value = presenter.value;
    const macros = presenter.macros ?? [];

    return (
        <div className={macros.length !== 0 ? 'form-group has-macros' : 'form-group'}>
            <label>
                Onderbouwing<span className="form-label-highlight">*</span>
            </label>
            {presenter.macros && (
                <Macros
                    externalType={MacroExternalType.LOCAL_KNOWLEDGE_REASONING}
                    parentAnswerUuid={null}
                    questionSet={null}
                    appraisal={null}
                    isActive={(macro) => macroIsActive(macro, value?.reasoning ?? '')}
                    onUseMacro={(macro) => presenter.onChange({reasoning: toggleMacro(macro, value?.reasoning ?? '')})}
                    onAdd={presenter.onAddAsMacroClick}
                    onRemove={(macro) => presenter.onRemoveMacroClick(macro)}
                    showAddButton={
                        value !== null &&
                        value.reasoning !== '' &&
                        !presenter.macros.some((m) => m.contents === value.reasoning)
                    }
                    hasAdvancedOptions={false}
                />
            )}
            <textarea
                placeholder="Vul een onderbouwing in voor het gekozen werkgebied."
                className="form-control"
                rows={8}
                value={value?.reasoning ?? ''}
                onChange={(e) => presenter.onChange({reasoning: e.target.value})}
            />
            {props.showRequiredError && (
                <span className="invalid-feedback" role="alert">
                    Dit veld is verplicht.
                </span>
            )}
            <div className="row">
                <div className="col-6">
                    <input
                        type="checkbox"
                        name={`reasoning-near-office`}
                        id={`reasoning-near-office`}
                        checked={value?.nearOffice ?? false}
                        onChange={(e) => presenter.onChange({nearOffice: e.target.checked})}
                    />
                    <label htmlFor={`reasoning-near-office`}>Nabij kantooradres</label>
                </div>
                <div className="col-6">
                    <input
                        type="checkbox"
                        name={`reasoning-near-living`}
                        id={`reasoning-near-living`}
                        checked={value?.nearLiving ?? false}
                        onChange={(e) => presenter.onChange({nearLiving: e.target.checked})}
                    />
                    <label htmlFor={`reasoning-near-living`}>Nabij woonadres</label>
                </div>
            </div>
        </div>
    );
});
