import {action, makeObservable, observable, runInAction} from 'mobx';
import {Presenter} from '../support/presenter/presenter';
import {Macro, MacroExternalType} from '../appraising/models/macro';
import {CompositeSubscription} from '../support/composite_subscription';
import {MacroInteractor} from '../appraising/business/macro_interactor';

export class ExternalMacroInputPresenter implements Presenter {
    @observable.ref public macros?: Macro[];
    @observable.ref public value: string;

    private subscriptions = new CompositeSubscription();

    constructor(
        private originalElement: HTMLTextAreaElement,
        private externalType: MacroExternalType,
        private macroInteractor: MacroInteractor
    ) {
        this.value = this.originalElement.value;

        makeObservable(this);
    }

    public mount() {
        this.subscriptions.add(
            this.macroInteractor.macrosForExternalType(this.externalType).subscribe((macros) => {
                runInAction(() => {
                    this.macros = macros;
                });
            })
        );
    }

    public unmount() {
        this.subscriptions.clear();
    }

    @action
    public onChange(value: string) {
        this.value = value;
        this.originalElement.value = value;
        $(this.originalElement).trigger('change');
    }

    public onAddAsMacroClick = async () => {
        if (this.value) {
            try {
                await this.macroInteractor.storeExternal(this.value, this.externalType);
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };

    public onRemoveMacroClick = async (toBeRemovedMacro: Macro) => {
        if (this.macros !== undefined) {
            try {
                const macro = this.macros.find((m) => m.id === toBeRemovedMacro.id);
                if (macro !== undefined && macro.isUserDefined) {
                    await this.macroInteractor.destroy(macro.id);
                }
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };
}
