import {Answer} from '../../models/answer';
import {AppraisalFieldResolver} from './appraisal_field_resolver';
import {Condition} from '../../models/condition';
import {ConditionGroup} from '../../models/condition_group';
import {EffectState} from './conditions_interactor';
import {NormalQuestionType} from '../../enum/question_type';
import {Question} from '../../models/question';
import {QuestionSet} from '../../models/question_set';
import {isEmpty} from '../../../support/util';
import {ConditionComparison} from '../../models/condition_comparison';
import {DateFormat, formatDate} from '../../appraise/ui/support/format_date';

export class EffectsCollectorFromAnswers {
    constructor(private questionSet: QuestionSet, private appraisalFieldResolver: AppraisalFieldResolver) {}

    public collect(
        conditionGroup: ConditionGroup,
        answersForQuestionUuids: Array<{questionUuid: string; answers: Answer[] | null}>,
        answersForConditionAnswers: Array<{questionUuid: string; answers: Answer[] | null}>
    ): EffectState[] {
        const conclusions = conditionGroup.conditions
            .map((condition) => {
                const answerForQuestionUuid = answersForQuestionUuids.find(
                    (a) => condition.questionUuid === a.questionUuid
                );

                const question =
                    answerForQuestionUuid !== undefined
                        ? this.questionSet.findQuestionByUuid(answerForQuestionUuid.questionUuid)
                        : undefined;
                if (answerForQuestionUuid === undefined) {
                    return {
                        enabled: this.conditionIsEnabled(condition, question, undefined, undefined),
                        condition,
                    };
                }

                if (answerForQuestionUuid.answers === null) {
                    return {
                        enabled: this.conditionIsEnabled(condition, question, null, undefined),
                        condition,
                    };
                }

                return answerForQuestionUuid.answers.map((answer) => {
                    return {
                        enabled: this.conditionIsEnabled(condition, question, answer, answersForConditionAnswers),
                        condition,
                    };
                });
            })
            .flat();

        const effectsEnabled = this.effectsForConditionGroupAreEnabled(conditionGroup, conclusions);

        return conditionGroup.effects.map((effect) => {
            return {
                enabled: effectsEnabled,
                effect: effect,
                conclusions: conclusions,
            };
        });
    }

    private conditionIsEnabled(
        condition: Condition,
        question: Question | undefined,
        answer: Answer | undefined | null,
        answersForConditionAnswers: Array<{questionUuid: string; answers: Answer[] | null}> | undefined
    ): boolean | ConditionComparison {
        const value = this.getValue(condition, question, answer);

        return this.evaluateConditionWithValue(condition, value, answersForConditionAnswers);
    }

    private getValue(
        condition: Condition,
        question: Question | undefined,
        answer: Answer | null | undefined
    ): string | null {
        if (condition.appraisalField !== null) {
            return this.appraisalFieldResolver.resolve(condition.appraisalField);
        } else if (condition.questionUuid !== null) {
            if (answer === undefined) {
                return null;
            }
            if (answer === null) {
                return this.getDefaultValueForQuestionUuid(condition.questionUuid);
            }
            if (this.shouldUseAnswerOptionValue(answer, question)) {
                return '' + answer.answerOptionId;
            } else if (answer.contents !== null) {
                return answer.contents;
            } else if (answer.file !== null) {
                return answer.file.url;
            }
        }

        return null;
    }

    // Boolean questions have no answer option, so skip checking for answerOption.
    private shouldUseAnswerOptionValue(answer: Answer, question: Question | undefined): boolean {
        if (question !== undefined && question.type === NormalQuestionType.BOOLEAN) {
            return false;
        }

        return answer.answerOptionId !== null;
    }

    private getDefaultValueForQuestionUuid(questionUuid: string): string | null {
        const question = this.questionSet.findQuestionByUuid(questionUuid);
        if (question === undefined) {
            return null;
        }

        const defaultValue =
            this.appraisalFieldResolver.resolve(question.defaultValue ?? '') ?? question.defaultValue ?? null;
        if (
            question.defaultValue === 'NOW' &&
            (question.type === NormalQuestionType.DATE || question.type === NormalQuestionType.DATE_PICKER)
        ) {
            return formatDate(new Date(), DateFormat.TAXAPI);
        }

        return defaultValue;
    }

    private normalizedMap: Record<string, string | null> = {};
    private normalize(value: string | null) {
        if (value === null) {
            return value;
        }
        if (this.normalizedMap[value]) {
            return this.normalizedMap[value];
        }
        this.normalizedMap[value] = value.toLowerCase().trim();

        return this.normalizedMap[value];
    }

    private evaluateConditionWithValue(
        condition: Condition,
        value: string | null,
        answersForConditionAnswers: Array<{questionUuid: string; answers: Answer[] | null}> | undefined
    ): boolean | ConditionComparison {
        value = this.normalize(value);

        switch (condition.condition) {
            case '==': {
                if (condition.value === 'empty') {
                    return value === null || isEmpty(value);
                }
                if (value === null) {
                    return false;
                }
                return value === this.normalize(condition.value);
            }
            case '!=': {
                if (condition.value === 'empty') {
                    return !(value === null || isEmpty(value));
                }
                if (value === null) {
                    return true;
                }
                return value !== this.normalize(condition.value);
            }
            case '<':
            case '>':
            case '<=':
            case '>=': {
                let conditionComparison: ConditionComparison = {
                    condition: condition.condition,
                    showMessage: true,
                };
                if (value === null) {
                    return conditionComparison;
                }
                let compareValue = condition.value;
                if (condition.compareQuestionUuid) {
                    const question = this.questionSet.findQuestionByUuid(condition.compareQuestionUuid);
                    if (question?.uuid) {
                        conditionComparison = {...conditionComparison, questionName: question.contents};
                        const answer = answersForConditionAnswers?.find(
                            (conditionAnswers) => conditionAnswers.questionUuid === question.uuid
                        );
                        if (answer && answer.answers && answer.answers.length > 0) {
                            compareValue = this.normalize(answer.answers[0].contents) ?? condition.value;
                        }
                    }
                }
                if (!compareValue) {
                    return conditionComparison;
                }
                conditionComparison = {...conditionComparison, value: compareValue};
                switch (condition.condition) {
                    case '>=':
                        conditionComparison = {...conditionComparison, showMessage: value < compareValue};
                        break;
                    case '<=':
                        conditionComparison = {...conditionComparison, showMessage: value > compareValue};
                        break;
                    case '>':
                        conditionComparison = {...conditionComparison, showMessage: value <= compareValue};
                        break;
                    case '<':
                        conditionComparison = {...conditionComparison, showMessage: value >= compareValue};
                        break;
                }

                return conditionComparison;
            }
            default:
                return false;
        }
    }

    private effectsForConditionGroupAreEnabled(
        conditionGroup: ConditionGroup,
        conclusions: Array<{enabled: boolean | ConditionComparison; condition: Condition}>
    ): boolean {
        switch (conditionGroup.type) {
            case 'and': {
                for (const condition of conditionGroup.conditions) {
                    const conclusionsForCondition = conclusions.filter((c) => c.condition.id === condition.id);
                    if (
                        !conclusionsForCondition.every((c) =>
                            typeof c.enabled == 'boolean' ? c.enabled : c.enabled.showMessage
                        )
                    ) {
                        return false;
                    }
                }
                return true;
            }
            case 'or': {
                return conclusions.some((c) => (typeof c.enabled == 'boolean' ? c.enabled : c.enabled.showMessage));
            }
        }
    }
}
