import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';

import {AlternativeAdaptedDefaultAnswer} from '../components/alternative_adapted_default_answer';
import {Label} from '../components/label';
import {Macros} from '../advanced/macros/macros';
import {ValidationMessages} from '../components/validation_messages';
import {classNames} from '../../../../../../support/classnames';
import {filterValidationMessages} from '../../../support/filter_validation_messages';
import {macroIsActive} from '../../../../../business/toggle_macro';
import {observer} from 'mobx-react';
import {CompactEditable} from '../../../components/compact_editable';
import {Modal} from '../components/modal';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {QuestionExtensionType} from '../../../../../enum/question_extension_type';
import {MaxCharactersQuestionExtension} from '../../../../../models/question_extension';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const OpenQuestion: React.FC<OwnProps> = observer(function OpenQuestion(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));

    const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);

    const updateTextareaHeight = React.useCallback(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = '5px';
            textareaRef.current.style.height = textareaRef.current.scrollHeight + 5 + 'px';
        }
    }, [textareaRef]);

    React.useEffect(() => {
        updateTextareaHeight();
    }, [presenter.answer?.contents]);

    if (presenter.isHidden) {
        return null;
    }

    const macros = presenter.macros;
    const maxCharactersExtension = props.question.extensions.find(
        (ext) => ext.type === QuestionExtensionType.MAX_CHARACTERS
    ) as MaxCharactersQuestionExtension | null;

    function renderCompact() {
        return (
            <>
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    disableHistory
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <div>{presenter.answer?.contents || <i>Niet ingevuld</i>}</div>
            </>
        );
    }
    function renderExpanded(close: () => void) {
        return (
            <>
                {renderCompact()}
                <Modal visible={true} onHide={close} onShow={() => updateTextareaHeight()}>
                    <div className="modal-header">
                        <h2>{props.question.contents}</h2>
                    </div>
                    <div className="modal-body">{renderInput()}</div>
                    <div className="modal-footer">
                        <button className="btn btn-primary" onClick={close}>
                            Sluiten
                        </button>
                    </div>
                </Modal>
            </>
        );
    }

    function renderInput() {
        const answer = presenter.answer;
        return (
            <>
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <AlternativeAdaptedDefaultAnswer
                    adaptedDefaultValue={props.adaptedDefaultValue}
                    currentAnswer={presenter.answer}
                    disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                    onChange={(value) => presenter.onChange(value)}
                />
                {!presenter.isDisabled && !props.disabled && presenter.macros !== undefined ? (
                    <Macros
                        questionUuid={props.question.uuid}
                        parentAnswerUuid={props.parentAnswerUuid ?? null}
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        hasAdvancedOptions={true}
                        isActive={(contents) => (answer ? macroIsActive(contents, answer.contents) : false)}
                        onAdd={presenter.onAddAsMacroClick}
                        onUseMacro={presenter.onMacroClick}
                        onRemove={presenter.onRemoveMacroClick}
                        onFavorite={presenter.onFavoriteMacroClick}
                        showAddButton={presenter.showAddMacroButton}
                        useAI
                    />
                ) : null}

                <textarea
                    id={presenter.name}
                    name={presenter.name}
                    className={classNames('form-control no-min-height', {'has-inset': presenter.showAddMacroButton})}
                    ref={textareaRef}
                    readOnly={presenter.isDisabled || props.disabled}
                    value={presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : ''}
                    onChange={(e) => presenter.onChange(e.target.value, false)}
                    onBlur={() => presenter.onTouched()}
                    maxLength={maxCharactersExtension?.maxCharacters ?? undefined}
                    rows={1}
                />
            </>
        );
    }

    return (
        <div
            className={classNames('form-group', presenter.filledByAutomator, {
                'has-macros': macros !== undefined && macros.length !== 0,
                'automation-filled': presenter.filledByAutomator !== null,
            })}
            data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
        >
            <CompactEditable
                renderingContext={props.renderingContext}
                compact={() => renderCompact()}
                expanded={(close) => renderExpanded(close)}
                question={props.question}
            >
                {renderInput()}
            </CompactEditable>

            <ValidationMessages
                disabled={props.disabled || presenter.isDisabled}
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={presenter.answer}
                validationMessages={filterValidationMessages(
                    props.question,
                    presenter.answer,
                    props.validationMessages,
                    presenter.validationMessages
                )}
            />
        </div>
    );
});
