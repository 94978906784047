import {action, computed, makeObservable, observable, reaction, runInAction} from 'mobx';
import {CompositeSubscription} from '../support/composite_subscription';
import {Presenter} from '../support/presenter/presenter';
import {FlashMessageBroadcaster, Type} from '../appraising/business/flash_message_broadcaster';
import {DistrictInfo, MunicipalityInfo, NeighborhoodResult} from '../appraising/models/neighborhood';
import {NeighborhoodApi} from '../appraising/network/neighborhood_api';
import {ReasoningType} from './reasoning_input_presenter';

type InfoEntry =
    | {
          type: 'municipality';
          data: MunicipalityInfo;
      }
    | {
          type: 'district';
          data: DistrictInfo;
      };

declare const DISTRICTS: {district: DistrictInfo; reasoning: string; living?: boolean; office?: boolean}[];

export class AppraiserDistrictsPresenter implements Presenter {
    public static readonly COLUMNS = [
        'fid',
        'gemeentenaam',
        'gemeentecode',
        'wijknaam',
        'wijkcode',
        'buurtnaam',
        'buurtcode',
    ];

    private _subscriptions = new CompositeSubscription();

    @observable public addedDistricts: {district: DistrictInfo; reasoning: ReasoningType}[] = [];
    @observable public selectedDistrictCodes = new Set<string>();
    @observable public editReasoningModalDetails: {
        reasoningValue: ReasoningType;
        codes: string[];
        description: string;
        isAdding: boolean;
    } | null = null;
    @observable private infoByCode = new Map<string, InfoEntry>();

    constructor(private flashMessageBroadcaster: FlashMessageBroadcaster, private neighborhoodApi: NeighborhoodApi) {
        this.addedDistricts = DISTRICTS.map(({district, reasoning, living, office}) => ({
            district,
            reasoning: {
                reasoning,
                nearLiving: living ?? false,
                nearOffice: office ?? false,
            },
        }));

        makeObservable(this);
    }

    public mount(): void {
        this._subscriptions.add(
            reaction(
                () =>
                    Array.from(this.addedDistricts)
                        .flatMap((entry) => [entry.district.wijkcode, entry.district.gemeentecode])
                        .filter((code) => !this.infoByCode.has(code)),
                (codesToFetch) => {
                    this.fetchByCodes(codesToFetch, true).catch((error) => {
                        console.error(error);
                        this.flashMessageBroadcaster.broadcast(
                            'Er is een fout opgetreden bij het ophalen van de buurtinformatie',
                            Type.Danger
                        );
                    });
                }
            )
        );
    }

    public unmount(): void {
        this._subscriptions.clear();
    }

    @computed
    public get fullyIncludedCodes(): Set<string> {
        const codes = new Set<string>();

        const toCheckFullMunicipalities = new Set<string>();

        for (const {district} of this.addedDistricts) {
            codes.add(district.wijkcode);

            toCheckFullMunicipalities.add(district.gemeentecode);
        }

        const allInfo = Array.from(this.infoByCode.values());

        for (const code of toCheckFullMunicipalities) {
            let matching = true;
            for (const info of allInfo) {
                if (info.type === 'district' && info.data.gemeentecode === code && !codes.has(info.data.wijkcode)) {
                    matching = false;
                    break;
                }
            }

            if (matching) {
                codes.add(code);
            }
        }

        return codes;
    }

    @computed
    public get districtsByMunicipality(): {
        code: string;
        name: string;
        districts: {district: DistrictInfo; reasoning: ReasoningType}[];
    }[] {
        const districtsByMunicipality = new Map<
            string,
            {name: string; districts: {district: DistrictInfo; reasoning: ReasoningType}[]}
        >();

        for (const info of this.addedDistricts) {
            const municipalityCode = info.district.gemeentecode;
            const municipality = districtsByMunicipality.get(municipalityCode);

            if (municipality) {
                municipality.districts.push(info);
            } else {
                districtsByMunicipality.set(municipalityCode, {
                    name: info.district.gemeentenaam,
                    districts: [info],
                });
            }
        }

        const result = Array.from(districtsByMunicipality.entries()).map(([code, {name, districts}]) => ({
            code,
            name,
            districts,
        }));

        result.sort((a, b) => a.name.localeCompare(b.name));

        for (const entry of result) {
            entry.districts.sort((a, b) => a.district.wijknaam.localeCompare(b.district.wijknaam));
        }

        return result;
    }

    @computed
    public get allSelected(): boolean {
        return this.selectedDistrictCodes.size === this.addedDistricts.length;
    }

    public async addCodes(codes: string[], reasoning: ReasoningType | null): Promise<void> {
        try {
            const result = await this.fetchByCodes(codes, true);
            runInAction(() => {
                this.addedDistricts = [
                    ...this.addedDistricts,
                    ...result.districts.map((district) => ({
                        district,
                        reasoning: reasoning ?? {
                            reasoning: '',
                            nearOffice: false,
                            nearLiving: false,
                        },
                    })),
                ];
            });
        } catch (e) {
            console.error(e);
            this.flashMessageBroadcaster.broadcast(
                'Er is een fout opgetreden bij het ophalen van de buurtinformatie',
                Type.Danger
            );
        }
    }

    @action
    public removeCodes(codes: string[]) {
        this.addedDistricts = this.addedDistricts.filter(
            (entry) => !codes.includes(entry.district.wijkcode) && !codes.includes(entry.district.gemeentecode)
        );
        for (const code of codes) {
            this.selectedDistrictCodes.delete(code);
        }
    }

    @action
    public clearCodes(): void {
        this.addedDistricts = [];
    }

    @action
    public toggleSelected(code: string): void {
        if (this.selectedDistrictCodes.has(code)) {
            this.selectedDistrictCodes.delete(code);
        } else {
            this.selectedDistrictCodes.add(code);
        }
    }

    @action
    public toggleSelectAll(): void {
        if (this.allSelected) {
            this.selectedDistrictCodes.clear();
        } else {
            this.selectedDistrictCodes = new Set(this.addedDistricts.map((entry) => entry.district.wijkcode));
        }
    }

    @action
    public editReasoningForCodes(codes: string[], description: string): void {
        const uniqueReasoningValues = new Set(
            this.addedDistricts
                .filter((entry) => codes.includes(entry.district.wijkcode))
                .map((entry) => JSON.stringify(entry.reasoning))
        );

        let reasoning: ReasoningType;
        if (uniqueReasoningValues.size === 1) {
            reasoning = JSON.parse(uniqueReasoningValues.values().next().value as string);
        } else {
            reasoning = {
                reasoning: '',
                nearOffice: false,
                nearLiving: false,
            };
        }

        const isAdding = codes.some((code) => !this.fullyIncludedCodes.has(code));

        this.editReasoningModalDetails = {reasoningValue: reasoning, codes, description, isAdding};
    }

    @action
    public onChangeEditReasoning(value: ReasoningType): void {
        if (this.editReasoningModalDetails) {
            this.editReasoningModalDetails.reasoningValue = value;
        }
    }

    @action
    public async closeEditReasoningModal(save: boolean): Promise<void> {
        if (!this.editReasoningModalDetails) {
            return;
        }

        if (save) {
            const toBeAddedCodes = this.editReasoningModalDetails.codes.filter(
                (code) => !this.fullyIncludedCodes.has(code)
            );
            if (toBeAddedCodes.length > 0) {
                await this.addCodes(toBeAddedCodes, null);
            }

            for (const entry of this.addedDistricts) {
                if (this.editReasoningModalDetails.codes.includes(entry.district.wijkcode)) {
                    entry.reasoning = this.editReasoningModalDetails.reasoningValue;
                }
            }
        }

        this.editReasoningModalDetails = null;
    }

    public submit() {
        const form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', '/account/me/districts');

        const data: {
            districts: {code: string; reasoning: string; living: boolean; office: boolean}[];
        } = {
            districts: [],
        };

        for (const {district, reasoning} of this.addedDistricts) {
            data.districts.push({
                code: district.wijkcode,
                reasoning: reasoning.reasoning,
                living: reasoning.nearLiving,
                office: reasoning.nearOffice,
            });
        }

        const dataField = document.createElement('input');
        dataField.setAttribute('type', 'hidden');
        dataField.setAttribute('name', 'data');
        dataField.setAttribute('value', JSON.stringify(data));
        form.appendChild(dataField);

        const csrfToken = (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content;

        const csrfField = document.createElement('input');
        csrfField.setAttribute('type', 'hidden');
        csrfField.setAttribute('name', '_token');
        csrfField.setAttribute('value', csrfToken);
        form.appendChild(csrfField);

        document.body.appendChild(form);
        form.submit();
    }

    private async fetchByCodes(codes: string[], withChildren: boolean) {
        const result: NeighborhoodResult = {
            districts: [],
            neighborhoods: [],
            municipalities: [],
        };
        const codesToFetch: string[] = [];

        const allInfo = Array.from(this.infoByCode.values());

        for (const code of codes) {
            const info = this.infoByCode.get(code);
            if (info) {
                switch (info.type) {
                    case 'municipality':
                        result.municipalities.push(info.data);
                        if (withChildren) {
                            for (const district of allInfo) {
                                if (
                                    district.type === 'district' &&
                                    district.data.gemeentecode === info.data.gemeentecode
                                ) {
                                    result.districts.push(district.data);
                                }
                            }
                        }
                        break;
                    case 'district':
                        result.districts.push(info.data);
                        break;
                }
            } else {
                codesToFetch.push(code);
            }
        }

        if (codesToFetch.length === 0) {
            return result;
        }

        const fetchResult = await this.neighborhoodApi.findByCodes(
            codes,
            withChildren,
            AppraiserDistrictsPresenter.COLUMNS,
            ['district', 'municipality']
        );

        runInAction(() => {
            for (const info of fetchResult.districts) {
                result.districts.push(info);
                this.infoByCode.set(info.wijkcode, {type: 'district', data: info});
            }

            for (const info of fetchResult.municipalities) {
                result.municipalities.push(info);
                this.infoByCode.set(info.gemeentecode, {type: 'municipality', data: info});
            }
        });

        return result;
    }
}
