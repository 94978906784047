import {AppraisalProvider} from '../appraisal_provider';

export class AppraisalFieldResolver {
    constructor(private _appraisalProvider: AppraisalProvider) {}

    public resolve(appraisalField: string) {
        const appraisal = this._appraisalProvider.appraisal;
        switch (appraisalField) {
            case 'appraisal.estimated_object_type':
            case 'appraisal.object_display_type':
                return appraisal.objectDisplayType;
            case 'appraisal.object_type':
                return appraisal.objectType;
            case 'appraisal.status':
                return appraisal.status.toString();
            case 'appraisal.appraisal_goal':
                return appraisal.appraisalGoal.toString();
            case 'appraisal.validation_type':
                return appraisal.validationType.toString();
            case 'appraisal.improvements_planned':
                return appraisal.improvementsPlanned ? '1' : '0';
            case 'appraisal.recently_sold':
                return appraisal.recentlySold ? '1' : '0';
            case 'appraisal.new_construction':
                return appraisal.newConstruction ? '1' : '0';
            case 'appraisal.is_woco':
                return appraisal.isWoco ? '1' : '0';
            case 'appraisal.object_usage_type':
                return appraisal.objectUsageType.value;
            case 'appraisal.object_ownership_type':
                return appraisal.objectOwnershipType.value;
            case 'appraisal.appraiser_is_prior_appraiser':
                return appraisal.appraiser?.isPriorAppraiser ? '1' : '0';
            case 'appraisal.appraiser_salutation':
                return appraisal.appraiser?.salutation ?? null;
            case 'appraisal.appraiser_initials':
                return appraisal.appraiser?.initials ?? null;
            case 'appraisal.appraiser_first_name':
                return appraisal.appraiser?.firstName ?? null;
            case 'appraisal.appraiser_last_name':
                return appraisal.appraiser?.lastName ?? null;
            case 'appraisal.appraiser_rt_number':
                return appraisal.appraiser?.rtNumber ?? null;
            case 'appraisal.appraiser_company_name':
                return appraisal.appraiser?.companyName ?? null;
            case 'appraisal.appraiser_city':
                return appraisal.appraiser?.city ?? null;
            case 'appraisal.appraiser_email':
                return appraisal.appraiser?.email ?? null;
        }
        return null;
    }
}
