import {observer} from 'mobx-react';
import React, {useState} from 'react';
import 'react-day-picker/style.css';
import {Loader} from '../appraising/appraise/ui/components/loader';
import {Modal} from '../appraising/appraise/ui/content/questions/components/modal';
import {usePresenter} from '../support/presenter/use_presenter';
import {SearchModalPresenter} from './search_modal_presenter';
import {DistrictInfo, MunicipalityInfo} from '../appraising/models/neighborhood';
import {SimpleTooltip} from '../appraising/components/tooltip';
import {ReasoningInput} from './reasoning_input';
import {ReasoningType} from './reasoning_input_presenter';

export const SearchModal = observer(function SearchModal({
    onClose,
    addedCodes,
}: {
    onClose: (codes: string[], reasoning: ReasoningType | null) => Promise<void>;
    addedCodes: Set<string>;
}) {
    const presenter = usePresenter(
        (component) =>
            new SearchModalPresenter(
                component.network.neighborhoodApi,
                component.business.flashMessageBroadcaster,
                addedCodes
            ),
        [addedCodes]
    );

    const [isClosing, setIsClosing] = useState(false);

    const close = (addedCodes: string[]) => {
        if (addedCodes.length > 0 && (!presenter.reasoningValue || !presenter.reasoningValue.reasoning)) {
            presenter.showReasoningValidationError();
            return;
        }

        setIsClosing(true);
        onClose(addedCodes, presenter.reasoningValue)
            .then(() => setIsClosing(false))
            .catch(() => setIsClosing(false));
    };

    function renderItem(
        data:
            | {
                  type: 'municipality';
                  data: MunicipalityInfo;
              }
            | {
                  type: 'district';
                  data: DistrictInfo;
              }
    ) {
        let key: string;
        let label: string;
        let title: string;
        let subtitle: string;
        let code: string;
        let disabledNote: string | null = null;
        let canAdd = true;

        switch (data.type) {
            case 'municipality':
                key = `municipality-${data.data.gemeentecode}`;
                label = 'Gemeente';
                title = data.data.gemeentenaam;
                subtitle = '';
                code = data.data.gemeentecode;
                if (presenter.addedCodes.has(data.data.gemeentecode)) {
                    disabledNote = 'Deze gemeente is al toegevoegd';
                    canAdd = false;
                }
                break;
            case 'district':
                key = `district-${data.data.wijkcode}`;
                label = 'Wijk';
                title = data.data.wijknaam;
                subtitle = `Gemeente ${data.data.gemeentenaam}`;
                code = data.data.wijkcode;
                if (presenter.addedCodes.has(data.data.wijkcode)) {
                    disabledNote = 'Deze wijk is al toegevoegd';
                    canAdd = false;
                }
                break;
        }

        return (
            <li className="list-item" key={key}>
                <span className="list-avatar ion-md-locate"></span>
                <span className="list-title">
                    <strong>
                        <span className="status-label secondary-label d-inline-block">{label}</span>
                        {title}
                    </strong>
                    <span className="list-form-element">
                        <button className="tooltip-trigger">
                            {canAdd ? (
                                <button className="tooltip-trigger">
                                    <input
                                        type="checkbox"
                                        name={`neighborhood-value-${code}`}
                                        id={`neighborhood-value-${code}`}
                                        checked={presenter.selectedCodes.some((c) => c === code)}
                                        onClick={() => presenter.toggleCode(code)}
                                    />
                                    <label htmlFor={`neighborhood-value-${code}`}></label>
                                </button>
                            ) : (
                                <SimpleTooltip
                                    content={disabledNote}
                                    contentStyle={{
                                        zIndex: 1200,
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        name={`neighborhood-value-${code}`}
                                        id={`neighborhood-value-${code}`}
                                        checked={true}
                                        disabled
                                    />
                                    <label htmlFor={`neighborhood-value-${code}`}></label>
                                </SimpleTooltip>
                            )}
                        </button>
                    </span>
                </span>
                <small className="list-details">
                    <span>{subtitle}</span>
                </small>
            </li>
        );
    }

    return (
        <Modal visible>
            <div className="modal-header">
                <h2>Zoeken naar gemeente of wijk</h2>
            </div>
            <div className="modal-body">
                <p>Zoek naar gemeenten of wijken die je toe wil voegen</p>
                <div className="card-search">
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Zoek op naam"
                            className="form-control"
                            onChange={(ev) => presenter.onSearchAppraisal(ev.target.value)}
                            onKeyDown={(ev) =>
                                ev.key === 'Enter' ? presenter.onSearchAppraisal(undefined, true) : null
                            }
                        />
                    </div>
                    <button className="btn-search" onClick={() => presenter.onSearchAppraisal(undefined, true)}>
                        <span className="ion-md-search"></span>
                    </button>
                </div>
                <ul className="list-circles mt-2" style={presenter.isSearchPending ? {opacity: 0.6} : undefined}>
                    {presenter.isSearching ? (
                        <div className="empty-message mt-5">
                            <Loader />
                            <h3>Zoeken naar gemeenten of wijken</h3>
                        </div>
                    ) : presenter.searchResult !== null &&
                      (presenter.searchResult.neighborhoods.length > 0 ||
                          presenter.searchResult.districts.length > 0 ||
                          presenter.searchResult.neighborhoods.length > 0) ? (
                        <>
                            {presenter.searchResult.municipalities.map((municipality) =>
                                renderItem({type: 'municipality', data: municipality})
                            )}
                            {presenter.searchResult.districts.map((district) =>
                                renderItem({type: 'district', data: district})
                            )}
                        </>
                    ) : (
                        <div className="empty-message mt-5">
                            <h3>
                                {presenter.searchResult === null
                                    ? 'Voer een zoekopdracht in'
                                    : 'Geen gemeeentes of wijken gevonden'}
                            </h3>
                            <span className="spacer-gradient"></span>
                        </div>
                    )}
                </ul>
                {presenter.selectedCodes.length > 0 && (
                    <ReasoningInput
                        onChange={(reasoning) => presenter.onChangeReasoning(reasoning)}
                        defaultValue={presenter.reasoningValue}
                        showRequiredError={presenter.reasoningValidationErrorShown}
                    />
                )}
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary ion-md-close" type="button" onClick={() => close([])}>
                    Sluiten
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() => close(presenter.selectedCodes)}
                    disabled={presenter.selectedCodes.length === 0}
                >
                    {isClosing ? (
                        <Loader isSmall />
                    ) : (
                        `${presenter.selectedCodes.length} gebied${
                            presenter.selectedCodes.length === 1 ? '' : 'en'
                        } toevoegen`
                    )}
                </button>
            </div>
        </Modal>
    );
});
