import {
    ExtraReferenceSaleSet,
    ReferenceSale,
} from '../../appraise/ui/content/questions/advanced/reference_objects_question/v1/models/reference_sale';

import {getFromApiAreaConclusion} from '../../models/area_conclusion';
import {getFromApiEnergyConclusion} from '../../models/energy_conclusion';
import {getFromApiReferenceObject} from '../../appraise/ui/content/questions/advanced/reference_objects_question/v1/internal/normalized_sale_date';
import {
    ApiReferenceSaleImage,
    apiReferenceSaleImagesToReferenceSaleImages,
    apiReferenceSaleImageToReferenceSaleImage,
    referenceSaleImagesToApiReferenceSaleImages,
    referenceSaleImageToApiReferenceSaleImage,
} from './api_reference_sale_image';

export interface ApiReferenceSale {
    // Shared data with Sale class
    id: string;
    source: string | null;
    postal_code: string;
    street: string;
    house_number: string;
    letter: string;
    city: string;
    price_range: string;
    indexed_price_range: string;
    sale_quarter: string;
    transport_date: string | null;
    object_type: string | null;
    plot_area: number;
    floor_area: number;
    build_year: number;
    volume: number | null;
    latitude: number;
    longitude: number;
    energy_label: string | null;
    energy_label_valid_date: string | null;
    days_open_for_sale: string | null;
    has_warning: boolean;
    warning: string | null;
    ownership: string | null;
    brochure_url: string | null;
    frontview: ApiReferenceSaleImage | null;
    images?: ApiReferenceSaleImage[];
    rooms: number | null;
    complete: boolean | null;
    reject_reason: string | null;

    // Specific data
    additional_properties_by_category: Record<string, Record<string, string>> | null;
    sale_text: string | null;
    highlights: string[];
    overview: Record<string, string> | null;
    installation_dates: Record<string, string> | null;
    matching_percentage: number | null;
}

export interface ApiExtraReferenceSaleSet {
    id: string;
    name: string;
    build_year: number | null;
    valuation: string;
    areas: {
        bruto_inhoud: number;
        perceel_oppervlakte: number;
        gebruiksoppervlakte_wonen: number;
        gebouwgebonden_buitenruimte?: number;
        overige_inpandige_ruimte?: number;
        externe_bergruimte?: number;
    };
    energy: {
        label: string | null;
        is_preliminary: boolean;
    };
    reference_objects: ApiReferenceSale[];
}

export function apiReferenceSalesToReferenceSales(apiReferenceObjects: ApiReferenceSale[]): ReferenceSale[] {
    return apiReferenceObjects.map((apiReferenceObject) => apiReferenceObjectToReferenceObject(apiReferenceObject));
}

export function apiReferenceObjectToReferenceObject(apiReferenceObject: ApiReferenceSale): ReferenceSale {
    return {
        // Shared data with Sale class
        id: apiReferenceObject.id,
        source: apiReferenceObject.source,
        postalCode: apiReferenceObject.postal_code,
        street: apiReferenceObject.street,
        houseNumber: apiReferenceObject.house_number,
        letter: apiReferenceObject.letter,
        city: apiReferenceObject.city,
        priceRange: apiReferenceObject.price_range,
        indexedPriceRange: apiReferenceObject.indexed_price_range,
        saleQuarter: apiReferenceObject.sale_quarter,
        normalizedSaleDate: getFromApiReferenceObject(apiReferenceObject),
        transportDate: apiReferenceObject.transport_date,
        objectType: apiReferenceObject.object_type,
        plotArea: apiReferenceObject.plot_area,
        floorArea: apiReferenceObject.floor_area,
        buildYear: apiReferenceObject.build_year,
        volume: apiReferenceObject.volume,
        latitude: apiReferenceObject.latitude,
        longitude: apiReferenceObject.longitude,
        energyLabel: apiReferenceObject.energy_label,
        energyLabelIsPreliminary: false,
        energyLabelValidDate: apiReferenceObject.energy_label_valid_date,
        daysOpenForSale: apiReferenceObject.days_open_for_sale,
        hasWarning: apiReferenceObject.has_warning,
        warning: apiReferenceObject.warning,
        ownership: apiReferenceObject.ownership,
        brochureUrl: apiReferenceObject.brochure_url,
        complete: apiReferenceObject.complete,
        frontview: apiReferenceObject.frontview
            ? apiReferenceSaleImageToReferenceSaleImage(apiReferenceObject.frontview)
            : null,
        images: apiReferenceObject.images ? apiReferenceSaleImagesToReferenceSaleImages(apiReferenceObject.images) : [],
        rooms: apiReferenceObject.rooms,
        rejectReason: apiReferenceObject.reject_reason,

        // Specific data
        additionalPropertiesByCategory: apiReferenceObject.additional_properties_by_category,
        saleText: apiReferenceObject.sale_text,
        highlights: apiReferenceObject.highlights,
        overview: apiReferenceObject.overview,
        installationDates: apiReferenceObject.installation_dates,
        matchingPercentage: apiReferenceObject.matching_percentage,
    };
}

export function referenceObjectToApiReferenceObject(referenceObject: ReferenceSale): ApiReferenceSale {
    return {
        // Shared data with Sale class
        id: referenceObject.id,
        source: referenceObject.source,
        postal_code: referenceObject.postalCode,
        street: referenceObject.street,
        house_number: referenceObject.houseNumber,
        letter: referenceObject.letter,
        city: referenceObject.city,
        price_range: referenceObject.priceRange ?? '',
        indexed_price_range: referenceObject.indexedPriceRange ?? '',
        sale_quarter: referenceObject.saleQuarter ?? '',
        transport_date: referenceObject.transportDate,
        object_type: referenceObject.objectType,
        plot_area: referenceObject.plotArea ?? 0,
        floor_area: referenceObject.floorArea ?? 0,
        build_year: referenceObject.buildYear ?? 0,
        volume: referenceObject.volume,
        latitude: referenceObject.latitude,
        longitude: referenceObject.longitude,
        energy_label: referenceObject.energyLabel,
        energy_label_valid_date: referenceObject.energyLabelValidDate,
        days_open_for_sale: referenceObject.daysOpenForSale,
        has_warning: referenceObject.hasWarning,
        warning: referenceObject.warning,
        ownership: referenceObject.ownership,
        brochure_url: referenceObject.brochureUrl,
        complete: referenceObject.complete,
        frontview: referenceObject.frontview
            ? referenceSaleImageToApiReferenceSaleImage(referenceObject.frontview)
            : null,
        images: referenceSaleImagesToApiReferenceSaleImages(referenceObject.images),
        rooms: referenceObject.rooms,
        reject_reason: referenceObject.rejectReason,

        // Specific data
        additional_properties_by_category: referenceObject.additionalPropertiesByCategory,
        sale_text: referenceObject.saleText,
        highlights: referenceObject.highlights,
        overview: referenceObject.overview,
        installation_dates: referenceObject.installationDates,
        matching_percentage: referenceObject.matchingPercentage,
    };
}

export function apiExtraReferenceSalesToReferenceSales(
    apiExtraReferenceObjects: ApiExtraReferenceSaleSet[]
): ExtraReferenceSaleSet[] {
    return apiExtraReferenceObjects.map((apiExtraReferenceObject) => {
        return {
            id: apiExtraReferenceObject.id,
            name: apiExtraReferenceObject.name,
            valuation: apiExtraReferenceObject.valuation,
            buildYear: apiExtraReferenceObject.build_year,
            areaConclusion: getFromApiAreaConclusion(apiExtraReferenceObject.areas),
            energyConclusion: getFromApiEnergyConclusion(apiExtraReferenceObject.energy),
            referenceObjects: apiReferenceSalesToReferenceSales(apiExtraReferenceObject.reference_objects),
        };
    });
}
