import {makeObservable, observable, runInAction} from 'mobx';
import {Presenter} from '../support/presenter';
import {SalutationType} from '../../appraising/enum/salutation_type';
import {ControllerAppraiser} from '../../appraising/models/appraisal';
import {ControllerAppraiserApi} from '../network/controller_appraiser_api';

export interface ControllerData {
    salutation: SalutationType | null;
    initials: string | null;
    firstName: string | null;
    lastName: string | null;
    rtNumber: string | null;
    companyName: string | null;
    city: string | null;
    email: string | null;
}

export class AppraisalControllerFormPresenter implements Presenter {
    @observable saving = false;
    @observable isDetailFieldsDisabled = true;
    @observable errorMessage: string | null = null;
    @observable.ref emptyFields: string[] = [];
    @observable.ref controllerData: ControllerData = {
        salutation: null,
        initials: null,
        firstName: null,
        lastName: null,
        rtNumber: null,
        companyName: null,
        city: null,
        email: null,
    };

    public requiredFieldNames = ['salutation', 'initials', 'lastName', 'companyName', 'city', 'email'];

    constructor(
        readonly salutation: SalutationType | null,
        readonly initials: string | null,
        readonly firstName: string | null,
        readonly lastName: string | null,
        readonly rtNumber: string | null,
        readonly companyName: string | null,
        readonly city: string | null,
        readonly email: string | null,
        readonly controllerAppraiserApi: ControllerAppraiserApi
    ) {
        makeObservable(this);
        this.controllerData = {
            salutation: salutation,
            initials: initials,
            firstName: firstName,
            lastName: lastName,
            rtNumber: rtNumber,
            companyName: companyName,
            city: city,
            email: email,
        };
        this.errorMessage = null;
        this.isDetailFieldsDisabled = this.hasData() === false;
    }

    public async mount() {
        //
    }

    public async unmount() {
        //
    }

    public onChange<T extends keyof ControllerData>(key: T, value: ControllerData[T]): void {
        runInAction(() => {
            this.controllerData = {
                ...this.controllerData,
                [key]: value,
            };
        });
    }

    public async onRequestData(email: string | null): Promise<void> {
        await runInAction(async () => {
            this.saving = true;
            const validates = email && email.length > 0;
            if (validates) {
                try {
                    const controllerAppraiser = await this.controllerAppraiserApi.requestControllerAppraiserData(email);
                    this.controllerData = this.mapData(controllerAppraiser);
                    this.isDetailFieldsDisabled = false;
                    this.errorMessage = null;
                } catch (error: string | unknown) {
                    this.errorMessage = typeof error === 'string' ? error : 'Er ging wat mis, probeer a.u.b. opnieuw.';
                    this.isDetailFieldsDisabled = true;
                } finally {
                    this.saving = false;
                }
            } else {
                this.saving = false;
            }
        });
    }

    private mapData(data: ControllerData | ControllerAppraiser | null): ControllerAppraiser {
        return {
            salutation: data?.salutation ?? null,
            initials: data?.initials ?? null,
            firstName: data?.firstName ?? null,
            lastName: data?.lastName ?? null,
            rtNumber: data?.rtNumber ?? null,
            companyName: data?.companyName ?? null,
            city: data?.city ?? null,
            email: data?.email ?? null,
            isInvited: data && 'isInvited' in data ? data.isInvited : false,
            isPriorAppraiser: data && 'isPriorAppraiser' in data ? data.isPriorAppraiser : false,
        };
    }

    private hasData(): boolean {
        return Object.entries(this.controllerData).map((arr) => arr[1]?.length > 0).length > 0;
    }
}
