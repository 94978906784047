import {AjaxDriver} from '../../network/driver/ajax_driver';
import {getDefaultAjaxHeaders} from '../../support/api_headers';
import {NeighborhoodResult} from '../models/neighborhood';

type NeighborhoodResultType = 'neighborhood' | 'district' | 'municipality';

export interface NeighborhoodApi {
    search(query: string, columns?: string[], types?: NeighborhoodResultType[]): Promise<NeighborhoodResult>;
    findByCodes(
        codes: string[],
        includeChildren?: boolean,
        columns?: string[],
        types?: NeighborhoodResultType[]
    ): Promise<NeighborhoodResult>;
    findByRange(
        postalCode: string,
        houseNumber: string,
        letter: string | null,
        rangeKm: number,
        columns?: string[],
        types?: NeighborhoodResultType[]
    ): Promise<NeighborhoodResult>;
    findByBounds(
        minLat: number,
        maxLat: number,
        minLng: number,
        maxLng: number,
        columns?: string[],
        types?: NeighborhoodResultType[],
        abortSignal?: AbortSignal
    ): Promise<NeighborhoodResult>;
}

export class DefaultNeighborhoodApi implements NeighborhoodApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public async search(
        query: string,
        columns?: string[],
        types?: NeighborhoodResultType[]
    ): Promise<NeighborhoodResult> {
        const response = await this.ajaxDriver.fetch(`/ajax/neighborhood/search`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: getDefaultAjaxHeaders(),
            body: JSON.stringify({query, columns, types}),
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        }

        throw new Error('Failed to query neighborhood');
    }

    public async findByCodes(
        codes: string[],
        includeChildren?: boolean,
        columns?: string[],
        types?: NeighborhoodResultType[]
    ): Promise<NeighborhoodResult> {
        const response = await this.ajaxDriver.fetch(`/ajax/neighborhood/find-by-codes`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: getDefaultAjaxHeaders(),
            body: JSON.stringify({codes, columns, include_children: includeChildren, types}),
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        }

        throw new Error('Failed to query neighborhood');
    }

    public async findByRange(
        postalCode: string,
        houseNumber: string,
        letter: string | null,
        rangeKm: number,
        columns?: string[],
        types?: NeighborhoodResultType[]
    ): Promise<NeighborhoodResult> {
        const response = await this.ajaxDriver.fetch(`/ajax/neighborhood/find-by-range`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: getDefaultAjaxHeaders(),
            body: JSON.stringify({
                postal_code: postalCode,
                house_number: houseNumber,
                letter,
                range_km: rangeKm,
                columns,
                types,
            }),
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        }

        throw new Error('Failed to query neighborhood');
    }

    public async findByBounds(
        minLat: number,
        maxLat: number,
        minLng: number,
        maxLng: number,
        columns?: string[],
        types?: NeighborhoodResultType[],
        abortSignal?: AbortSignal
    ): Promise<NeighborhoodResult> {
        const response = await this.ajaxDriver.fetch(`/ajax/neighborhood/find-by-bounds`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: getDefaultAjaxHeaders(),
            body: JSON.stringify({min_lat: minLat, max_lat: maxLat, min_lng: minLng, max_lng: maxLng, columns, types}),
            signal: abortSignal,
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        }

        throw new Error('Failed to query neighborhood');
    }
}
