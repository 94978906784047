import {makeObservable, observable, runInAction} from 'mobx';

import {Appraisal, ControllerAppraiser} from '../../../../../models/appraisal';
import {Presenter} from '../../../../../../support/presenter/presenter';
import {SalutationType} from '../../../../../enum/salutation_type';
import {ControllerAppraiserApi} from '../../../../../network/controller_appraiser_api';
import {AppraisalProvider} from '../../../../../business/appraisal_provider';

export interface ControllerData {
    salutation: SalutationType | null;
    initials: string | null;
    firstName: string | null;
    lastName: string | null;
    rtNumber: string | null;
    companyName: string | null;
    city: string | null;
    email: string | null;
}

export class PlausibilityRequestFormPresenter implements Presenter {
    @observable saving = false;
    @observable disabled = false;
    @observable isDetailFieldsDisabled = true;
    @observable isInvited = false;
    @observable isRemoveVisible = false;
    @observable errorMessage: string | null = null;
    @observable.ref emptyFields: string[] = [];
    @observable.ref controllerData: ControllerData = {
        salutation: null,
        initials: null,
        firstName: null,
        lastName: null,
        rtNumber: null,
        companyName: null,
        city: null,
        email: null,
    };

    public requiredFieldNames = ['salutation', 'initials', 'lastName', 'companyName', 'city', 'email'];

    constructor(
        readonly appraisal: Appraisal,
        readonly controllerAppraiserApi: ControllerAppraiserApi,
        private appraisalProvider: AppraisalProvider
    ) {
        makeObservable(this);
        this.errorMessage = null;
        this.controllerData = this.mapData(appraisal.controllerAppraiser);
        this.isInvited = appraisal.controllerAppraiser?.isInvited ?? false;
        this.disabled = appraisal.hasController || this.isInvited;
        this.isDetailFieldsDisabled = !this.validates();
        this.isRemoveVisible = appraisal.hasController || this.isInvited;
    }

    public async mount() {
        //
    }

    public async unmount() {
        //
    }

    public onChange<T extends keyof ControllerData>(key: T, value: ControllerData[T]): void {
        runInAction(() => {
            this.controllerData = {
                ...this.controllerData,
                [key]: value,
            };
        });
    }

    public async onRequestData(email: string | null): Promise<void> {
        await runInAction(async () => {
            this.saving = true;
            const validates = email && email.length > 0;

            if (validates) {
                try {
                    const controllerAppraiser = await this.controllerAppraiserApi.requestControllerAppraiserData(email);
                    this.controllerData = this.mapData(controllerAppraiser);
                    this.isDetailFieldsDisabled = false;
                    this.errorMessage = null;
                } catch (error: string | unknown) {
                    this.errorMessage = typeof error === 'string' ? error : 'Er ging wat mis, probeer a.u.b. opnieuw.';
                    this.isDetailFieldsDisabled = true;
                } finally {
                    this.disabled = false;
                    this.saving = false;
                }
            } else {
                this.saving = false;
            }
        });
    }

    public async onSubmit(): Promise<void> {
        await runInAction(async () => {
            this.saving = true;

            if (this.validates()) {
                try {
                    const appraisal = await this.controllerAppraiserApi.assignControllerAppraiser(
                        this.appraisal.id,
                        this.mapData(this.controllerData)
                    );
                    this.controllerData = this.mapData(appraisal.controllerAppraiser);
                    this.isInvited = appraisal.controllerAppraiser?.isInvited ?? false;
                    this.disabled = appraisal.hasController || this.isInvited;
                    this.isRemoveVisible = appraisal.hasController || this.isInvited;
                    this.errorMessage = null;
                    this.appraisalProvider.onChange(appraisal);
                } catch (error: string | unknown) {
                    this.errorMessage = typeof error === 'string' ? error : 'Er ging wat mis, probeer a.u.b. opnieuw.';
                } finally {
                    this.emptyFields = [];
                    this.saving = false;
                }
            } else {
                this.emptyFields = this.validate();
                this.saving = false;
            }
        });
    }

    public async onRemove(): Promise<void> {
        await runInAction(async () => {
            this.saving = true;

            if (this.validates()) {
                try {
                    const appraisal = await this.controllerAppraiserApi.detachControllerAppraiser(this.appraisal.id);
                    this.controllerData = this.mapData(appraisal.controllerAppraiser);
                    this.isInvited = appraisal.controllerAppraiser?.isInvited ?? false;
                    this.disabled = appraisal.hasController || this.isInvited;
                    this.isRemoveVisible = appraisal.hasController || this.isInvited;
                    this.errorMessage = null;
                } catch (error: string | unknown) {
                    this.errorMessage = typeof error === 'string' ? error : 'Er ging wat mis, probeer a.u.b. opnieuw.';
                } finally {
                    this.emptyFields = [];
                    this.saving = false;
                }
            } else {
                this.emptyFields = this.validate();
                this.saving = false;
            }
        });
    }

    private validates(): boolean {
        return this.validate().length === 0;
    }

    private validate(): string[] {
        return Object.entries(this.controllerData)
            .filter((field) => this.requiredFieldNames.includes(field[0]) && (field[1]?.length ?? 0) === 0)
            .map((field) => field[0]);
    }

    private mapData(data: ControllerData | ControllerAppraiser | null): ControllerAppraiser {
        return {
            salutation: data?.salutation ?? null,
            initials: data?.initials ?? null,
            firstName: data?.firstName ?? null,
            lastName: data?.lastName ?? null,
            rtNumber: data?.rtNumber ?? null,
            companyName: data?.companyName ?? null,
            city: data?.city ?? null,
            email: data?.email ?? null,
            isInvited: data && 'isInvited' in data ? data.isInvited : false,
            isPriorAppraiser: data && 'isPriorAppraiser' in data ? data.isPriorAppraiser : false,
        };
    }
}
