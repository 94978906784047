import {
    AnswerPathStubber,
    QuestionUuidIteratorUuidPair,
} from '../../../../../../../business/answering/answer_path_stubber';
import {ModalState, ModalType} from './models/modal_state';
import {NetworkStatus, NetworkStatusProvider} from '../../../../../../../business/network_status_provider';
import {createReferenceObjectData, ReferenceObjectData} from './internal/create_reference_object_data';
import {SortingDirection, SortingMethod} from '../../../../../../../enum/reference_objects_sorting';
import {
    findChildRecursiveByPredicate,
    findChildrenRecursiveByPredicate,
    TreeItem,
} from '../../../../../../../../support/generic_tree';
import {V3ReferenceSet, V3ReferenceSetsProvider} from './internal/reference_sets/reference_sets_provider';
import {action, autorun, computed, makeObservable, observable, runInAction} from 'mobx';

import {Answer} from '../../../../../../../models/answer';
import {AnswerController} from '../../../../../../../business/answering/answer_controller';
import {AnswerInteractor} from '../../../../../../../business/answering/answer_interactor';
import {Appraisal} from '../../../../../../../models/appraisal';
import {AppraisalProvider} from '../../../../../../../business/appraisal_provider';
import {AppraisalState} from '../../../../../../../enum/appraisal_state';
import {AppraisalValidationType} from '../../../../../../../enum/appraisal_validation_type';
import {AppraiseSecondaryConfigStackInteractor} from '../../../../../../../business/appraise_secondary_config_stack_interactor';
import {AppraiseSecondaryType} from '../../../../../../../models/appraise_secondary_config';
import {BuildYearProvider} from '../../../../../../../business/build_year_provider';
import {CompositeSubscription} from '../../../../../../../../support/composite_subscription';
import {IteratorQuestionType, NormalQuestionType} from '../../../../../../../enum/question_type';
import {Presenter} from '../../../../../../../../support/presenter/presenter';
import {Question} from '../../../../../../../models/question';
import {QuestionAnswerPair} from '../../../../../../../../support/question_answer_tree';
import {QuestionSet} from '../../../../../../../models/question_set';
import {ReferenceObject} from './models/reference_object';
import {ReferenceObjectAnswer} from './models/reference_object_answer';
import {ReferenceObjectSorter} from './internal/reference_object_sort/reference_object_sorter';
import {ReferenceObjectSortingStrategyProvider} from './internal/reference_object_sort/reference_object_sorting_strategy_provider';
import {ReferenceObjectsInteractor} from '../interactors/reference_objects_interactor';
import {ReferenceSaleSetData} from '../../../../../../../business/reference_object_provider';
import {TechnicalReference} from '../../../../../../../enum/technical_reference';
import {getFromReferenceObjectAnswer} from './internal/normalized_sale_date';
import {isEmpty} from '../../../../../../../../support/util';
import {isSet} from '../../../../../../../../support/is_set';
import {sortAnswersByCreatedAt} from '../../../../../../../../support/sort_answer';
import {fetchReferenceObjectPhoto} from './questions/reference_object_photo/internal/fetch_reference_object_photo';
import {ImageUploadInteractor} from '../../../../../../../business/attachments/image_upload_interactor';
import {findAllChildrenForQuestionUuid} from '../../../../../support/question_filtering';
import {GlobalProvider} from '../../../../../../../../business/global_provider';
import {FlashMessageBroadcaster} from '../../../../../../../business/flash_message_broadcaster';
import {isReferenceObjectAnswer} from './components/map/is_reference_object_answer';
import {SetType} from '../../../../../../../models/reference_set/set_type';
import {
    ReferenceSubscriptionPromotionType,
    ReferenceSubscriptions,
    ReferenceSubscriptionType,
} from '../../../../../../../models/reference_subscriptions';
import {isMobile, isTablet} from '../../../../../../../../support/check_mobile';
import {first} from 'rxjs/operators';
import {DistanceProvider} from '../../../../../../../business/distance_provider';
import {ReferenceObjectsV3Metadata} from '../../../../../../../models/reference_objects_metadata';
import {ReferenceObjectsMetadataProvider} from '../../../../../../../business/reference_objects_metadata/reference_objects_metadata_provider';
import {ReferenceObjectsMetadataVersion} from '../../../../../../../enum/reference_objects_metadata_version';
import {ReferenceFiltersData} from './components/reference_filters';
import {ReferenceSale} from '../v1/models/reference_sale';
import {ModalConfigStackInteractor} from '../../../../../../../business/modal_config_stack_interactor';
import {ModalType as GlobalModalType} from '../../../../../../../models/modal_config';
import {referenceObjectImages} from './internal/reference_sale_images';
import {
    ConfigWithCallbacks,
    DefaultConfigStackInteractor,
} from '../../../../../../../business/generic_config_stack_interactor';
import {ReferenceSaleImage} from '../v1/models/reference_sale_image';
import {ReferenceObjectsAnswerEnhancementInteractor} from '../interactors/reference_objects_answer_enhancement_interactor';

type SetDefinitionWithoutCount = V3ReferenceSet<TreeItem<QuestionAnswerPair>>;

export interface SetDefinition extends SetDefinitionWithoutCount {
    amountSelected: number;
    referenceObjectDatas: ReferenceObjectData[];
}

export enum ActiveSetDefinitionState {
    EMPTY = 'empty',
    DISABLED = 'disabled',
    ACTIVE = 'active',
}

interface EmptyActiveSetDefinition {
    state: ActiveSetDefinitionState.EMPTY;
}

interface SetDefinitionWithoutAnswer<T extends SetDefinitionWithoutCount> {
    state: ActiveSetDefinitionState.DISABLED | ActiveSetDefinitionState.ACTIVE;
    setDefinition: T;
    referenceObjectSetData: ReferenceSaleSetData | null;
    missingPreconditions: string[];
}

export interface ActiveSetDefinition extends SetDefinitionWithoutAnswer<SetDefinition> {
    referenceObjectTrees: Array<TreeItem<QuestionAnswerPair>>;
}

export interface VisibleReferenceObject {
    count: number;
    selectedObjects: ReferenceObjectData[];
    deletedSelectedObjects: ReferenceObjectData[];
    selectedPreselectedReferences: ReferenceObjectData[];
    nonSelectedPreselectedReferences: ReferenceObject[];
    nonSelectedObjects: ReferenceObject[];
    rejectedReferences: ReferenceObject[];
}

export interface SuggestedReferenceObjects {
    setDefinition: SetDefinition;
    objectDatas: ReferenceObjectData[];
}

export interface PreselectedReferenceObject {
    id: string;
    source: string;
}

export enum PromotionModalType {
    BRAINBAY_PROMOTION = 'brainbay-promotion',
    BRAINBAY_TRIAL_ENDED = 'brainbay-trial-ended',
}

const secondaryAppraisalId = 'object-references-v3-map';

export class ReferenceObjectsQuestionPresenter implements Presenter {
    public static readonly minReferenceObjects = 3;
    @observable public numVisible = 12;
    @observable public answer: Answer | null = null;
    @observable public sortingMethod = SortingMethod.ALPHABETICALLY;
    @observable public sortingDirection = SortingDirection.ASCENDING;
    @observable public isComparing = false;
    @observable public networkStatus: NetworkStatus = NetworkStatus.ONLINE;

    @observable.ref public modalState: ConfigWithCallbacks<ModalState, unknown>[] = [];
    public readonly referenceModalStack = new DefaultConfigStackInteractor<ModalState>();

    @observable public hoveringReferenceObjectId: string | null = null;
    @observable public clickedReferenceObject: ReferenceObject | ReferenceObjectAnswer | null = null;
    @observable public shouldShowDeletedObjects = false;
    @observable.ref public promotionModalType: PromotionModalType | null = null;
    @observable.ref public referenceSubscriptions: ReferenceSubscriptions | null = null;
    @observable.ref public forceUseSubscriptions: ReferenceSubscriptionType[] = [];
    @observable.ref public referenceObjectsMetadata: ReferenceObjectsV3Metadata | null = null;
    @observable.ref public filters: ReferenceFiltersData = {
        distance: [0, 50000],
        surfaceArea: [0, 1001],
        plotArea: [0, 150_001],
        buildYear: [1500, new Date().getFullYear()],
        age: [0, 50],
        ownership: {
            sorts: [],
            durations: [],
            other: [],
        },
        otherAspects: {},
    };

    @observable.ref private _referenceSets: SetDefinitionWithoutCount[] | null = null;
    @computed
    public get referenceSets(): SetDefinition[] | null {
        if (this._referenceSets === null) {
            return null;
        }

        return this._referenceSets.map((set) => {
            const referentieIterators = findChildrenRecursiveByPredicate(
                set.groupTree,
                (item) =>
                    item.question.type === IteratorQuestionType.ITERATOR_REFERENCE_OBJECTS_V3 && item.answer !== null,
                true
            );
            const referenceObjectDatas = this.extractReferenceObjectDatas(referentieIterators).filter(
                (so) => (so.treeItem.parent?.item.answer?.isDeleted ?? false) === false
            );

            return {
                ...set,
                amountSelected: referenceObjectDatas.length,
                referenceObjectDatas,
            };
        });
    }

    @observable private sortingMethodChangeCount = 0;
    @computed
    public get shouldShowSortingMethodTooltip() {
        return this.sortingMethodChangeCount === 1 && !isMobile();
    }

    @computed
    public get suggestedReferenceObjects(): SuggestedReferenceObjects[] {
        if (this.answer === null || this.referenceSets === null || this.activeSetDefinition === null) {
            return [];
        }

        return this.referenceSets.map((setDefinition) => {
            const referentieIterators = findChildrenRecursiveByPredicate(
                setDefinition.groupTree,
                (item) =>
                    item.question.type === IteratorQuestionType.ITERATOR_REFERENCE_OBJECTS_V3 && item.answer !== null,
                true
            );
            const objectDatas = this.extractReferenceObjectDatas(referentieIterators);
            return {
                setDefinition,
                objectDatas,
            };
        });
    }

    @computed
    public get canAdd(): boolean {
        const activeSet = this.activeSetDefinition;
        if (!activeSet || activeSet.state === ActiveSetDefinitionState.DISABLED) {
            return false;
        }

        return activeSet.setDefinition.amountSelected < this.questionSet.reportDefintionConfig.maxReferenceObjectsInSet;
    }

    @computed
    public get isFrozen(): boolean {
        if (this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return this.appraisal.status !== AppraisalState.PROCESSING && this.question.freezes;
        }

        return (
            this.appraisalProvider.appraisal.status === AppraisalState.APPROVED ||
            this.appraisalProvider.appraisal.status === AppraisalState.CANCELED ||
            this.appraisalProvider.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    @observable.ref private _activeSetDefinition:
        | SetDefinitionWithoutAnswer<SetDefinitionWithoutCount>
        | EmptyActiveSetDefinition = {
        state: ActiveSetDefinitionState.EMPTY,
    };
    @computed
    public get activeSetDefinition(): ActiveSetDefinition | null {
        if (this._activeSetDefinition.state === ActiveSetDefinitionState.EMPTY || this.answer === null) {
            return null;
        }

        const set = this.fillSetDefinition(this._activeSetDefinition.setDefinition);

        return {
            ...this._activeSetDefinition,
            setDefinition: set.setDefinition,
            referenceObjectTrees: set.referenceObjectTrees,
        };
    }

    private referenceObjectSorter = new ReferenceObjectSorter(
        new ReferenceObjectSortingStrategyProvider(this.buildYearProvider, this.distanceProvider)
    );

    private fillSetDefinition(setDefinition: SetDefinitionWithoutCount) {
        const referentieIterators = findChildrenRecursiveByPredicate(
            setDefinition.groupTree,
            (item) => item.question.type === IteratorQuestionType.ITERATOR_REFERENCE_OBJECTS_V3 && item.answer !== null,
            true
        );
        const referenceObjectDatas = this.extractReferenceObjectDatas(referentieIterators).filter(
            (so) => (so.treeItem.parent?.item.answer?.isDeleted ?? false) === false
        );

        return {
            setDefinition: {
                ...setDefinition,
                amountSelected: referenceObjectDatas.length,
                referenceObjectDatas,
            } as SetDefinition,
            referenceObjectTrees: referentieIterators,
        };
    }

    private extractReferenceObjectDatas(
        referenceObjectTrees: Array<TreeItem<QuestionAnswerPair>>
    ): ReferenceObjectData[] {
        return referenceObjectTrees
            .filter((tree) => tree.item.answer !== null)
            .map((treeItem) => {
                const objectAnswerChildTreeItem = findChildRecursiveByPredicate(
                    treeItem,
                    (item) =>
                        item.question.technicalReference === TechnicalReference.REFERENCE_OBJECTS_V3_ITERATOR_GROUP
                );
                if (!objectAnswerChildTreeItem) {
                    return null;
                }

                return createReferenceObjectData(objectAnswerChildTreeItem);
            })
            .filter((i): i is ReferenceObjectData => i !== null);
    }

    private sortReferenceObjects(setDefinition: SetDefinition): {
        selectedReferenceObjects: ReferenceObjectData[];
        deletedSelectedReferenceObjects: ReferenceObjectData[];
        selectedPreselectedReferences: ReferenceObjectData[];
        nonSelectedPreselectedReferences: ReferenceObject[];
        nonSelectedReferenceObjects: ReferenceObject[];
        rejectedReferences: ReferenceObject[];
    } {
        // If the provided set definition is not the active set definition, only the trees will be filled
        // We will not fetch the reference object data from the server
        let referenceObjectTrees: TreeItem<QuestionAnswerPair>[] | null = null;
        let referenceObjectSetData: ReferenceSaleSetData | null = null;

        if (this.activeSetDefinition?.setDefinition === setDefinition) {
            referenceObjectTrees = this.activeSetDefinition.referenceObjectTrees;
            referenceObjectSetData = this.activeSetDefinition.referenceObjectSetData;
        } else {
            referenceObjectTrees = findChildrenRecursiveByPredicate(
                setDefinition.groupTree,
                (item) =>
                    item.question.type === IteratorQuestionType.ITERATOR_REFERENCE_OBJECTS_V3 && item.answer !== null,
                true
            );
        }

        const datas = this.extractReferenceObjectDatas(referenceObjectTrees);

        const preselectedReferenceObjects = this.referenceObjectsMetadata?.preselectedObjects ?? [];
        const rejectedReferenceObjects = this.referenceObjectsMetadata?.rejectedObjects ?? [];

        const selectedObjects = datas
            .filter(
                (roa) =>
                    roa.treeItem.parent?.item.answer?.isDeleted === true ||
                    !preselectedReferenceObjects.some(
                        (obj) =>
                            obj.id === roa.referenceObjectAnswer.id && obj.source === roa.referenceObjectAnswer.source
                    )
            )
            .sort((a, b) => sortAnswersByCreatedAt(a.treeItem.item.answer, b.treeItem.item.answer));

        const nonDeletedSelectedObjects = selectedObjects.filter(
            (so) => (so.treeItem.parent?.item.answer?.isDeleted ?? false) === false
        );
        const deletedSelectedObjects = selectedObjects.filter(
            (so) => so.treeItem.parent?.item.answer?.isDeleted === true
        );

        const sortedSelectedPreselectedReferences = this.referenceObjectSorter.sortReferenceObjects(
            datas.filter(
                (roa) =>
                    (roa.treeItem.parent?.item.answer?.isDeleted ?? false) === false &&
                    preselectedReferenceObjects.some(
                        (obj) =>
                            obj.id === roa.referenceObjectAnswer.id && obj.source === roa.referenceObjectAnswer.source
                    )
            ),
            this.transformReferenceObjectData,
            this.appraisal,
            this.sortingMethod,
            this.sortingDirection,
            setDefinition.surfaceArea,
            setDefinition.plotArea
        );

        const nonSelectedObjects =
            referenceObjectSetData?.referenceSales?.filter(
                (rs) =>
                    !nonDeletedSelectedObjects.some((object) => object.referenceObjectAnswer.id === rs.id) &&
                    !deletedSelectedObjects.some((object) => object.referenceObjectAnswer.id === rs.id) &&
                    !preselectedReferenceObjects.some((obj) => obj.id === rs.id && obj.source === rs.source) &&
                    !rejectedReferenceObjects.some((obj) => obj.id === rs.id && obj.source === rs.source) &&
                    this.matchesFilters(setDefinition, rs)
            ) ?? [];

        const nonSelectedPreselectedReferences =
            referenceObjectSetData?.referenceSales?.filter(
                (rs) =>
                    !nonDeletedSelectedObjects.some((roa) => roa.referenceObjectAnswer.id === rs.id) &&
                    !deletedSelectedObjects.some((object) => object.referenceObjectAnswer.id === rs.id) &&
                    !sortedSelectedPreselectedReferences.some((roa) => roa.referenceObjectAnswer.id === rs.id) &&
                    preselectedReferenceObjects.some((obj) => obj.id === rs.id && obj.source === rs.source) &&
                    !rejectedReferenceObjects.some((obj) => obj.id === rs.id && obj.source === rs.source) &&
                    this.matchesFilters(setDefinition, rs)
            ) ?? [];

        const sortedNonSelectedObjects = this.referenceObjectSorter.sortReferenceObjects(
            nonSelectedObjects,
            (object) => object,
            this.appraisal,
            this.sortingMethod,
            this.sortingDirection,
            setDefinition.surfaceArea,
            setDefinition.plotArea
        );

        return {
            selectedReferenceObjects: nonDeletedSelectedObjects,
            deletedSelectedReferenceObjects: deletedSelectedObjects,
            selectedPreselectedReferences: sortedSelectedPreselectedReferences,
            nonSelectedPreselectedReferences: nonSelectedPreselectedReferences,
            nonSelectedReferenceObjects: sortedNonSelectedObjects,
            rejectedReferences: rejectedReferenceObjects,
        };
    }

    private transformReferenceObjectData = (pair: ReferenceObjectData) => {
        return {
            id: pair.referenceObjectAnswer.id,
            source: pair.referenceObjectAnswer.source || null,
            street: pair.referenceObjectAnswer.referenceObject.adres.straat,
            houseNumber: pair.referenceObjectAnswer.referenceObject.adres.huisnummer,
            letter: pair.referenceObjectAnswer.referenceObject.adres.huisnummerToevoeging,
            latitude: pair.referenceObjectAnswer.referenceObject.adres.latitude
                ? pair.referenceObjectAnswer.referenceObject.adres.latitude
                : 0,
            longitude: pair.referenceObjectAnswer.referenceObject.adres.longitude
                ? pair.referenceObjectAnswer.referenceObject.adres.longitude
                : 0,
            buildYear: pair.referenceObjectAnswer.referenceObject.bouwjaar,
            plotArea: pair.referenceObjectAnswer.referenceObject.perceelOppervlakte,
            floorArea: pair.referenceObjectAnswer.referenceObject.gebruiksOppervlakte,
            matchingPercentage: pair.referenceObjectAnswer.matchingPercentage,
            normalizedSaleDate: getFromReferenceObjectAnswer(pair.referenceObjectAnswer),
        };
    };

    private matchesFilters(setDefinition: SetDefinition, rs: ReferenceSale) {
        if (
            setDefinition.groupTree.item.question.technicalReference === TechnicalReference.VALUATION_FORCED_SALE_GROUP
        ) {
            return true; // Filters are disabled for forced sale as it has it's own filtering mechanisms
        }

        const distance = this.distanceProvider.getDistanceToAppraisal(rs);

        if (distance !== null && (distance < this.filters.distance[0] || distance > this.filters.distance[1])) {
            return false;
        }

        if (
            rs.floorArea !== null &&
            (rs.floorArea < this.filters.surfaceArea[0] || rs.floorArea > this.filters.surfaceArea[1])
        ) {
            return false;
        }

        if (
            rs.plotArea !== null &&
            (rs.plotArea < this.filters.plotArea[0] || rs.plotArea > this.filters.plotArea[1])
        ) {
            return false;
        }

        if (
            rs.buildYear !== null &&
            (rs.buildYear < this.filters.buildYear[0] || rs.buildYear > this.filters.buildYear[1])
        ) {
            return false;
        }

        const saleDate = rs.normalizedSaleDate
            ? new Date(rs.normalizedSaleDate.year, rs.normalizedSaleDate.month, 1)
            : null;
        const ageYear = saleDate ? new Date().getFullYear() - saleDate.getFullYear() : null;
        if (ageYear !== null && (ageYear < this.filters.age[0] || ageYear > this.filters.age[1])) {
            return false;
        }

        const ownershipProperties =
            rs.additionalPropertiesByCategory?.['Overige kenmerken']?.['Eigendomskenmerken']?.toLowerCase();

        if (
            this.filters.ownership.sorts.length > 0 &&
            (!ownershipProperties ||
                !this.filters.ownership.sorts.some((s) => ownershipProperties.includes(s.toLowerCase())))
        ) {
            return false;
        }

        if (
            this.filters.ownership.durations.length > 0 &&
            (!ownershipProperties ||
                !this.filters.ownership.durations.some((s) => ownershipProperties.includes(s.toLowerCase())))
        ) {
            return false;
        }

        if (
            this.filters.ownership.other.length > 0 &&
            (!ownershipProperties ||
                !this.filters.ownership.other.some((s) => ownershipProperties.includes(s.toLowerCase())))
        ) {
            return false;
        }

        if (this.filters.otherAspects.garden && !rs.additionalPropertiesByCategory?.['Buitenzijde']?.['Tuin']) {
            return false;
        }

        if (
            this.filters.otherAspects.parking &&
            !rs.additionalPropertiesByCategory?.['Buitenzijde']?.['Parkeerplaats']
        ) {
            return false;
        }

        if (this.filters.otherAspects.shed && !rs.additionalPropertiesByCategory?.['Buitenzijde']?.['Bergruimte']) {
            return false;
        }

        if (this.filters.otherAspects.garage && !rs.additionalPropertiesByCategory?.['Buitenzijde']?.['Garage']) {
            return false;
        }

        if (this.filters.otherAspects.solar && !rs.additionalPropertiesByCategory?.['Installaties']?.['Zonnepanelen']) {
            return false;
        }

        return true;
    }

    @computed
    public get visibleReferenceObjects(): VisibleReferenceObject | null {
        const activeSetDefinition = this.activeSetDefinition;
        if (activeSetDefinition === null) {
            return null;
        }

        return this.getVisibleReferenceObjectsForSet(activeSetDefinition.setDefinition);
    }

    private getVisibleReferenceObjectsForSet(setDefinition: SetDefinition) {
        const {
            selectedReferenceObjects,
            deletedSelectedReferenceObjects,
            selectedPreselectedReferences,
            nonSelectedPreselectedReferences,
            nonSelectedReferenceObjects,
            rejectedReferences,
        } = this.sortReferenceObjects(setDefinition);

        const selectedVisibleReferenceObjects = selectedReferenceObjects.slice(0, this.numVisible);
        const nonSelectedVisibleReferenceObjects = nonSelectedReferenceObjects.slice(
            0,
            this.numVisible - selectedVisibleReferenceObjects.length
        );

        return {
            count: selectedVisibleReferenceObjects.length + nonSelectedVisibleReferenceObjects.length,
            selectedObjects: selectedVisibleReferenceObjects,
            deletedSelectedObjects: deletedSelectedReferenceObjects,
            selectedPreselectedReferences: selectedPreselectedReferences,
            nonSelectedObjects: nonSelectedVisibleReferenceObjects,
            nonSelectedPreselectedReferences: nonSelectedPreselectedReferences,
            rejectedReferences: rejectedReferences,
        };
    }

    private subscriptions = new CompositeSubscription();
    private activeSetSubscriptions = new CompositeSubscription();

    constructor(
        private appraisal: Appraisal,
        private question: Question,
        private questionSet: QuestionSet,
        private appraisalProvider: AppraisalProvider,
        private parentAnswerUuid: string | undefined,
        private iteration: string | undefined,
        private answerController: AnswerController,
        private referenceSetsProvider: V3ReferenceSetsProvider,
        private referenceObjectsInteractor: ReferenceObjectsInteractor,
        private appraiseSecondaryConfigStackInteractor: AppraiseSecondaryConfigStackInteractor,
        private modalConfigStackInteractor: ModalConfigStackInteractor,
        private networkStatusProvider: NetworkStatusProvider,
        private buildYearProvider: BuildYearProvider,
        private answerPathStubber: AnswerPathStubber,
        private answerInteractor: AnswerInteractor,
        private imageUploadInteractor: ImageUploadInteractor,
        public flashMessageBroadcaster: FlashMessageBroadcaster,
        private globalProvider: GlobalProvider,
        private distanceProvider: DistanceProvider,
        private referenceObjectsMetadataProvider: ReferenceObjectsMetadataProvider,
        private referenceObjectsAnswerEnhancementInteractor: ReferenceObjectsAnswerEnhancementInteractor,
        private isWidget = false
    ) {
        makeObservable(this);

        this.updateSubscriptions(globalProvider.global.referenceSubscriptions, true);

        runInAction(() => {
            if (this.globalProvider.global.referencePreferences?.sortMethod) {
                this.sortingMethod = this.globalProvider.global.referencePreferences.sortMethod;
                this.sortingDirection =
                    this.globalProvider.global.referencePreferences.sortDirection ??
                    this.getDefaultSortingDirection(this.sortingMethod);
            }
        });
    }

    public async mount() {
        this.subscriptions.add(
            this.answerController
                .answerByIdentifiersStream(this.question.uuid, this.parentAnswerUuid ?? null, this.iteration ?? null)
                .subscribe((answer) => {
                    runInAction(() => {
                        this.answer = answer;
                    });
                })
        );

        this.subscriptions.add(
            this.networkStatusProvider.status().subscribe((networkStatus) => {
                runInAction(() => {
                    this.networkStatus = networkStatus;
                });
            })
        );

        this.subscriptions.add(
            this.referenceModalStack.stream().subscribe((modalState) => {
                runInAction(() => {
                    this.modalState = modalState;
                });
            })
        );

        this.subscriptions.add(
            this.referenceSetsProvider.referenceSets().subscribe((sets) => {
                const nonEmptySets = sets?.filter((s) => s.type !== SetType.NONE);

                if (nonEmptySets) {
                    const updatedV3ReferenceSet = nonEmptySets.find(
                        (rs) => rs.valuationType === this.activeSetDefinition?.setDefinition.valuationType
                    );
                    if (updatedV3ReferenceSet) {
                        this.debouncedSetActiveSetDefinition(updatedV3ReferenceSet);
                    } else if (
                        this._activeSetDefinition.state === ActiveSetDefinitionState.EMPTY &&
                        nonEmptySets.length > 0
                    ) {
                        this.setActiveSetDefinition(nonEmptySets[0]);
                    }

                    runInAction(() => {
                        this._referenceSets = nonEmptySets;
                    });
                }
            })
        );

        this.subscriptions.add(
            autorun(() => {
                //If the modal is open, dont update the secondary view, performance reasons
                if (this.modalState.length > 0 || this.isWidget) {
                    return;
                }

                if (
                    this.activeSetDefinition === null ||
                    this.visibleReferenceObjects === null ||
                    isEmpty(this.appraisal.objectType)
                ) {
                    const tempSecondaryAppraisalId = 'temp_' + secondaryAppraisalId;
                    this.appraiseSecondaryConfigStackInteractor.remove((c) => {
                        return c.id !== tempSecondaryAppraisalId && c.type === AppraiseSecondaryType.REFERENCE_V3_MAP;
                    });
                    this.appraiseSecondaryConfigStackInteractor.upsert({
                        id: tempSecondaryAppraisalId,
                        type: AppraiseSecondaryType.REFERENCE_V3_MAP,
                        setType: SetType.SOLD,
                        onClose: () => {
                            //Noop
                        },
                        onClickChange: () => {
                            //Noop
                        },
                        onHoverChange: () => {
                            //Noop
                        },
                        clickedReferenceObject: null,
                        hoveringReferenceObjectId: null,
                        appraisal: this.appraisal,
                        questionSet: this.questionSet,
                        showDetailsModal: () => {
                            //Noop
                        },
                        visibleReferenceObjects: {
                            count: 0,
                            selectedObjects: [],
                            deletedSelectedObjects: [],
                            selectedPreselectedReferences: [],
                            nonSelectedPreselectedReferences: [],
                            nonSelectedObjects: [],
                            rejectedReferences: [],
                        },
                        subscriptions: this.referenceSubscriptions,
                    });
                } else {
                    const loadedSecondaryAppraisalId = 'loaded_' + secondaryAppraisalId;
                    this.appraiseSecondaryConfigStackInteractor.remove((c) => {
                        return c.id !== loadedSecondaryAppraisalId && c.type === AppraiseSecondaryType.REFERENCE_V3_MAP;
                    });

                    this.appraiseSecondaryConfigStackInteractor.upsert({
                        id: loadedSecondaryAppraisalId,
                        type: AppraiseSecondaryType.REFERENCE_V3_MAP,
                        setType: this.activeSetDefinition.setDefinition.type,
                        onClose: () => {
                            //Noop
                        },
                        onClickChange: (rs) => this.setClickedReferenceObject(rs),
                        onHoverChange: (rs) => this.setHoveringReferenceObject(rs),
                        clickedReferenceObject: this.clickedReferenceObject,
                        hoveringReferenceObjectId: this.hoveringReferenceObjectId,
                        appraisal: this.appraisal,
                        questionSet: this.questionSet,
                        visibleReferenceObjects: this.visibleReferenceObjects,
                        showDetailsModal: this.showDetailsModal,
                        subscriptions: this.referenceSubscriptions,
                    });
                }
            })
        );

        runInAction(() => {
            this.numVisible = isMobile() || isTablet() ? 12 : 60;
        });
    }

    public async unmount() {
        this.subscriptions.clear();
        this.appraiseSecondaryConfigStackInteractor.remove((c) => String(c.id).endsWith(secondaryAppraisalId));
    }

    @action
    public setClickedReferenceObject = (referenceObject: ReferenceObject | ReferenceObjectAnswer | null) => {
        this.clickedReferenceObject = referenceObject;
    };

    @action
    public setHoveringReferenceObject = (referenceObjectId: string | null) => {
        this.hoveringReferenceObjectId = referenceObjectId;
    };

    @action
    public onRemove = async (iteratorAnswerUuid: string) => {
        this.answerController.delete(iteratorAnswerUuid);
        if (this.modalState !== null) {
            this.onModalHide();
        }
    };

    public onRestore = async (iteratorAnswerUuid: string) => {
        this.answerController.restore(iteratorAnswerUuid);
    };

    @action
    public toggleShowDeletedObjects = () => {
        this.shouldShowDeletedObjects = !this.shouldShowDeletedObjects;
    };

    @action
    public setFilters = (filters: ReferenceFiltersData) => {
        this.filters = filters;
    };

    @action
    public onAddAndOpenModal = async (referenceObjectAnswer: ReferenceObjectAnswer) => {
        const result = await this.onAdd(referenceObjectAnswer, null, null);

        if (result) {
            const iteratorAnswerUuid = result.iteratorAnswer.uuid;
            const parentAnswerUuid = result.groupAnswer.parentUuid;
            const question = this.questionSet.findQuestionByUuid(result.groupAnswer.questionUuid);

            if (iteratorAnswerUuid && parentAnswerUuid && question) {
                this.onModalHide();

                if (this.isComparisonModalPreferred()) {
                    // For the comparison modal, we first allow the appraiser to pick all reference objects before answering the questions
                    return;
                }

                //If we open the new modal too quickly, the body doesnt get the modal class and scrolling is still active on the body
                setTimeout(() => {
                    this.showPreferredModalByUuid(
                        referenceObjectAnswer,
                        question,
                        iteratorAnswerUuid,
                        parentAnswerUuid
                    );
                }, 500);
            }
        }
    };

    private addValueToMap = (
        parentQuestion: Question,
        answersMap: Record<string, string[] | string | number | boolean | null>,
        technicalReference: TechnicalReference,
        value: string | number | boolean | null | undefined
    ) => {
        const path = this.questionSet.findChildrenPathByPredicateRecursive(
            parentQuestion,
            (q) => q.technicalReference === technicalReference
        );
        if (path !== null) {
            const question = path[path.length - 1];
            if (question && isSet(value)) {
                answersMap[question.uuid] = value;
            }
        }
    };

    private addMultipleValuesToMap = (
        parentQuestion: Question,
        answersMap: Record<string, string[] | string | number | boolean | null>,
        technicalReference: TechnicalReference,
        values: string[] | null
    ) => {
        values?.forEach((value) => {
            const path = this.questionSet.findChildrenPathByPredicateRecursive(
                parentQuestion,
                (q) =>
                    q.technicalReference === technicalReference &&
                    (q.reportValue?.toLocaleLowerCase() ?? q.contents.toLocaleLowerCase()) === value.toLocaleLowerCase()
            );
            if (path !== null) {
                const question = path[path.length - 1];
                answersMap[question.uuid] = question.type === NormalQuestionType.BOOLEAN ? '1' : value;
            }
        });
    };

    public onAdd = async (
        toBeAddReferenceObjectAnswer: ReferenceObjectAnswer,
        photoAnswerJsonContents: string | null,
        referenceObjectData: ReferenceObjectData | null
    ) => {
        if (this.activeSetDefinition === null) {
            return;
        }

        const groupItem = findChildRecursiveByPredicate(
            this.activeSetDefinition.setDefinition.groupTree,
            (item) => item.question.technicalReference === TechnicalReference.REFERENCE_OBJECTS_V3_ITERATOR_GROUP
        );
        if (groupItem === null) {
            throw new Error(
                'No child question with TechnicalReference.REFERENCE_OBJECTS_V3_ITERATOR_GROUP found for this set.'
            );
        }

        toBeAddReferenceObjectAnswer = {
            ...toBeAddReferenceObjectAnswer,
            valuationType: this.activeSetDefinition.setDefinition.valuationType,
        };

        //In the case one of our parents is an iteration, we need to stub the children path while staying in this iteration
        let cursor = this.activeSetDefinition.setDefinition.groupTree;
        const parentTrace: QuestionUuidIteratorUuidPair[] = [];
        while (cursor.parent !== null) {
            if (cursor.parent.item.answer?.iteration) {
                parentTrace.push({
                    questionUuid: cursor.parent.item.question.uuid,
                    iteration: cursor.parent.item.answer?.iteration,
                });
            }
            cursor = cursor.parent;
        }

        const pathPairs = this.answerPathStubber.stubPathForQuestionUuid(groupItem.item.question.uuid, parentTrace, {
            [groupItem.item.question.uuid]: JSON.stringify(toBeAddReferenceObjectAnswer),
        });
        const groupAnswerPair = pathPairs[pathPairs.length - 1];
        const iteratorAnswerPair = pathPairs.find((pair) => pair.answer.uuid === groupAnswerPair.answer.parentUuid);
        if (!iteratorAnswerPair) {
            throw new Error('Failed creating iteration');
        }

        const objectAnswers: Record<string, string | number | boolean> = {};

        if (photoAnswerJsonContents !== null) {
            this.addValueToMap(
                iteratorAnswerPair.question,
                objectAnswers,
                TechnicalReference.REFERENCE_OBJECT_PHOTO,
                photoAnswerJsonContents
            );
        }

        const toBeAddReferenceObjectDataMap: Record<string, string[] | string | number | boolean> = {};

        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_ADRES] =
            toBeAddReferenceObjectAnswer.referenceObject.adres.huisnummerToevoeging;
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_BOUWJAAR] =
            toBeAddReferenceObjectAnswer.referenceObject.bouwjaar ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_BOUWJAAR_UITLEG] =
            toBeAddReferenceObjectAnswer.referenceObject.bouwjaarUitleg;
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_ENERGIELABEL] =
            toBeAddReferenceObjectAnswer.referenceObject.energielabel;
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_ENERGIELABEL_GELDIG_TOT] =
            toBeAddReferenceObjectAnswer.referenceObject.energielabelGeldigTot ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_ENERGIELABEL_VOORLOPIG] =
            toBeAddReferenceObjectAnswer.referenceObject.energielabelVoorlopig ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_WONING_TYPE] =
            toBeAddReferenceObjectAnswer.referenceObject.woningType;
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_BRON_GEGEVENS] =
            toBeAddReferenceObjectAnswer.referenceObject.bronGegevens ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_GEBRUIKS_OPPERVLAKTE] =
            toBeAddReferenceObjectAnswer.referenceObject.gebruiksOppervlakte ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_GEBRUIKS_OPPERVLAKTE_UITLEG] =
            toBeAddReferenceObjectAnswer.referenceObject.gebruiksOppervlakteUitleg ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_INHOUD] =
            toBeAddReferenceObjectAnswer.referenceObject.inhoud ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_INHOUD_UITLEG] =
            toBeAddReferenceObjectAnswer.referenceObject.inhoudUitleg ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_PERCEEL_OPPERVLAKTE] =
            toBeAddReferenceObjectAnswer.referenceObject.perceelOppervlakte ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_PERCEEL_OPPERVLAKTE_UITLEG] =
            toBeAddReferenceObjectAnswer.referenceObject.perceelOppervlakteUitleg ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_AANVANGS_HUURPRIJS_PER_MAAND] =
            toBeAddReferenceObjectAnswer.referenceObject.aanvangsHuurprijsPerMaand ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_GECORRIGEERDE_HUURPRIJS_PER_MAAND] =
            toBeAddReferenceObjectAnswer.referenceObject.gecorrigeerdeHuurprijsPerMaand ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_INGANGSDATUM_HUUR] =
            toBeAddReferenceObjectAnswer.referenceObject.ingangsdatumHuur ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_VERKOOPPRIJS] =
            toBeAddReferenceObjectAnswer.referenceObject.verkoopprijs ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_GECORRIGEERDE_VERKOOPPRIJS] =
            toBeAddReferenceObjectAnswer.referenceObject.gecorrigeerdeVerkoopprijs ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_VERKOOPDATUM] =
            toBeAddReferenceObjectAnswer.referenceObject.verkoopdatum ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_TRANSPORTDATUM] =
            toBeAddReferenceObjectAnswer.referenceObject.transportdatum ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_VERKOCHT_BINNEN] =
            toBeAddReferenceObjectAnswer.referenceObject.verkochtBinnen ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_VOLLE_EIGENDOM] =
            toBeAddReferenceObjectAnswer.referenceObject.eigendomssituatieVolleEigendom ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_ERFPACHT] =
            toBeAddReferenceObjectAnswer.referenceObject.eigendomssituatieRechtErfpacht ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_ONDERERFPACHT] =
            toBeAddReferenceObjectAnswer.referenceObject.eigendomssituatieRechtOndererfpacht ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_OPSTAL] =
            toBeAddReferenceObjectAnswer.referenceObject.eigendomssituatieRechtOpstal ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_GEBRUIK_BEWONING] =
            toBeAddReferenceObjectAnswer.referenceObject.eigendomssituatieRechtGebruikBewoning ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_VRUCHTGEBRUIK] =
            toBeAddReferenceObjectAnswer.referenceObject.eigendomssituatieRechtVruchtgebruik ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_ANDERS] =
            toBeAddReferenceObjectAnswer.referenceObject.eigendomssituatieRechtAnders ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_TOELICHTING] =
            toBeAddReferenceObjectAnswer.referenceObject.eigendomssituatieToelichting ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_STATUS] =
            toBeAddReferenceObjectAnswer.referenceObject.eigendomssituatieStatus ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_UITLEG] =
            toBeAddReferenceObjectAnswer.referenceObject.eigendomssituatieUitleg ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_ONDERHOUDSSITUATIE] =
            toBeAddReferenceObjectAnswer.referenceObject.onderhoudssituatie ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_ONDERHOUDS_SITUATIE_STATUS] =
            toBeAddReferenceObjectAnswer.referenceObject.onderhoudsSituatieStatus ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_ONDERHOUDS_SITUATIE_UITLEG] =
            toBeAddReferenceObjectAnswer.referenceObject.onderhoudsSituatieUitleg ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_WONING_TYPE_STATUS] =
            toBeAddReferenceObjectAnswer.referenceObject.woningTypeStatus ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_WONING_TYPE_UITLEG] =
            toBeAddReferenceObjectAnswer.referenceObject.woningTypeUitleg;
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_MATE_VAN_LUXE_STATUS] =
            toBeAddReferenceObjectAnswer.referenceObject.mateVanLuxeStatus ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_MATE_VAN_LUXE_UITLEG] =
            toBeAddReferenceObjectAnswer.referenceObject.mateVanLuxeUitleg ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_MATE_VAN_DOELMATIGHEID_STATUS] =
            toBeAddReferenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_MATE_VAN_DOELMATIGHEID_UITLEG] =
            toBeAddReferenceObjectAnswer.referenceObject.mateVanDoelmatigheidUitleg ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_LIGGING_STATUS] =
            toBeAddReferenceObjectAnswer.referenceObject.liggingStatus;
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_LIGGING_UITLEG] =
            toBeAddReferenceObjectAnswer.referenceObject.liggingUitleg;
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_AANBOUW_STATUS] =
            toBeAddReferenceObjectAnswer.referenceObject.aanbouwStatus;
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_AANBOUW_UITLEG] =
            toBeAddReferenceObjectAnswer.referenceObject.aanbouwUitleg;
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_ANDERE_WEZENLIJKE_VERSCHILLEN] =
            toBeAddReferenceObjectAnswer.referenceObject.andereWezenlijkeVerschillen ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_ANDERE_WEZENLIJKE_VERSCHILLEN_UITLEG] =
            toBeAddReferenceObjectAnswer.referenceObject.andereWezenlijkeVerschillenUitleg ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_TOELICHTING_GEBRUIK_REFERENTIE_OBJECT] =
            toBeAddReferenceObjectAnswer.referenceObject.toelichtingGebruikReferentieObject;
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_AANBOUW] =
            referenceObjectData?.referenceObjectValues.aanbouw ??
            toBeAddReferenceObjectAnswer.referenceObject.aanbouw.split(',');
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_TE_KOOP_VRAAGPRIJS] =
            toBeAddReferenceObjectAnswer.referenceObject.vraagprijs ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_TE_KOOP_DATUM] =
            toBeAddReferenceObjectAnswer.referenceObject.teKoopSinds ?? '';
        toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_TE_KOOP_OORSPRONKELIJKE_VRAAGPRIJS] =
            toBeAddReferenceObjectAnswer.referenceObject.oorspronkelijkeVraagprijs ?? '';

        if (toBeAddReferenceObjectAnswer.referenceObject.bronGegevens === 'Altum') {
            toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_BRON_GEGEVENS] = 'Anders';
            toBeAddReferenceObjectDataMap[TechnicalReference.REFERENCE_OBJECT_BRON_GEGEVENS_TOELICHTING] = 'taXapi';
        }

        for (const technicalReference in toBeAddReferenceObjectDataMap) {
            if (technicalReference === TechnicalReference.REFERENCE_OBJECT_AANBOUW) {
                this.addMultipleValuesToMap(
                    iteratorAnswerPair.question,
                    objectAnswers,
                    technicalReference as TechnicalReference,
                    toBeAddReferenceObjectDataMap[technicalReference] as string[] | null
                );
            } else {
                this.addValueToMap(
                    iteratorAnswerPair.question,
                    objectAnswers,
                    technicalReference as TechnicalReference,
                    toBeAddReferenceObjectDataMap[technicalReference] as string | number | boolean | null | undefined
                );
            }
        }

        const answers = this.answerPathStubber.stubChildren(groupAnswerPair.answer, objectAnswers);

        // When there is no photoAnswerJsonContents, add the first imageUrlPairs as answer
        if (photoAnswerJsonContents === null) {
            const images = referenceObjectImages(null, toBeAddReferenceObjectAnswer);

            if (images.length > 0) {
                const question = findAllChildrenForQuestionUuid(
                    iteratorAnswerPair.question.uuid,
                    this.questionSet
                ).find((q) => q.technicalReference === TechnicalReference.REFERENCE_OBJECT_PHOTO);
                if (question) {
                    const answer = answers.find((a) => (a.questionUuid = question.uuid));
                    const url = images[0].url;
                    const file = url ? await fetchReferenceObjectPhoto(url) : null;
                    if (file && answer) {
                        await this.imageUploadInteractor.uploadForAnswer(answer.uuid, file, {});
                    }
                }
            }
        }

        await this.answerInteractor.submit();

        return {
            iteratorAnswer: iteratorAnswerPair.answer,
            groupAnswer: groupAnswerPair.answer,
        };
    };

    private timeout: number | null = null;
    public debouncedSetActiveSetDefinition = async (setDefinition: SetDefinitionWithoutCount) => {
        // Debouncing of set type sale is not necessary as we do not trigger a request immediately anyway
        if (setDefinition.type === SetType.SALE) {
            this.setActiveSetDefinition(setDefinition);
            return;
        }

        if (this.timeout) {
            clearInterval(this.timeout);
        }

        this.timeout = window.setTimeout(() => {
            this.setActiveSetDefinition(setDefinition);
        }, 1000);
    };

    private getFailedPreconditions(setDefinition: SetDefinitionWithoutCount) {
        const missingPreconditions: string[] = [];

        if (!isSet(setDefinition.buildYear)) {
            missingPreconditions.push('bouwjaar');
        }

        //if (
        //    !isSet(setDefinition.plotArea) &&
        //    (this.appraisal.objectType === null || !isApartment(this.appraisal.objectType))
        //) {
        //    missingPreconditions.push('perceeloppervlak');
        //}

        if (!isSet(setDefinition.surfaceArea)) {
            missingPreconditions.push('gebruiksoppervlakte wonen');
        }

        if (!isSet(setDefinition.valuationDate)) {
            missingPreconditions.push('opnamedatum');
        }

        // Object type can be ''
        if (isEmpty(this.appraisal.objectType)) {
            missingPreconditions.push('object type');
        }

        return missingPreconditions;
    }

    @action
    public setActiveSetDefinition = async (setDefinition: SetDefinitionWithoutCount) => {
        this.activeSetSubscriptions.clear();

        const missingPrecondition = this.getFailedPreconditions(setDefinition);

        const oldActiveSetDefinition = this._activeSetDefinition;

        runInAction(() => {
            this._activeSetDefinition = {
                setDefinition: setDefinition,
                state:
                    missingPrecondition.length === 0
                        ? ActiveSetDefinitionState.DISABLED
                        : ActiveSetDefinitionState.ACTIVE,
                referenceObjectSetData: this.activeSetDefinition?.referenceObjectSetData ?? null,
                missingPreconditions: missingPrecondition,
            };

            this.referenceObjectsMetadata = null;
        });

        if (missingPrecondition.length === 0) {
            if (this.globalProvider.global.referencePreferences?.sortMethod === null) {
                if (this.isWidget) {
                    return;
                }
                await this.openReferencePreferencesModal();
            }

            const requestData = {
                appraisalId: this.appraisal.id,
                setType: setDefinition.type,
                valuationTypeUuid: setDefinition.valuationType,
                buildYear: setDefinition.buildYear ?? 0,
                livingArea: setDefinition.surfaceArea ?? 0,
                plotArea: setDefinition.plotArea,
                filters: setDefinition.filters,
                forceUseSubscriptions: this.forceUseSubscriptions,
            };

            let referenceObjectSetData: ReferenceSaleSetData | null = null;
            if (this.activeSetDefinition?.setDefinition.type === SetType.SOLD) {
                referenceObjectSetData = await this.referenceObjectsInteractor.request(requestData);
            } else if (this.activeSetDefinition?.setDefinition.type === SetType.SALE) {
                if (
                    oldActiveSetDefinition.state !== ActiveSetDefinitionState.EMPTY &&
                    oldActiveSetDefinition.setDefinition.valuationType === setDefinition.valuationType
                ) {
                    referenceObjectSetData = oldActiveSetDefinition.referenceObjectSetData;
                }
            }

            runInAction(() => {
                this._activeSetDefinition = {
                    setDefinition: setDefinition,
                    state: ActiveSetDefinitionState.ACTIVE,
                    referenceObjectSetData: referenceObjectSetData,
                    missingPreconditions: missingPrecondition,
                };
            });

            const taskId = referenceObjectSetData?.taskId ?? null;
            if (taskId !== null) {
                this.loadEnhancements(setDefinition, taskId);
            } else if (referenceObjectSetData) {
                this.referenceObjectsAnswerEnhancementInteractor.storeLoadedSet(requestData, referenceObjectSetData);
            }

            if (referenceObjectSetData) {
                this.updateSubscriptions(referenceObjectSetData.subscriptions);
            }

            this.loadMetadata(setDefinition);
        }
    };

    public triggerSearchActiveSet = async () => {
        if (!this._activeSetDefinition || this._activeSetDefinition.state === ActiveSetDefinitionState.EMPTY) {
            return;
        }

        const setDefinition = this._activeSetDefinition.setDefinition;

        const missingPrecondition = this.getFailedPreconditions(setDefinition);

        if (missingPrecondition.length === 0) {
            runInAction(() => {
                this._activeSetDefinition = {
                    setDefinition: setDefinition,
                    state:
                        this.activeSetDefinition?.setDefinition.type === SetType.SALE
                            ? ActiveSetDefinitionState.DISABLED
                            : ActiveSetDefinitionState.ACTIVE,
                    referenceObjectSetData: null,
                    missingPreconditions: missingPrecondition,
                };
            });

            const requestData = {
                appraisalId: this.appraisal.id,
                setType: setDefinition.type,
                valuationTypeUuid: setDefinition.valuationType,
                buildYear: setDefinition.buildYear ?? 0,
                livingArea: setDefinition.surfaceArea ?? 0,
                plotArea: setDefinition.plotArea,
                filters: setDefinition.filters,
                forceUseSubscriptions: this.forceUseSubscriptions,
            };

            let referenceObjectSetData: ReferenceSaleSetData | null = null;
            if (this.activeSetDefinition?.setDefinition.type === SetType.SALE) {
                referenceObjectSetData = await this.referenceObjectsInteractor.request(requestData);
            }

            if (this._activeSetDefinition.setDefinition.valuationType === setDefinition.valuationType) {
                runInAction(() => {
                    this._activeSetDefinition = {
                        setDefinition: setDefinition,
                        state: ActiveSetDefinitionState.ACTIVE,
                        referenceObjectSetData: referenceObjectSetData,
                        missingPreconditions: missingPrecondition,
                    };
                });
            } else if (referenceObjectSetData) {
                this.referenceObjectsAnswerEnhancementInteractor.storeLoadedSet(requestData, referenceObjectSetData);
            }

            const taskId = referenceObjectSetData?.taskId ?? null;
            if (taskId !== null) {
                this.loadEnhancements(setDefinition, taskId);
            }

            if (referenceObjectSetData) {
                this.updateSubscriptions(referenceObjectSetData.subscriptions);
            }
        }
    };

    public async forceRequest(type: ReferenceSubscriptionType) {
        if (
            !this._activeSetDefinition ||
            this._activeSetDefinition.state === ActiveSetDefinitionState.EMPTY ||
            this.getFailedPreconditions(this._activeSetDefinition.setDefinition).length > 0
        ) {
            return;
        }

        const setDefinition = this._activeSetDefinition.setDefinition;

        runInAction(() => {
            this._activeSetDefinition = {
                setDefinition: setDefinition,
                state:
                    this.activeSetDefinition?.setDefinition.type === SetType.SOLD
                        ? ActiveSetDefinitionState.DISABLED
                        : ActiveSetDefinitionState.ACTIVE,
                referenceObjectSetData: null,
                missingPreconditions: [],
            };

            if (!this.forceUseSubscriptions.includes(type)) {
                this.forceUseSubscriptions.push(type);
            }
        });

        let referenceObjectSetData: ReferenceSaleSetData | null = null;
        const requestData = {
            appraisalId: this.appraisal.id,
            setType: setDefinition.type,
            valuationTypeUuid: setDefinition.valuationType,
            buildYear: setDefinition.buildYear ?? 0,
            livingArea: setDefinition.surfaceArea ?? 0,
            plotArea: setDefinition.plotArea,
            filters: setDefinition.filters,
            forceUseSubscriptions: this.forceUseSubscriptions,
        };

        // forceRequest is only available for sold references
        if (this.activeSetDefinition?.setDefinition.type === SetType.SOLD) {
            this.referenceObjectsInteractor.clearCached(requestData);

            referenceObjectSetData = await this.referenceObjectsInteractor.request(requestData);
        }

        if (this._activeSetDefinition.setDefinition.valuationType === setDefinition.valuationType) {
            runInAction(() => {
                this._activeSetDefinition = {
                    setDefinition: setDefinition,
                    state: ActiveSetDefinitionState.ACTIVE,
                    referenceObjectSetData: referenceObjectSetData,
                    missingPreconditions: [],
                };
            });
        }

        const taskId = referenceObjectSetData?.taskId ?? null;
        if (taskId !== null) {
            this.loadEnhancements(setDefinition, taskId);
        } else if (referenceObjectSetData) {
            this.referenceObjectsAnswerEnhancementInteractor.storeLoadedSet(requestData, referenceObjectSetData);
        }

        if (referenceObjectSetData) {
            this.updateSubscriptions(referenceObjectSetData.subscriptions);
        }
    }

    private loadEnhancements(setDefinition: SetDefinitionWithoutCount, taskId: number) {
        const requestData = {
            appraisalId: this.appraisal.id,
            setType: setDefinition.type,
            valuationTypeUuid: setDefinition.valuationType,
            buildYear: setDefinition.buildYear ?? 0,
            livingArea: setDefinition.surfaceArea ?? 0,
            plotArea: setDefinition.plotArea,
            filters: setDefinition.filters,
            forceUseSubscriptions: this.forceUseSubscriptions,
        };
        let lastValue: ReferenceSaleSetData | null = null;

        this.activeSetSubscriptions.add(
            this.referenceObjectsInteractor.requestEnhancementStream(taskId, requestData).subscribe({
                next: (val) => {
                    if (
                        this._activeSetDefinition.state !== ActiveSetDefinitionState.ACTIVE ||
                        this._activeSetDefinition.setDefinition.valuationType !== setDefinition.valuationType ||
                        val === null
                    ) {
                        return;
                    }

                    lastValue = val;

                    runInAction(() => {
                        this._activeSetDefinition = {
                            setDefinition: setDefinition,
                            state: ActiveSetDefinitionState.ACTIVE,
                            referenceObjectSetData: val,
                            missingPreconditions: [],
                        };
                    });

                    // Update data inside the details modal if it is open
                    if (this.modalState.length > 0) {
                        const topModal = this.modalState[this.modalState.length - 1];
                        if (
                            topModal.type === ModalType.REFERENCE_OBJECT_DETAILS &&
                            topModal.referenceObject !== null &&
                            !val.referenceSales.includes(topModal.referenceObject)
                        ) {
                            const matched = val.referenceSales.find(
                                (r) =>
                                    topModal.referenceObject &&
                                    r.id === topModal.referenceObject.id &&
                                    r.source === topModal.referenceObject.source
                            );

                            if (matched) {
                                this.showDetailsModal(matched);
                            }
                        }
                    }
                },
                complete: () => {
                    if (lastValue) {
                        this.referenceObjectsAnswerEnhancementInteractor.storeLoadedSet(requestData, lastValue);
                    }
                },
            })
        );
    }

    private loadMetadata(setDefinition: SetDefinitionWithoutCount) {
        if (!setDefinition.groupTree.item.answer) {
            return;
        }

        this.activeSetSubscriptions.add(
            this.referenceObjectsMetadataProvider
                .getMetadataByAnswerUuidStream(
                    setDefinition.groupTree.item.question.uuid,
                    setDefinition.groupTree.item.answer.uuid
                )
                .subscribe((metadata) => {
                    if (!metadata || metadata.version !== ReferenceObjectsMetadataVersion.V3) {
                        return;
                    }

                    runInAction(() => {
                        this.referenceObjectsMetadata = metadata;
                    });
                })
        );
    }

    @action
    public updateSortingMethod = (method: SortingMethod): void => {
        if (method === this.sortingMethod) {
            this.isComparing = false;
            this.sortingDirection =
                this.sortingDirection === SortingDirection.ASCENDING
                    ? SortingDirection.DESCENDING
                    : SortingDirection.ASCENDING;
        } else {
            this.isComparing = false;
            this.sortingMethod = method;
            this.sortingDirection = this.getDefaultSortingDirection(method);
        }
        this.sortingMethodChangeCount++;
    };

    @action
    public updateIsComparing = (isComparing: boolean) => {
        this.isComparing = isComparing;
    };

    private getDefaultSortingDirection(sortingMethod: SortingMethod): SortingDirection {
        switch (sortingMethod) {
            case SortingMethod.ALPHABETICALLY:
                return SortingDirection.ASCENDING;
            case SortingMethod.SALES_DATE:
                return SortingDirection.DESCENDING;
            case SortingMethod.DISTANCE:
                return SortingDirection.ASCENDING;
            case SortingMethod.DEVIATION_SCORE:
                return SortingDirection.DESCENDING;
        }
    }

    @action
    public showMoreReferenceObjects = () => {
        this.numVisible += 12;
    };

    @action
    public onAddCustomReferenceSaleButtonClick = () => {
        this.referenceModalStack.upsert({
            type: ModalType.CUSTOM_REFERENCE_SALE,
            id: 'custom-reference-sale',
            subscriptions: this.referenceSubscriptions,
        });
    };

    @action
    public showPreferredModal = (
        referenceObjectAnswer: ReferenceObjectAnswer,
        treeItem: TreeItem<QuestionAnswerPair>
    ) => {
        const groupItem = findChildRecursiveByPredicate(
            treeItem,
            (item) => item.question.technicalReference === TechnicalReference.REFERENCE_OBJECTS_V3_ITERATOR_GROUP
        );

        const iteratorAnswerUuid = groupItem?.parent?.item.answer?.uuid;
        const parentAnswerUuid = groupItem?.item.answer?.parentUuid;
        const question = groupItem?.item.question;

        if (iteratorAnswerUuid && parentAnswerUuid && question) {
            this.showPreferredModalByUuid(referenceObjectAnswer, question, iteratorAnswerUuid, parentAnswerUuid);
        }
    };

    @action
    public showAnswerModal = (referenceObjectAnswer: ReferenceObjectAnswer, treeItem: TreeItem<QuestionAnswerPair>) => {
        const groupItem = findChildRecursiveByPredicate(
            treeItem,
            (item) => item.question.technicalReference === TechnicalReference.REFERENCE_OBJECTS_V3_ITERATOR_GROUP
        );

        const iteratorAnswerUuid = groupItem?.parent?.item.answer?.uuid;
        const parentAnswerUuid = groupItem?.item.answer?.parentUuid;
        const question = groupItem?.item.question;

        if (iteratorAnswerUuid && parentAnswerUuid && question) {
            this.showAnswerModalByUuid(referenceObjectAnswer, question, iteratorAnswerUuid, parentAnswerUuid);
        }
    };

    private isComparisonModalPreferred() {
        return this.globalProvider.global.isReferencesComparisonModalEnabled && !isMobile();
    }

    private showPreferredModalByUuid = async (
        referenceObjectAnswer: ReferenceObjectAnswer,
        question: Question,
        iteratorAnswerUuid: string,
        parentAnswerUuid: string
    ) => {
        if (this.isComparisonModalPreferred() && this.activeSetDefinition?.setDefinition) {
            let set = this.activeSetDefinition.setDefinition;

            // The currently stored activeSetDefinition will be debounced, so might not be up to date. Hence we fetch it fresh
            const sets = await this.referenceSetsProvider.referenceSets().pipe(first()).toPromise();
            const updatedSet = sets?.find((s) => s.valuationType === set.valuationType);
            if (updatedSet) {
                set = this.fillSetDefinition(updatedSet).setDefinition;
            }

            this.showComparisonModal(set);
        } else {
            this.showAnswerModalByUuid(referenceObjectAnswer, question, iteratorAnswerUuid, parentAnswerUuid);
        }
    };

    @action
    private showAnswerModalByUuid = (
        referenceObjectAnswer: ReferenceObjectAnswer,
        question: Question,
        iteratorAnswerUuid: string,
        parentAnswerUuid: string
    ) => {
        const set = this.referenceSets?.find((set) => set.valuationType === referenceObjectAnswer.valuationType);
        if (!set) {
            return;
        }

        // Clear stack
        this.referenceModalStack.remove(() => true);
        this.referenceModalStack.upsert({
            type: ModalType.OBJECT_ANSWER,
            id: 'object-answer',
            referenceObjectAnswer,
            question: question,
            iteratorAnswerUuid: iteratorAnswerUuid,
            parentAnswerUuid: parentAnswerUuid,
            setDefinition: set,
        });
    };

    @action
    public showComparisonModal = (setDefinition: SetDefinition) => {
        const visibleObjects = this.getVisibleReferenceObjectsForSet(setDefinition);

        // Clear stack
        this.referenceModalStack.remove(() => true);
        this.referenceModalStack.upsert({
            type: ModalType.OBJECT_ANSWERS_COMPARISON,
            id: 'object-answer',
            referenceObjects: [...visibleObjects.selectedPreselectedReferences, ...visibleObjects.selectedObjects],
            activeSetDefinition: setDefinition,
            referenceSets: this.referenceSets ?? [],
        });
    };

    @action
    public showDetailsModal = (referenceObject: ReferenceObject | ReferenceObjectAnswer, enhance = true) => {
        const altumSubscription = this.referenceSubscriptions?.subscriptions.find(
            (s) => s.type === ReferenceSubscriptionType.ALTUM
        );

        if (
            altumSubscription?.available &&
            !altumSubscription.usedForAppraisal &&
            referenceObject.source === 'Altum' &&
            this.referenceObjectsMetadata?.preselectedObjects.some(
                (obj) => obj.id === referenceObject.id && obj.source === 'Altum'
            )
        ) {
            return;
        }

        let referenceObjectData = isReferenceObjectAnswer(referenceObject) ? null : referenceObject;
        const referenceObjectAnswer = isReferenceObjectAnswer(referenceObject) ? referenceObject : null;

        let isEnhancing = false;
        if (referenceObjectAnswer === null && referenceObjectData !== null && enhance) {
            isEnhancing = this.enhanceHighlightedReferenceSale(referenceObjectData);
        }

        if (referenceObjectAnswer !== null && referenceObjectData === null) {
            referenceObjectData =
                this.activeSetDefinition?.referenceObjectSetData?.referenceSales.find(
                    (r) => r.id === referenceObjectAnswer.id && r.source === referenceObjectAnswer.source
                ) ?? null;
        }

        this.referenceModalStack.upsert({
            type: ModalType.REFERENCE_OBJECT_DETAILS,
            id: 'reference-object-details',
            referenceObject: referenceObjectData,
            referenceObjectAnswer,
            canAdd: this.canAdd,
            isEnhancing,
            setDefinition: this.activeSetDefinition?.setDefinition ?? null,
        });
    };

    public showImagesModal = (images: ReferenceSaleImage[], initialIndex?: number, photoAnswerUuid?: string) => {
        this.referenceModalStack.upsert({
            type: ModalType.IMAGE_VIEWER,
            id: 'image-viewer',
            images,
            initialIndex,
            photoAnswerUuid,
        });
    };

    @action
    public onModalHide = () => {
        if (this.modalState.length > 0) {
            this.referenceModalStack.remove(this.modalState[this.modalState.length - 1].id);
        }
    };

    public enhanceHighlightedReferenceSale = (referenceObject: ReferenceObject) => {
        if (referenceObject.images.length > 5 || !referenceObject.complete) {
            return false; // Do not fetch
        }

        const setDefinition = this.activeSetDefinition?.setDefinition;
        if (setDefinition === undefined) {
            return false;
        }

        const requestData = {
            appraisalId: this.appraisal.id,
            setType: setDefinition.type,
            valuationTypeUuid: setDefinition.valuationType,
            buildYear: setDefinition.buildYear ?? 0,
            livingArea: setDefinition.surfaceArea ?? 0,
            plotArea: setDefinition.plotArea,
            filters: setDefinition.filters,
            forceUseSubscriptions: this.forceUseSubscriptions,
        };

        this.referenceObjectsInteractor
            .requestHighlightedReferenceSaleEnhancement(referenceObject.id, requestData)
            .then((enhancedSale) => {
                if (enhancedSale === null) {
                    return;
                }

                const oldReferenceObjectData = this.activeSetDefinition?.referenceObjectSetData;
                if (!oldReferenceObjectData) {
                    return;
                }

                const newReferenceObjectData = {
                    ...oldReferenceObjectData,
                    referenceSales: oldReferenceObjectData.referenceSales.map((r) => {
                        if (r.id === referenceObject.id && r.source === referenceObject.source) {
                            return enhancedSale;
                        }
                        return r;
                    }),
                };

                this.referenceObjectsAnswerEnhancementInteractor.storeLoadedSet(requestData, newReferenceObjectData);

                runInAction(() => {
                    this._activeSetDefinition = {
                        setDefinition: setDefinition,
                        state: ActiveSetDefinitionState.ACTIVE,
                        referenceObjectSetData: newReferenceObjectData,
                        missingPreconditions: [],
                    };
                });

                // Update data inside the details modal if it is open
                if (this.modalState.length > 0) {
                    const topModal = this.modalState[this.modalState.length - 1];
                    if (
                        topModal.type === ModalType.REFERENCE_OBJECT_DETAILS &&
                        topModal.referenceObject !== null &&
                        topModal.referenceObject.id === referenceObject.id &&
                        topModal.referenceObject.source === referenceObject.source
                    ) {
                        const matched = newReferenceObjectData.referenceSales.find(
                            (r) =>
                                topModal.referenceObject &&
                                r.id === topModal.referenceObject.id &&
                                r.source === topModal.referenceObject.source
                        );

                        if (matched) {
                            this.showDetailsModal(matched, false);
                        }
                    }
                }
            });

        return true;
    };

    @action
    private updateSubscriptions(subscriptions: ReferenceSubscriptions | null, allowInitialTrigger = false) {
        if (
            this.referenceSubscriptions?.promotions.some(
                (p) => p.promotion === ReferenceSubscriptionPromotionType.BRAINBAY_TRIAL
            ) &&
            !subscriptions?.promotions.some(
                (p) =>
                    p.promotion === ReferenceSubscriptionPromotionType.BRAINBAY_PROMOTION ||
                    p.promotion === ReferenceSubscriptionPromotionType.BRAINBAY_TRIAL
            )
        ) {
            this.promotionModalType = PromotionModalType.BRAINBAY_TRIAL_ENDED;
        }

        this.referenceSubscriptions = subscriptions;

        if (allowInitialTrigger && subscriptions) {
            const freeAvailableSubscriptions = subscriptions.subscriptions.filter(
                (s) => s.available && !s.enabled && !s.usedForAppraisal && s.priceCents === 0
            );

            this.forceUseSubscriptions.push(...freeAvailableSubscriptions.map((s) => s.type));
        }
    }

    public openReferencePreferencesModal = async () => {
        this.modalConfigStackInteractor.remove('references_preferences_modal');
        await this.modalConfigStackInteractor.insert({
            id: 'references_preferences_modal',
            type: GlobalModalType.REFERENCES_PREFERENCES,
        });

        runInAction(() => {
            this.isComparing = false;
            this.sortingMethod = this.globalProvider.global.referencePreferences?.sortMethod ?? this.sortingMethod;
            this.sortingDirection =
                this.globalProvider.global.referencePreferences?.sortDirection ?? this.sortingDirection;
        });
    };

    @action
    public setPromotionModalType(promotionModalType: PromotionModalType | null) {
        this.promotionModalType = promotionModalType;
    }
}
