import React from 'react';
import {createRoot} from 'react-dom/client';

import {observer} from 'mobx-react';
import {usePresenter} from '../support/presenter/use_presenter';
import {AppraiserDistrictsPresenter} from './appraiser_districts_presenter';
import {RangeSearchModal} from './range_modal';
import {SearchModal} from './search_modal';
import {FlashMessage} from '../appraising/appraise/ui/container/flash_message';
import {Collapsible} from '../appraising/appraise/ui/components/collapsible';
import {ReasoningInput} from './reasoning_input';
import {Modal} from '../appraising/appraise/ui/content/questions/components/modal';
import {createPortal} from 'react-dom';
import {AppraiserDistrictsMap} from './districts_map';

const AppraiserDistricts: React.FC<{secondary?: HTMLElement}> = observer(({secondary}) => {
    const presenter = usePresenter(
        (component) =>
            new AppraiserDistrictsPresenter(
                component.business.flashMessageBroadcaster,
                component.network.neighborhoodApi
            )
    );
    const [isRangeModalVisible, setIsRangeModalVisible] = React.useState(false);
    const [searchModalVisible, setIsSearchModalVisible] = React.useState(false);

    return (
        <>
            <div className="card-body">
                <div className="messages-stack">
                    <FlashMessage />
                </div>
                <p>Bepaal je werkgebied door de wijken te selecteren waar je actief bent.</p>
                <ul className="list-circles mt-2">
                    <li className="list-item ion-md-arrow-forward" onClick={() => setIsRangeModalVisible(true)}>
                        <span className="list-avatar ion-md-locate" />
                        <span className="list-title">
                            <span className="thin">Kies op basis van</span>
                            <strong>&nbsp;afstand</strong>
                            <span className="thin">&nbsp;vanaf een bepaald adres</span>
                        </span>
                    </li>
                    <li className="list-item ion-md-arrow-forward" onClick={() => setIsSearchModalVisible(true)}>
                        <span className="list-avatar ion-md-search" />
                        <span className="list-title">
                            <span className="thin">Zoek gemeenten of wijken</span>
                            <strong>&nbsp;op naam</strong>
                        </span>
                    </li>
                </ul>
                <div className="row">
                    {presenter.districtsByMunicipality.length > 0 ? (
                        <>
                            <div className="col-12 col-md-6">
                                <button
                                    className="btn btn-secondary btn-sm ion-md-create mr-2"
                                    onClick={() =>
                                        presenter.editReasoningForCodes(
                                            Array.from(presenter.selectedDistrictCodes),
                                            'Pas de onderbouwing aan voor de geselecteerde wijken'
                                        )
                                    }
                                    disabled={presenter.selectedDistrictCodes.size === 0}
                                >
                                    Bewerken
                                </button>
                                <button
                                    className="btn btn-secondary btn-sm ion-md-trash"
                                    onClick={() => {
                                        if (confirm('Weet je zeker dat je de geselecteerde wijken wilt verwijderen?')) {
                                            presenter.removeCodes(Array.from(presenter.selectedDistrictCodes));
                                        }
                                    }}
                                    disabled={presenter.selectedDistrictCodes.size === 0}
                                >
                                    Verwijderen
                                </button>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group float-right w-auto" style={{marginRight: 22}}>
                                    <input
                                        type="checkbox"
                                        id="select-all"
                                        name="select-all"
                                        checked={presenter.allSelected}
                                        onChange={() => presenter.toggleSelectAll()}
                                    />
                                    <label htmlFor="select-all" className="label-align-right d-flex">
                                        Alles selecteren
                                    </label>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="empty-message mt-5">
                            <h3>Er zijn nog geen wijken toegevoegd</h3>
                            <span className="spacer-gradient"></span>
                        </div>
                    )}
                    <div className="col-12">
                        <ul className="list-districts pl-0" style={{listStyle: 'none'}}>
                            {presenter.districtsByMunicipality.map(
                                ({code: municipalityCode, name: municipalityName, districts}) => (
                                    <Collapsible
                                        alwaysRenderChildren={false}
                                        title={
                                            <li>
                                                <strong>{municipalityName}</strong>
                                                <span className="thin pl-1">
                                                    {' '}
                                                    ({districts.length} wijk{districts.length > 1 ? 'en' : ''}){' '}
                                                </span>
                                                <span className="list-options">
                                                    <a
                                                        className="ion-md-create"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            presenter.editReasoningForCodes(
                                                                districts.map((d) => d.district.wijkcode),
                                                                `Pas de onderbouwing aan voor alle toegevoegde wijken binnen de gemeente ${municipalityName}`
                                                            );
                                                        }}
                                                    ></a>
                                                    <a
                                                        className="ion-md-trash mr-2"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (
                                                                confirm(
                                                                    'Weet je zeker dat je deze gemeente inclusief onderliggende wijken wilt verwijderen?'
                                                                )
                                                            ) {
                                                                presenter.removeCodes([municipalityCode]);
                                                            }
                                                        }}
                                                    ></a>
                                                    <input
                                                        type="checkbox"
                                                        name={`municipality-item-${municipalityCode}`}
                                                        id={`municipality-item-${municipalityCode}`}
                                                        checked={districts.every(({district}) =>
                                                            presenter.selectedDistrictCodes.has(district.wijkcode)
                                                        )}
                                                        onChange={(e) => {
                                                            e.stopPropagation();
                                                            const unselectedDistricts = districts.filter(
                                                                ({district}) =>
                                                                    !presenter.selectedDistrictCodes.has(
                                                                        district.wijkcode
                                                                    )
                                                            );
                                                            if (unselectedDistricts.length > 0) {
                                                                unselectedDistricts.forEach(({district}) =>
                                                                    presenter.toggleSelected(district.wijkcode)
                                                                );
                                                            } else {
                                                                districts.forEach(({district}) =>
                                                                    presenter.toggleSelected(district.wijkcode)
                                                                );
                                                            }
                                                        }}
                                                    />
                                                    <label
                                                        htmlFor={`municipality-item-${municipalityCode}`}
                                                        style={{height: 32}}
                                                    ></label>
                                                </span>
                                            </li>
                                        }
                                        key={municipalityCode}
                                    >
                                        <ul className="list-circles list-appraiser-districts mb-0">
                                            {districts.map(({district, reasoning}) => (
                                                <li className="list-item" key={district.wijkcode}>
                                                    <span className="list-avatar ion-md-locate" />
                                                    <span className="list-title">
                                                        <strong>{district.wijknaam}</strong>
                                                    </span>
                                                    <small className="list-details">
                                                        <span>
                                                            <strong>Onderbouwing:</strong> {reasoning.reasoning}
                                                        </span>
                                                    </small>
                                                    <span className="list-options mr-1">
                                                        <a
                                                            className="ion-md-create"
                                                            onClick={() =>
                                                                presenter.editReasoningForCodes(
                                                                    [district.wijkcode],
                                                                    `Pas de onderbouwing aan voor de wijk ${district.wijknaam} in de gemeente ${municipalityName}`
                                                                )
                                                            }
                                                        ></a>
                                                        <a
                                                            className="ion-md-trash mr-2"
                                                            onClick={() => presenter.removeCodes([district.wijkcode])}
                                                        ></a>
                                                        <input
                                                            type="checkbox"
                                                            name={`district-item-${district.wijkcode}`}
                                                            id={`district-item-${district.wijkcode}`}
                                                            checked={presenter.selectedDistrictCodes.has(
                                                                district.wijkcode
                                                            )}
                                                            onChange={() => presenter.toggleSelected(district.wijkcode)}
                                                        />
                                                        <label
                                                            htmlFor={`district-item-${district.wijkcode}`}
                                                            style={{height: 32}}
                                                        ></label>
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    </Collapsible>
                                )
                            )}
                        </ul>
                    </div>
                </div>
                {searchModalVisible && (
                    <SearchModal
                        onClose={(codes, reasoning) =>
                            presenter.addCodes(codes, reasoning).then(() => {
                                setIsSearchModalVisible(false);
                            })
                        }
                        addedCodes={presenter.fullyIncludedCodes}
                    />
                )}
                {isRangeModalVisible && (
                    <RangeSearchModal
                        onClose={(codes, reasoning) =>
                            presenter.addCodes(codes, reasoning).then(() => {
                                setIsRangeModalVisible(false);
                            })
                        }
                        addedCodes={presenter.fullyIncludedCodes}
                    />
                )}
                {presenter.editReasoningModalDetails && (
                    <Modal visible>
                        <div className="modal-header">
                            <h2>
                                {presenter.editReasoningModalDetails.isAdding
                                    ? 'Voeg onderbouwing toe'
                                    : 'Pas de onderbouwing aan'}
                            </h2>
                        </div>
                        <div className="modal-body">
                            <p>{presenter.editReasoningModalDetails.description}</p>
                            <ReasoningInput
                                defaultValue={presenter.editReasoningModalDetails.reasoningValue}
                                onChange={(value) => presenter.onChangeEditReasoning(value)}
                                showRequiredError={false}
                            />
                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn btn-secondary ion-md-close"
                                type="button"
                                onClick={() => presenter.closeEditReasoningModal(false)}
                            >
                                Sluiten
                            </button>
                            <button className="btn btn-primary" onClick={() => presenter.closeEditReasoningModal(true)}>
                                Aanpassen
                            </button>
                        </div>
                    </Modal>
                )}
            </div>
            <footer className="d-flex justify-content-end">
                <a className="btn btn-secondary" href="/">
                    Terug
                </a>
                <button className="btn btn-primary" onClick={() => presenter.submit()}>
                    Opslaan
                </button>
            </footer>
            {secondary &&
                createPortal(
                    <AppraiserDistrictsMap
                        addedCodes={presenter.fullyIncludedCodes}
                        addedDistricts={presenter.addedDistricts}
                        editReasoningForCodes={(codes, description) =>
                            presenter.editReasoningForCodes(codes, description)
                        }
                        removeCodes={(codes) => presenter.removeCodes(codes)}
                    />,
                    secondary
                )}
        </>
    );
});

export function renderAppraiserDistrictsInto(element: HTMLElement, secondaryElement?: HTMLElement) {
    createRoot(element).render(<AppraiserDistricts secondary={secondaryElement} />);
}
