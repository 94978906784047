import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {Link} from 'react-router-dom';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {ParagraphReferencesListPresenter} from './paragraph_references_list_presenter';
import {QuestionSet} from '../../../../models/question_set';
import {SidebarItemForQuestionProvider} from '../../../../business/sidebar_item_for_question_provider';
import {SidebarItemsProvider} from '../../../../business/sidebar_items_provider';
import {classNames} from '../../../../../support/classnames';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {RenderingContextType} from '../../../../enum/rendering_context_type';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet | null;
    renderingContext: RenderingContextType;
}

export const ParagraphReferencesList: React.FC<OwnProps> = observer(function ParagraphReferencesList(props) {
    const presenter = usePresenter(
        (container) =>
            new ParagraphReferencesListPresenter(
                props.questionSet,
                new SidebarItemForQuestionProvider(
                    props.questionSet,
                    new SidebarItemsProvider(
                        container.business.sidebarTreeBuilder(
                            props.appraisal,
                            props.questionSet,
                            props.pagePartsSet,
                            props.renderingContext
                        )
                    )
                )
            )
    );

    return (
        <>
            {presenter.reportParagraphReferencesGrouped.length !== 0 ? (
                <p>
                    Via onderstaande legenda kun je gemakkelijk navigeren van de hoofdstukken uit het rapport naar de
                    vragen. Enkel de hoofdstukken die van toepassing zijn, staan in onderstaand overzicht.{' '}
                </p>
            ) : null}

            <ul className="list-simple">
                {presenter.reportParagraphReferencesGrouped.map((paragraphReferencesGroup, i) => {
                    return (
                        <li key={i}>
                            <>
                                <span
                                    className={classNames(
                                        'list-simple-content',
                                        presenter.isActiveGroup(paragraphReferencesGroup.section)
                                            ? 'ion-md-arrow-dropup-circle'
                                            : 'ion-md-arrow-dropdown-circle'
                                    )}
                                    onClick={() => presenter.setActiveGroup(paragraphReferencesGroup.section)}
                                >
                                    <span>
                                        {paragraphReferencesGroup.section} {paragraphReferencesGroup.title}
                                    </span>
                                </span>
                                <ul
                                    className={classNames('list-simple', 'collapsable', {
                                        active: presenter.isActiveGroup(paragraphReferencesGroup.section),
                                    })}
                                >
                                    {paragraphReferencesGroup.paragraphReferences.map((paragraphReference, subKey) => {
                                        return (
                                            <li key={subKey}>
                                                <Link
                                                    to={{
                                                        pathname: paragraphReference.sidebarItem.link,
                                                        state: {
                                                            focusValidation: true,
                                                            questionUuids: [paragraphReference.question.uuid],
                                                        },
                                                    }}
                                                    className="list-simple-content"
                                                >
                                                    {paragraphReference.section} {paragraphReference.title}
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </>
                        </li>
                    );
                })}
            </ul>
        </>
    );
});
