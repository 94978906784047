import * as React from 'react';
import {usePresenter} from '../support/use_presenter';
import {ObjectOwnershipTypePresenter} from './object_ownership_type_presenter';
import {MacroExternalType} from '../../appraising/models/macro';
import {observer} from 'mobx-react';
import {MacrosComponent} from '../../appraising/appraise/ui/content/questions/advanced/macros/macros_component';
import {macroIsActive, toggleMacro} from '../../appraising/business/toggle_macro';

interface OwnProps {
    invalid: boolean;
    disabled: boolean;
    initialValue: string | null;
}

export const ObjectOwnershipType = observer(function ObjectOnwershipType(props: OwnProps) {
    const presenter = usePresenter(
        (container) => new ObjectOwnershipTypePresenter(container.macroInteractor, MacroExternalType.OBJECT_OWNERSHIP)
    );

    const [textValue, setTextValue] = React.useState(props.initialValue ?? '');

    return (
        <div className="form-group has-macros">
            <MacrosComponent
                allowSearch={false}
                allowAddingMacro={true}
                allowRemoveSystemMacros={true}
                containerRef={null}
                showSearch={false}
                searchValue={''}
                setSearch={() => {}}
                setShowSearch={() => {}}
                showAddButton={true}
                onAddMacro={async () => {
                    await presenter.storeMacro(textValue);
                }}
                superHiddenMacros={[]}
                setShowHiddenMacros={() => {}}
                showHiddenMacros={false}
                useAI={false}
                isTextAIEnabled={false}
                promptAI={() => {}}
                textAIResponse={null}
                textAILoading={false}
                toggleMacroSettingsWidget={() => {}}
                macros={presenter.macros}
                isActive={(content) => macroIsActive(content, textValue)}
                onUseMacro={(contents) => setTextValue(toggleMacro(contents, textValue))}
                onFavorite={async (macro) => {
                    await presenter.onFavoriteMacroClick(macro);
                }}
                hasAdvancedOptions={false}
                onRemove={async (macro) => {
                    await presenter.onRemoveMacro(macro);
                }}
            />
            <textarea
                id="object-ownership-type-notes"
                className={'form-control ' + (props.invalid ? 'is-invalid' : '')}
                name="object_ownership_type_notes"
                disabled={props.disabled}
                value={textValue}
                onChange={(e) => setTextValue(e.target.value)}
            />
        </div>
    );
});
