import {Appraisal} from '../../../../../models/appraisal';
import * as React from 'react';
import {observer} from 'mobx-react';
import {ControllerData, PlausibilityRequestFormPresenter} from './plausibility_request_form_presenter';
import {SalutationType} from '../../../../../enum/salutation_type';
import {classNames} from '../../../../../../support/classnames';
import {Loader} from '../../../components/loader';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {Component} from '../../../../component';

interface OwnProps {
    appraisal: Appraisal;
}

export const PlausibilityRequestForm: React.FC<OwnProps> = observer(function PlausibilityRequestForm(props) {
    const presenter = usePresenter(
        (container: Component) =>
            new PlausibilityRequestFormPresenter(
                props.appraisal,
                container.network.controllerAppraiserApi,
                container.business.appraisalProvider
            )
    );

    function renderInput(field: keyof ControllerData) {
        return (
            <div className="input-group">
                <input
                    className="form-control pl-3"
                    type="text"
                    id={field}
                    name={field}
                    value={presenter.controllerData[field] ?? ''}
                    disabled={
                        presenter.saving ||
                        presenter.disabled ||
                        (field !== 'email' && presenter.isDetailFieldsDisabled)
                    }
                    onChange={(e) => presenter.onChange(field, e.target.value)}
                    onBlur={field === 'email' ? (e) => presenter.onRequestData(e.target.value) : () => null}
                />
                {field === 'email' && (
                    <div className="input-group-append">
                        <button
                            type="button"
                            className="btn btn-secondary btn-append"
                            disabled={!presenter.isDetailFieldsDisabled}
                            onClick={() => presenter.onRequestData(presenter.controllerData?.email ?? null)}
                        >
                            <span className="ion-md-checkmark"></span>
                        </button>
                    </div>
                )}
            </div>
        );
    }

    function renderSalutation(field: keyof ControllerData) {
        return (
            <div className="input-group">
                <select
                    className="form-control custom-select"
                    value={presenter.controllerData[field] ?? ''}
                    id={field}
                    name={field}
                    disabled={presenter.saving || presenter.disabled || presenter.isDetailFieldsDisabled}
                    onChange={(e) => presenter.onChange(field, e.target.value)}
                >
                    {Object.values(SalutationType).map((val) => {
                        return (
                            <option value={val} key={val}>
                                {val}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }

    const fields = {
        email: 'E-mail',
        salutation: 'Aanhef',
        initials: 'Initialen',
        lastName: 'Achternaam',
        rtNumber: 'RT-nummer',
        companyName: 'Taxatiebedrijf',
        city: 'Vestigingslocatie',
    };

    return (
        <>
            <div className="row">
                {Object.entries(fields).map((field) => {
                    return (
                        <div
                            className={classNames(
                                'col-12',
                                {'col-md-6': field[0] !== 'email'},
                                {'d-none': field[0] !== 'email' && presenter.isDetailFieldsDisabled}
                            )}
                            key={field[0]}
                        >
                            <div className="form-group">
                                <label htmlFor={'controller-' + field[0]}>
                                    <span>{field[1]}</span>
                                    {presenter.requiredFieldNames.includes(field[0]) && (
                                        <>
                                            &nbsp;<span className="form-label-highlight">*</span>
                                        </>
                                    )}
                                </label>
                                {field[0] === 'salutation'
                                    ? renderSalutation(field[0] as keyof ControllerData)
                                    : renderInput(field[0] as keyof ControllerData)}
                                {presenter.emptyFields.includes(field[0]) && (
                                    <span className="invalid-feedback">Dit is een verplicht veld</span>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            {presenter.disabled && (
                <div className="row">
                    <div className="col-12">
                        <div className="alert-inline alert-info ion-md-alert">
                            <p>
                                {presenter.isInvited
                                    ? 'Er is een uitnodiging aan de controlerend taxateur verstuurd.'
                                    : 'Er is momenteel een controlerend taxateur gekoppeld.'}
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {presenter.errorMessage && (
                <div className="row">
                    <div className="col-12">
                        <div className="alert-inline alert-danger ion-md-alert">
                            <p>{presenter.errorMessage}</p>
                        </div>
                    </div>
                </div>
            )}
            {presenter.isDetailFieldsDisabled === false && (
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        {presenter.isRemoveVisible && (
                            <button
                                className={classNames('btn btn-sm btn-remove ion-ios-trash mr-3', {
                                    disabled: presenter.saving,
                                })}
                                disabled={presenter.saving}
                                onClick={() => presenter.onRemove()}
                            >
                                {presenter.saving ? <Loader /> : null}
                                Verwijderen
                            </button>
                        )}
                        <button
                            className={classNames('btn btn-sm', {
                                disabled: presenter.saving || presenter.disabled || presenter.isDetailFieldsDisabled,
                            })}
                            disabled={presenter.saving || presenter.disabled}
                            onClick={() => presenter.onSubmit()}
                        >
                            {presenter.saving ? <Loader /> : null}
                            Controlerend taxateur uitnodigen
                        </button>
                    </div>
                </div>
            )}

            <div className="spacer">&nbsp;</div>
        </>
    );
});
