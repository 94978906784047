import * as React from 'react';

import {isEmpty, isNumeric} from '../../../../../../../support/util';
import {
    simpleQuestionPresenterConstructorParametersFactory,
    SimpleQuestionPresenterProps,
} from '../../simple/simple_question_presenter';

import {observer} from 'mobx-react';
import NumberFormat from 'react-number-format';
import {classNames} from '../../../../../../../support/classnames';
import {filterValidationMessages} from '../../../../support/filter_validation_messages';
import {AlternativeAdaptedDefaultAnswer} from '../../components/alternative_adapted_default_answer';
import {Label} from '../../components/label';
import {ValidationMessages} from '../../components/validation_messages';
import {CurrencyIndexableQuestionPresenter} from './currency_indexable_question_presenter';
import {Referencesv3IndexSource} from './sources/references_v3_index_source';
import {formatMoney} from '../../../../support/format_money';
import {CompactEditable} from '../../../../components/compact_editable';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {QuestionExtensionType} from '../../../../../../enum/question_extension_type';
import {MaxCharactersQuestionExtension} from '../../../../../../models/question_extension';

interface OwnProps extends SimpleQuestionPresenterProps {
    step: string;
    negative: boolean;
}

export const CurrencyIndexableQuestion: React.FC<OwnProps> = observer(function CurrencyIndexableQuestion(props) {
    const presenter = usePresenter(
        (container) =>
            new CurrencyIndexableQuestionPresenter(
                [
                    new Referencesv3IndexSource(
                        props.appraisal,
                        props.questionSet,
                        props.question,
                        container.business.answerController(props.appraisal, props.questionSet),
                        container.network.referenceObjectApi
                    ),
                ],
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );
    if (presenter.isHidden) {
        return null;
    }

    const maxCharactersExtension = props.question.extensions.find(
        (ext) => ext.type === QuestionExtensionType.MAX_CHARACTERS
    ) as MaxCharactersQuestionExtension | null;

    function renderRounded() {
        return (
            <NumberFormat
                value={presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : ''}
                displayType="input"
                type="text"
                className="form-control"
                disabled={props.disabled ?? presenter.isDisabled}
                decimalScale={0}
                allowNegative={props.negative}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value;
                    if (isEmpty(value)) {
                        presenter.onChange(value, false);
                    } else if (isNumeric(value) && isNumeric(parseInt(value, 10))) {
                        presenter.onChange('' + parseInt(value, 10), false);
                    } else if (value === '-') {
                        presenter.onChange(value, false);
                    }
                }}
                onBlur={() => presenter.onTouched()}
                maxLength={maxCharactersExtension?.maxCharacters ?? undefined}
            />
        );
    }

    function renderFree() {
        return (
            <NumberFormat
                id={presenter.name}
                name={presenter.name}
                value={presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : ''}
                decimalSeparator=","
                displayType="input"
                type="text"
                className="form-control"
                decimalScale={2}
                disabled={props.disabled || presenter.isDisabled}
                allowNegative={props.negative}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value;
                    if (!isEmpty(value)) {
                        if (isNumeric(value)) {
                            presenter.onChange(value, false);
                        } else if (value === '-') {
                            presenter.onChange(value, false);
                        }
                    } else {
                        presenter.onChange('', false);
                    }
                }}
                onBlur={() => presenter.onTouched()}
                maxLength={maxCharactersExtension?.maxCharacters ?? undefined}
            />
        );
    }

    function renderCompact() {
        return (
            <>
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    disableHistory
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />

                <div>
                    {presenter.answer?.contents ? formatMoney(Number(presenter.answer.contents)) : <i>Niet ingevuld</i>}
                </div>
            </>
        );
    }

    return (
        <div
            className={classNames('form-group', presenter.filledByAutomator, {
                'automation-filled': presenter.filledByAutomator !== null,
            })}
            data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
        >
            <CompactEditable
                renderingContext={props.renderingContext}
                compact={() => renderCompact()}
                question={props.question}
                answer={presenter.answer}
            >
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <AlternativeAdaptedDefaultAnswer
                    adaptedDefaultValue={props.adaptedDefaultValue}
                    currentAnswer={presenter.answer}
                    disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                    onChange={(value) => presenter.onChange(value)}
                />
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text">&euro;</div>
                    </div>
                    {props.step === '1' ? renderRounded() : renderFree()}
                    {presenter.indexableSource != null && (
                        <div className="input-group-append">
                            <button
                                className="btn btn-secondary btn-append"
                                title="Indexeren"
                                onClick={() => presenter.index()}
                                disabled={presenter.indexing}
                            >
                                <span className="ion-md-calculator" style={{fontSize: 20}}></span>
                            </button>
                        </div>
                    )}
                </div>
            </CompactEditable>
            <ValidationMessages
                disabled={props.disabled || presenter.isDisabled}
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={presenter.answer}
                validationMessages={filterValidationMessages(
                    props.question,
                    presenter.answer,
                    props.validationMessages,
                    presenter.validationMessages
                )}
            />
        </div>
    );
});
