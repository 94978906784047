import {ApiAnswer} from './api_answer';
import {ApiCadastralPlotAddress, apiCadastralPlotAddressToCadastralPlotAddress} from './api_cadastral_plot_address';

import {Appraisal, Appraiser, ControllerAppraiser} from '../../models/appraisal';
import {AppraisalGoal} from '../../enum/appraisal_goal';
import {AppraisalState} from '../../enum/appraisal_state';
import {AppraiseModel} from '../../enum/appraise_model';
import {ObjectDisplayType} from '../../enum/object_display_type';
import {ObjectType} from '../../enum/object_type';
import {AppraisalValidationType} from '../../enum/appraisal_validation_type';
import {ApiCadastralDocument, apiCadastralDocumentToCadastralDocument} from './api_cadastral_document';
import {ObjectUsageType} from '../../enum/object_usage_type';
import {ObjectOwnershipType} from '../../enum/object_ownership_type';
import {ApiFile, apiFileToFile} from './api_file';
import {SalutationType} from '../../enum/salutation_type';
import {AppraisalInvoiceFlow} from '../../enum/appraisal_invoice_flow';
import {AppraisalCostType} from '../../../create_appraisal/preferences/appraisal_costs';

export interface ApiAppraisal {
    id: number;
    question_set_id: number;
    parent_appraisal_id: number | null;
    appraise_model: AppraiseModel;
    is_frozen: boolean;
    created_at: string;
    valuation_date: string;
    validation_type: AppraisalValidationType;
    status: AppraisalState;
    appraisal_goal: AppraisalGoal;
    title: string;
    address: string;
    postal_code: string;
    house_number: string;
    letter: string | null;
    city: string;
    country: string;
    latitude: number | null;
    longitude: number | null;
    object_type: string;
    object_display_type: string;
    new_construction: boolean;
    improvements_planned: boolean;
    is_woco: boolean;
    is_accepted_by_client: boolean;
    recently_sold: boolean;
    recently_sold_agreements: boolean;
    notes: string;
    object_usage_type: {
        value: ObjectUsageType;
        text: string;
    };
    object_ownership_type: {
        value: ObjectOwnershipType;
        text: string;
        other: string | null;
    };
    other_value_concepts: boolean;
    other_value_concepts_notes: string | null;
    answers: ApiAnswer[];
    cadastral_plot_addresses: ApiCadastralPlotAddress[];
    cadastral_documents: ApiCadastralDocument[];
    terms_of_engagement: {
        file_name: string | null;
        url: string | null;
    } | null;
    precheck_report: ApiFile;
    attachment_team_info:
        | {
              [key: string]: unknown;
          }
        | unknown[]
        | null;
    is_editable_appraisal: boolean;
    report_number: string | null;
    controller_appraiser: {
        salutation: SalutationType | null;
        initials: string | null;
        first_name: string | null;
        last_name: string | null;
        company_name: string | null;
        rt_number: string | null;
        city: string | null;
        email: string | null;
        is_invited: boolean;
        is_prior_appraiser: boolean | null;
    };
    appraiser: {
        salutation: SalutationType | null;
        initials: string | null;
        first_name: string | null;
        last_name: string | null;
        company_name: string | null;
        rt_number: string | null;
        city: string | null;
        email: string | null;
        is_invited: boolean;
        is_prior_appraiser: boolean | null;
    };
    has_controller: boolean;
    invoice_flow: AppraisalInvoiceFlow;
    activated_optional_costs: AppraisalCostType[];
}

export interface ApiAppraiser {
    salutation: SalutationType | null;
    initials: string | null;
    first_name: string | null;
    last_name: string | null;
    rt_number: string | null;
    company_name: string | null;
    city: string | null;
    email: string | null;
    is_prior_appraiser: boolean | null;
}

export interface ApiControllerAppraiser extends ApiAppraiser {
    is_invited: boolean;
}

export function apiAppraisalToAppraisal(apiAppraisal: ApiAppraisal): Appraisal {
    return {
        id: apiAppraisal.id,
        questionSetId: apiAppraisal.question_set_id,
        parentAppraisalId: apiAppraisal.parent_appraisal_id,
        appraiseModel: apiAppraisal.appraise_model,
        isFrozen: apiAppraisal.is_frozen,
        createdAt: new Date(apiAppraisal.created_at),
        valuationDate: apiAppraisal.valuation_date,
        validationType: apiAppraisal.validation_type,
        appraisalGoal: apiAppraisal.appraisal_goal,
        title: apiAppraisal.title,
        address: apiAppraisal.address,
        postalCode: apiAppraisal.postal_code,
        houseNumber: apiAppraisal.house_number,
        letter: apiAppraisal.letter,
        city: apiAppraisal.city,
        country: apiAppraisal.country,
        latitude: apiAppraisal.latitude,
        longitude: apiAppraisal.longitude,
        objectType: apiAppraisal.object_type as ObjectType,
        objectDisplayType: apiAppraisal.object_display_type as ObjectDisplayType,
        newConstruction: apiAppraisal.new_construction,
        improvementsPlanned: apiAppraisal.improvements_planned,
        isWoco: apiAppraisal.is_woco,
        isAcceptedByClient: apiAppraisal.is_accepted_by_client,
        recentlySold: apiAppraisal.recently_sold,
        recentlySoldAgreements: apiAppraisal.recently_sold_agreements,
        notes: apiAppraisal.notes,
        status: apiAppraisal.status,
        dummy: false,
        hasController: apiAppraisal.has_controller,
        controllerAppraiser: apiAppraisal.controller_appraiser
            ? apiControllerAppraiserToControllerAppraiser(apiAppraisal.controller_appraiser)
            : null,
        appraiser: apiAppraisal.appraiser ? apiAppraiserToAppraiser(apiAppraisal.appraiser) : null,
        objectUsageType: {
            value: apiAppraisal.object_usage_type.value,
            text: apiAppraisal.object_usage_type.text,
        },
        objectOwnershipType: {
            value: apiAppraisal.object_ownership_type.value,
            text: apiAppraisal.object_ownership_type.text,
            other: apiAppraisal.object_ownership_type.other,
        },
        otherValueConcepts: apiAppraisal.other_value_concepts,
        otherValueConceptsNotes: apiAppraisal.other_value_concepts_notes,
        attachmentTeamInfo: Array.isArray(apiAppraisal.attachment_team_info) ? null : apiAppraisal.attachment_team_info,
        cadastralPlotAddresses: apiAppraisal.cadastral_plot_addresses.map((apiCadastralPlotAddress) =>
            apiCadastralPlotAddressToCadastralPlotAddress(apiCadastralPlotAddress)
        ),
        cadastralDocuments: apiAppraisal.cadastral_documents.map((apiCadastralDocument) =>
            apiCadastralDocumentToCadastralDocument(apiCadastralDocument)
        ),
        termsOfEngagement: apiAppraisal.terms_of_engagement
            ? {
                  fileName: apiAppraisal.terms_of_engagement.file_name,
                  url: apiAppraisal.terms_of_engagement.url,
              }
            : {
                  fileName: null,
                  url: null,
              },
        precheckReport: apiAppraisal.precheck_report ? apiFileToFile(apiAppraisal.precheck_report) : null,
        isEditableAppraisal: apiAppraisal.is_editable_appraisal,
        reportNumber: apiAppraisal.report_number,
        invoiceFlow: apiAppraisal?.invoice_flow ?? null,
        activatedOptionalCosts: apiAppraisal.activated_optional_costs ?? null,
    };
}

export function apiAppraiserToAppraiser(apiAppraiser: ApiControllerAppraiser): Appraiser {
    return {
        salutation: apiAppraiser.salutation ?? null,
        initials: apiAppraiser.initials ?? null,
        firstName: apiAppraiser.first_name ?? null,
        lastName: apiAppraiser.last_name ?? null,
        rtNumber: apiAppraiser.rt_number ?? null,
        companyName: apiAppraiser.company_name ?? null,
        city: apiAppraiser.city ?? null,
        email: apiAppraiser.email ?? null,
        isPriorAppraiser: apiAppraiser.is_prior_appraiser ?? null,
    };
}

export function apiControllerAppraiserToControllerAppraiser(apiAppraiser: ApiControllerAppraiser): ControllerAppraiser {
    return {
        ...apiAppraiserToAppraiser(apiAppraiser),
        isInvited: apiAppraiser?.is_invited ?? false,
    };
}
