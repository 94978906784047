import {ReferenceObject} from '../models/reference_object';
import {ReferenceObjectAnswer} from '../models/reference_object_answer';
import {buildImages} from '../../interactors/images';
import {ReferenceSaleImage} from '../../v1/models/reference_sale_image';

export function referenceObjectImages(
    referenceObject: ReferenceObject | null,
    referenceObjectAnswer: ReferenceObjectAnswer | null
): ReferenceSaleImage[] {
    if (referenceObjectAnswer !== null) {
        return buildImages(
            referenceObjectAnswer.uploadedPhotoUrl,
            referenceObjectAnswer.frontview ?? null,
            referenceObjectAnswer.frontviewImage ?? null,
            referenceObjectAnswer.referenceObject.adres.straat,
            referenceObjectAnswer.referenceObject.adres.huisnummer,
            referenceObjectAnswer.referenceObject.adres.plaats,
            referenceObject?.source ?? null,
            referenceObjectAnswer.images ?? null,
            referenceObjectAnswer.imageUrlPairs ?? null,
            referenceObject?.images ?? null,
            referenceObjectAnswer.saleQuarter ?? referenceObject?.saleQuarter ?? null
        );
    } else if (referenceObject !== null) {
        return buildImages(
            null,
            null,
            referenceObject.frontview,
            referenceObject.street,
            referenceObject.houseNumber,
            referenceObject.city,
            referenceObject.source,
            null,
            null,
            referenceObject.images,
            referenceObject.saleQuarter
        );
    }

    return [];
}
