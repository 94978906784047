import * as React from 'react';

import {Route, RouteComponentProps, Switch, useHistory, useRouteMatch} from 'react-router';

import {Appraisal} from '../../models/appraisal';
import {AppraisalPresenter} from './appraisal_presenter';
import {AppraisalRecentUserActions} from './global/appraisal_recent_user_actions';
import {AppraiseSecondary} from './appraise_secondary/appraise_secondary';
import {Boot} from './boot';
import {BuildingCostsAdvice} from './content/advice/building_costs_advice';
import {Camera} from './content/camera';
import {CameraProgress} from './content/camera_presenter';
import {ClientFiles} from './content/client_files/client_files';
import {ConstructionDefectsButton} from './content/construction_defects_button';
import {Content} from './content/content';
import {DetailName} from '../../enum/detail_name';
import {EndButton} from './container/end_button';
import {FloorContent} from './content/floor_content';
import {FloorType} from '../../enum/floor_type';
import {Loader} from './components/loader';
import {MessagesStack} from './global/messages_stack';
import {ModalsStack} from './content/modals_stack/modals_stack';
import {NextButton} from './container/next_button';
import {Notes} from './content/notes';
import {NotificationPill} from './components/notification_pill/notification_pill';
import {OnMountSaver} from './components/on_mount_saver';
import {PagePartContent} from './content/page_part_content';
import {PagePartsSet} from '../../models/page_parts_set';
import {QuestionSet} from '../../models/question_set';
import {QuestionType} from '../../enum/question_type';
import {RenderingContextType} from '../../enum/rendering_context_type';
import {Sidebar} from './components/sidebar';
import {SidebarItemForQuestionProvider} from '../../business/sidebar_item_for_question_provider';
import {SidebarItemsProvider} from '../../business/sidebar_items_provider';
import {ValidationError} from '../../models/validation_error';
import {ValidationErrorsPage} from './content/validation_errors_page';
import {ValidationInstituteValidationErrorsPage} from './content/validation_institute_validation_errors_page';
import {ValidationMessageMap} from '../../business/validation/validation_message';
import {classNames} from '../../../support/classnames';
import {AutosaveStatus} from '../../enum/autosave_status';
import {Export} from './debug/export';
import {Import} from './debug/import';
import {usePresenter} from '../../../support/presenter/use_presenter';
import {observer} from 'mobx-react';
import {TreeViewer} from './components/tree_viewer/tree_viewer';
import {AppraisalCopy} from './content/appraisal_copy';
import {QuestionRenderingData} from '../../models/question_rendering_data';
import {PhotoRequirementsChecklist} from './content/photo_requirements_checklist/photo_requirements_checklist';
import {SidebarTabs} from './components/sidebar_tabs';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet | null;
    onChange: (appraisal: Appraisal) => void;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;
    hiddenQuestionTypes: QuestionType[];
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export const AppraisalComponent: React.FC<OwnProps> = observer(function AppraisalComponent(props) {
    const history = useHistory();
    const match = useRouteMatch();
    const presenter = usePresenter(
        (container) =>
            new AppraisalPresenter(
                props.appraisal,
                props.questionSet,
                props.renderingContext,
                container.globalProvider,
                container.ui.appraisalNavigator,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.business.photoAnswerRetryInteractor(props.appraisal, props.questionSet),
                container.business.sidebarTreeBuilder(
                    props.appraisal,
                    props.questionSet,
                    props.pagePartsSet,
                    props.renderingContext
                ),
                new SidebarItemForQuestionProvider(
                    props.questionSet,
                    new SidebarItemsProvider(
                        container.business.sidebarTreeBuilder(
                            props.appraisal,
                            props.questionSet,
                            props.pagePartsSet,
                            props.renderingContext
                        )
                    )
                ),
                container.business.userInteractor,
                container.business.appraiseSecondaryConfigStackInteractor,
                container.business.referenceObjectsProvider(props.appraisal, props.questionSet),
                container.business.appraisalProvider,
                container.business.buildingInspectionAppointmentInteractor(props.appraisal, props.questionSet),
                container.business.valuationDateProvider(props.appraisal, props.questionSet)
            )
    );

    function renderFloorContent(floorType: FloorType, routeProps: RouteComponentProps<{iteration: string}>) {
        return (
            <FloorContent
                key={routeProps.match.params.iteration}
                floorType={floorType}
                appraisal={props.appraisal}
                iteration={routeProps.match.params.iteration}
                questionSet={props.questionSet}
                validationErrors={props.validationErrors}
                validationMessages={props.validationMessages}
                forceShowValidationMessages={props.forceShowValidationMessages}
                hiddenQuestionTypes={props.hiddenQuestionTypes}
                pagePartsSet={props.pagePartsSet}
                activePagePart={null}
                renderingContext={props.renderingContext}
                questionRenderingData={props.questionRenderingData}
            />
        );
    }

    function renderAutosaveFooter() {
        if (!presenter.shouldAutosave) {
            return null;
        }

        return (
            <div className="row mb-4">
                <div className="align-self-end">
                    <div
                        className={classNames('alert-inline', {
                            'alert-success': presenter.autosaveStatus === AutosaveStatus.SAVED,
                            'alert-hint': presenter.autosaveStatus !== AutosaveStatus.SAVED,
                            'ion-md-checkmark-circle': presenter.autosaveStatus === AutosaveStatus.SAVED,
                            'ion-md-information-circle': presenter.autosaveStatus !== AutosaveStatus.SAVED,
                        })}
                    >
                        {presenter.autosaveStatus === AutosaveStatus.NOT_SAVED
                            ? 'Je wijzigingen zijn nog niet opgeslagen.'
                            : presenter.autosaveStatus === AutosaveStatus.SAVED
                            ? 'Je wijzigingen zijn opgeslagen.'
                            : 'Opslaan...'}
                    </div>
                </div>
            </div>
        );
    }

    const urlPath = match.path === '/' ? '' : match.path;
    let footer = presenter.global.inline !== true ? renderFooter() : null;

    const sidebarTabs = presenter.shouldShowTabbar ? (
        <SidebarTabs
            key={props.pagePartsSet?.id}
            appraisal={props.appraisal}
            questionSet={props.questionSet}
            pagePartsSet={props.pagePartsSet}
            validationErrors={props.validationErrors}
            renderingContext={props.renderingContext}
        />
    ) : null;

    function renderFooter() {
        const footerButtonClassNames = classNames('fancy-buttons', {
            'has-camera': presenter.shouldShowCamera,
            'has-notes': presenter.shouldShowNotes,
            'has-construction-defects': presenter.shouldShowConstructionDefects,
        });

        return (
            <>
                {renderAutosaveFooter()}
                <footer className="d-flex justify-content-end">
                    <div className="d-flex justify-content-end w-100 overflow-hidden">
                        <EndButton global={presenter.global} />
                        <NextButton
                            global={presenter.global}
                            history={history}
                            appraisal={props.appraisal}
                            questionSet={props.questionSet}
                            pagePartsSet={props.pagePartsSet}
                            validationErrors={props.validationErrors}
                            renderingContext={props.renderingContext}
                        />
                    </div>
                    {presenter.shouldShowCamera ||
                    presenter.shouldShowNotes ||
                    presenter.shouldShowConstructionDefects ? (
                        <div className={footerButtonClassNames}>
                            <div className="fancy-buttons-container">
                                {presenter.shouldShowCamera ? (
                                    <Camera
                                        history={history}
                                        appraisal={props.appraisal}
                                        questionSet={props.questionSet}
                                        validationErrors={props.validationErrors}
                                        validationMessages={props.validationMessages}
                                        forceShowValidationMessages={props.forceShowValidationMessages}
                                        onProgressChange={(progress: CameraProgress) =>
                                            presenter.onCameraProgressChange(progress)
                                        }
                                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                                        renderingContext={props.renderingContext}
                                        pagePartsSet={props.pagePartsSet}
                                    />
                                ) : null}
                                {presenter.shouldShowNotes ? <Notes appraisal={props.appraisal} /> : null}
                                {presenter.shouldShowConstructionDefects ? (
                                    <ConstructionDefectsButton
                                        appraisal={props.appraisal}
                                        questionSet={props.questionSet}
                                        validationErrors={props.validationErrors}
                                        validationMessages={props.validationMessages}
                                        onProgressChange={(progress: CameraProgress) =>
                                            presenter.onCameraProgressChange(progress)
                                        }
                                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                                        history={history}
                                        renderingContext={props.renderingContext}
                                        questionRenderingData={props.questionRenderingData}
                                    />
                                ) : null}

                                <PhotoRequirementsChecklist
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                />
                            </div>
                        </div>
                    ) : null}
                </footer>
            </>
        );
    }

    function renderRoutes() {
        const isCardClass =
            presenter.global.inline !== true && props.renderingContext !== RenderingContextType.PLAUSIBILITY_CHECK;

        return (
            <Switch>
                <Route
                    exact
                    path={`${urlPath}/validation-messages`}
                    render={() => (
                        <div className={classNames({'card d-flex': isCardClass})}>
                            {sidebarTabs}
                            <OnMountSaver appraisal={props.appraisal} questionSet={props.questionSet}>
                                <ValidationInstituteValidationErrorsPage
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    pagePartsSet={props.pagePartsSet}
                                    validationErrors={props.validationErrors}
                                    renderingContext={props.renderingContext}
                                />
                            </OnMountSaver>
                            {footer}
                        </div>
                    )}
                />
                <Route
                    exact
                    path={`${urlPath}/attic/:iteration`}
                    render={(routeProps: RouteComponentProps<{iteration: string}>) => (
                        <div className={classNames({'card d-flex': isCardClass})}>
                            {sidebarTabs}
                            <OnMountSaver appraisal={props.appraisal} questionSet={props.questionSet}>
                                {renderFloorContent(FloorType.ATTIC, routeProps)}
                            </OnMountSaver>
                            {footer}
                        </div>
                    )}
                />
                <Route
                    exact
                    path={`${urlPath}/floor/:iteration`}
                    render={(routeProps: RouteComponentProps<{iteration: string}>) => (
                        <div className={classNames({'card d-flex': isCardClass})}>
                            {sidebarTabs}
                            <OnMountSaver appraisal={props.appraisal} questionSet={props.questionSet}>
                                {renderFloorContent(FloorType.FLOOR, routeProps)}
                            </OnMountSaver>
                            {footer}
                        </div>
                    )}
                />
                <Route
                    exact
                    path={`${urlPath}/ground/:iteration`}
                    render={(routeProps: RouteComponentProps<{iteration: string}>) => (
                        <div className={classNames({'card d-flex': isCardClass})}>
                            {sidebarTabs}
                            <OnMountSaver appraisal={props.appraisal} questionSet={props.questionSet}>
                                {renderFloorContent(FloorType.GROUND, routeProps)}
                            </OnMountSaver>
                            {footer}
                        </div>
                    )}
                />
                <Route
                    exact
                    path={`${urlPath}/basement/:iteration`}
                    render={(routeProps: RouteComponentProps<{iteration: string}>) => (
                        <div className={classNames({'card d-flex': isCardClass})}>
                            {sidebarTabs}
                            <OnMountSaver appraisal={props.appraisal} questionSet={props.questionSet}>
                                {renderFloorContent(FloorType.BASEMENT, routeProps)}
                            </OnMountSaver>
                            {footer}
                        </div>
                    )}
                />
                <Route
                    path={`${urlPath}/validation-page`}
                    render={(routeProps: RouteComponentProps<Record<string, string | undefined>>) => (
                        <div className={classNames({'card d-flex': isCardClass})}>
                            {sidebarTabs}
                            <OnMountSaver appraisal={props.appraisal} questionSet={props.questionSet}>
                                <ValidationErrorsPage
                                    global={presenter.global}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    pagePartsSet={props.pagePartsSet}
                                    activePagePart={null}
                                    validationErrors={props.validationErrors}
                                    validationMessages={props.validationMessages}
                                    renderingContext={props.renderingContext}
                                    forceShowValidationMessages={props.forceShowValidationMessages}
                                    questionRenderingData={props.questionRenderingData}
                                    {...routeProps}
                                />
                            </OnMountSaver>
                            {footer}
                        </div>
                    )}
                />
                <Route
                    exact
                    path={`${urlPath}/advice-building-costs`}
                    render={() => (
                        <div className={classNames({'card d-flex': isCardClass})}>
                            {sidebarTabs}
                            <OnMountSaver appraisal={props.appraisal} questionSet={props.questionSet}>
                                <BuildingCostsAdvice
                                    global={presenter.global}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    validationErrors={props.validationErrors}
                                    validationMessages={props.validationMessages}
                                    forceShowValidationMessages={props.forceShowValidationMessages}
                                    onChange={props.onChange}
                                    pagePartsSet={props.pagePartsSet}
                                    activePagePart={null}
                                    history={history}
                                    renderingContext={props.renderingContext}
                                    questionRenderingData={props.questionRenderingData}
                                />
                            </OnMountSaver>
                            {footer}
                        </div>
                    )}
                />
                <Route
                    exact
                    path={`${urlPath}/page-part/:uuid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})`}
                    render={(routeProps: RouteComponentProps<{uuid: string}>) => {
                        return (
                            <div className={classNames({'card d-flex': isCardClass})}>
                                {sidebarTabs}
                                <OnMountSaver
                                    key={routeProps.match.params.uuid}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                >
                                    <PagePartContent
                                        key={routeProps.match.params.uuid}
                                        global={presenter.global}
                                        appraisal={props.appraisal}
                                        pagePartsUuid={routeProps.match.params.uuid}
                                        iteration={undefined}
                                        questionSet={props.questionSet}
                                        validationErrors={props.validationErrors}
                                        validationMessages={props.validationMessages}
                                        onChange={props.onChange}
                                        pagePartsSet={props.pagePartsSet}
                                        renderingContext={props.renderingContext}
                                        forceShowValidationMessages={props.forceShowValidationMessages}
                                        questionRenderingData={props.questionRenderingData}
                                    />
                                </OnMountSaver>
                                {footer}
                            </div>
                        );
                    }}
                />
                <Route
                    exact
                    path={`${urlPath}/:uuid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})`}
                    render={(routeProps: RouteComponentProps<{uuid: string}>) => (
                        <div className={classNames({'card d-flex': presenter.global.inline !== true})}>
                            {sidebarTabs}
                            <OnMountSaver
                                key={routeProps.match.params.uuid}
                                appraisal={props.appraisal}
                                questionSet={props.questionSet}
                            >
                                <Content
                                    key={routeProps.match.params.uuid}
                                    global={presenter.global}
                                    appraisal={props.appraisal}
                                    uuid={routeProps.match.params.uuid}
                                    questionSet={props.questionSet}
                                    validationErrors={props.validationErrors}
                                    validationMessages={props.validationMessages}
                                    forceShowValidationMessages={props.forceShowValidationMessages}
                                    onChange={props.onChange}
                                    activePagePart={null}
                                    pagePartsSet={props.pagePartsSet}
                                    history={history}
                                    renderingContext={props.renderingContext}
                                    questionRenderingData={props.questionRenderingData}
                                />
                            </OnMountSaver>
                            {footer}
                        </div>
                    )}
                />
                <Route
                    exact
                    path={`${urlPath}/:uuid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/:iteration`}
                    render={(routeProps: RouteComponentProps<{uuid: string; iteration: string}>) => (
                        <div className={classNames({'card d-flex': presenter.global.inline !== true})}>
                            {sidebarTabs}
                            <OnMountSaver
                                key={routeProps.match.params.uuid + '[' + routeProps.match.params.iteration + ']'}
                                appraisal={props.appraisal}
                                questionSet={props.questionSet}
                            >
                                <Content
                                    key={routeProps.match.params.uuid + '[' + routeProps.match.params.iteration + ']'}
                                    global={presenter.global}
                                    appraisal={props.appraisal}
                                    uuid={routeProps.match.params.uuid}
                                    iteration={routeProps.match.params.iteration}
                                    questionSet={props.questionSet}
                                    validationErrors={props.validationErrors}
                                    validationMessages={props.validationMessages}
                                    forceShowValidationMessages={props.forceShowValidationMessages}
                                    onChange={props.onChange}
                                    activePagePart={null}
                                    pagePartsSet={props.pagePartsSet}
                                    history={history}
                                    renderingContext={props.renderingContext}
                                    questionRenderingData={props.questionRenderingData}
                                />
                            </OnMountSaver>
                            {footer}
                        </div>
                    )}
                />
                <Route
                    exact
                    path={`${urlPath}/boot`}
                    render={() => (
                        <div className={classNames({'card d-flex': presenter.global.inline !== true})}>
                            {sidebarTabs}
                            <OnMountSaver appraisal={props.appraisal} questionSet={props.questionSet}>
                                <Boot
                                    key="boot"
                                    global={presenter.global}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    validationErrors={props.validationErrors}
                                    validationMessages={props.validationMessages}
                                    forceShowValidationMessages={props.forceShowValidationMessages}
                                    onChange={props.onChange}
                                    history={history}
                                    pagePartsSet={props.pagePartsSet}
                                    renderingContext={props.renderingContext}
                                    questionRenderingData={props.questionRenderingData}
                                />
                            </OnMountSaver>
                            {footer}
                        </div>
                    )}
                />
                <Route
                    exact
                    path={`${urlPath}/debug/export`}
                    render={() => (
                        <div className={classNames({'card d-flex': presenter.global.inline !== true})}>
                            {sidebarTabs}
                            <Export appraisal={props.appraisal} questionSet={props.questionSet} />
                        </div>
                    )}
                />
                <Route
                    exact
                    path={`${urlPath}/debug/import`}
                    render={() => (
                        <div className={classNames({'card d-flex': presenter.global.inline !== true})}>
                            {sidebarTabs}
                            <Import appraisal={props.appraisal} questionSet={props.questionSet} />
                        </div>
                    )}
                />
                <Route
                    exact
                    path={`${urlPath}/appraisal-copy`}
                    render={() => (
                        <div className={classNames({'card d-flex': presenter.global.inline !== true})}>
                            {sidebarTabs}
                            <AppraisalCopy
                                appraisal={props.appraisal}
                                questionSet={props.questionSet}
                                pagePartsSet={props.pagePartsSet}
                                renderingContext={props.renderingContext}
                            />
                        </div>
                    )}
                />
                <Route
                    render={() => {
                        if (process.env.NODE_ENV === 'development') {
                            console.warn('Not found in appraisal.tsx (no route matched?)');
                        }
                        return props.renderingContext !== RenderingContextType.APPRAISAL ? (
                            <div className={classNames({'card d-flex': presenter.global.inline !== true})}>
                                {sidebarTabs}{' '}
                                <div className="empty-message appraise">
                                    <h3>Helaas, deze pagina is niet gevonden (404)</h3>
                                    <span className="spacer-gradient">&nbsp;</span>
                                </div>
                            </div>
                        ) : null;
                    }}
                />
            </Switch>
        );
    }

    if (presenter.global.detailName === DetailName.CLIENT_FILES) {
        footer = null;

        return (
            <ClientFiles
                history={history}
                global={presenter.global}
                appraisal={props.appraisal}
                onChange={props.onChange}
                questionSet={props.questionSet}
                validationErrors={props.validationErrors}
                validationMessages={props.validationMessages}
                forceShowValidationMessages={props.forceShowValidationMessages}
                hiddenQuestionTypes={props.hiddenQuestionTypes}
                activePagePart={null}
                pagePartsSet={props.pagePartsSet}
                renderingContext={props.renderingContext}
                questionRenderingData={props.questionRenderingData}
            >
                {renderRoutes()}
            </ClientFiles>
        );
    }

    if (presenter.photoAnswerRetryStatus === null || presenter.photoAnswerRetryStatus.queued > 0) {
        return (
            <div className="empty-message appraise">
                <Loader />
                <h3>
                    {presenter.photoAnswerRetryStatus === null
                        ? 'Antwoorden synchroniseren...'
                        : `Foto's aan het uploaden... ${presenter.photoAnswerRetryStatus.done}/${presenter.photoAnswerRetryStatus.total}`}
                </h3>
            </div>
        );
    }

    return (
        <>
            {process.env.NODE_ENV === 'development' &&
                history.location.search.includes('tree-viewer') === true &&
                props.pagePartsSet !== null && (
                    <TreeViewer
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        pagePartsSet={props.pagePartsSet}
                    />
                )}
            <ModalsStack
                appraisal={props.appraisal}
                questionSet={props.questionSet}
                validationErrors={props.validationErrors}
                hiddenQuestionTypes={props.hiddenQuestionTypes}
                renderingContext={props.renderingContext}
                questionRenderingData={props.questionRenderingData}
            />
            <AppraiseSecondary
                appraisal={props.appraisal}
                validationErrors={props.validationErrors}
                pagePartsSet={props.pagePartsSet}
                renderingContext={props.renderingContext}
                questionRenderingData={props.questionRenderingData}
            />
            <span id="appraising-loaded" />
            {presenter.global.inline !== true && (
                <div className="card-badge-container d-flex align-items-center">
                    <NotificationPill appraisal={props.appraisal} questionSet={props.questionSet} />
                    <AppraisalRecentUserActions appraisal={props.appraisal} />
                </div>
            )}
            <MessagesStack
                history={history}
                appraisal={props.appraisal}
                questionSet={props.questionSet}
                pagePartsSet={props.pagePartsSet}
                validationErrors={props.validationErrors}
                renderingContext={props.renderingContext}
                cameraProgress={presenter.cameraProgress}
            />
            {presenter.shouldShowSidebar && (
                <Sidebar
                    key={props.pagePartsSet?.id}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    pagePartsSet={props.pagePartsSet}
                    validationErrors={props.validationErrors}
                    renderingContext={props.renderingContext}
                />
            )}
            {renderRoutes()}
        </>
    );
});
