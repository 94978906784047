import {AppraisalState} from '../appraising/enum/appraisal_state';
import {DetailName} from '../appraising/enum/detail_name';
import {FloorSorting} from '../enums/floor_sorting';
import {PhotoSorting} from '../enums/photo_sorting';
import {SurfaceInputMethod} from '../enums/surface_input_method';
import {UserType} from '../appraising/enum/user_type';
import {UserWidget} from '../appraising/enum/user_widget';
import {ApiBuildingInspectorInfo, BuildingInspectorInfo} from '../appraising/network/building_inspectors_api';
import {Macro} from '../appraising/models/macro';
import {ApiMacro, apiMacroToMacro} from '../appraising/network/models/api_macro';
import {ReferenceSubscriptions} from '../appraising/models/reference_subscriptions';
import {
    ApiAppraisalCostLine,
    AppraisalCostLine,
    AutomatorDispatchGroup,
    apiAppraisalCostLineToAppraisalCostLine,
} from '../create_appraisal/preferences/appraisal_costs';
import {District} from '../appraising/models/district';
import {ApiSustainabilityInfo, SustainabilityInfo} from '../appraising/network/sustainability_api';
import {ReferencePreferences} from '../appraising/models/reference_preferences';

export interface ApiGlobal {
    state: AppraisalState;
    //If the appraisal area is rendered inline, for example for the client attachments
    inline?: boolean;
    //Link to the appraisals dashboard
    appraisals_url: string;
    // Link to appraise environment
    appraise_url?: string;
    //Simple back button url
    back_url: string;
    //Url where the user should be redirected to after the very last question
    //or in the case of enriching by client, the page that registers that it is already enriched
    conclusion_url?: string;
    //Define the user can go to the validation page
    configuration_url: string;
    //Optional url for page parts configuration
    gate_allows_validation?: boolean;
    gate_allows_change_audit_trail?: boolean;
    gate_allows_text_ai?: boolean;
    object_image_url?: string;
    detail_name?: DetailName | null;
    user_type?: UserType;
    user_id?: number;
    original_user_id?: number | null;
    building_inspector_subscription: 'yearly' | 'monthly' | 'single' | false;
    building_inspector_info?: ApiBuildingInspectorInfo;
    sustainability_info?: ApiSustainabilityInfo;
    client_extra_files_enabled?: boolean;
    question_uuid?: string | null;
    parent_answer_uuid?: string | null;
    attachments_checked_by?: string | null;
    user_widgets?: UserWidget[];
    object_energy_label?: string;
    external_advice_count?: number;
    floor_sorting?: FloorSorting;
    photo_sorting?: PhotoSorting;
    google_maps_api_key?: string | null;
    user_surface_input_method: SurfaceInputMethod;
    external_macros?: {[externalType: string]: ApiMacro[]};
    reference_subscriptions?: ReferenceSubscriptions | null;
    is_references_comparison_modal_enabled?: boolean | null;
    triggerable_automator_groups?:
        | {
              cost_lines: ApiAppraisalCostLine[];
              group: AutomatorDispatchGroup;
              references: string[];
          }[]
        | null;
    is_attachment_preview_widget_enabled?: boolean | null;
    user_districts?: District[];
    appraisal_district?: District | null;
    rate_additional_kadaster_excerpt?: number | null;
    reference_preferences?: ReferencePreferences | null;
    core_tasks_preferences?: {
        afterSalesTermsDisabled: boolean;
        afterSalesReportDisabled: boolean;
    };
}
declare const GLOBAL: ApiGlobal;

export interface Global {
    state: AppraisalState;
    inline: boolean | null;
    appraisalsUrl: string;
    appraiseUrl: string | null;
    backUrl: string;
    conclusionUrl: string | null;
    configurationUrl: string | null;
    gateAllowsValidation: boolean | null;
    gateAllowsChangeAuditTrail: boolean | null;
    gateAllowsTextAI: boolean | null;
    objectImageUrl: string | null;
    detailName: DetailName | null;
    userType: UserType | null;
    userId: number | null;
    originalUserId: number | null;
    buildingInspectorSubscription: 'yearly' | 'monthly' | 'single' | false;
    buildingInspectorInfo: BuildingInspectorInfo;
    sustainabilityInfo: SustainabilityInfo;
    clientExtraFilesEnabled: boolean | null;
    questionUuid: string | null;
    attachmentsCheckedBy: string | null;
    userWidgets: UserWidget[];
    objectEnergyLabel: string | null;
    externalAdviceCount: number | null;
    floorSorting: FloorSorting;
    photoSorting: PhotoSorting;
    externalMacros: {[externalType: string]: Macro[]};
    referenceSubscriptions: ReferenceSubscriptions | null;
    isReferencesComparisonModalEnabled: boolean | null;
    triggerableAutomatorGroups:
        | {
              costLines: AppraisalCostLine[];
              group: AutomatorDispatchGroup;
              references: string[];
          }[]
        | null;
    isAttachmentPreviewWidgetEnabled: boolean | null;
    userDistricts: District[];
    appraisalDistrict: District | null;
    rateAdditionalKadasterExcerpt: number | null;
    referencePreferences: ReferencePreferences | null;
    coreTasksPreferences: {
        afterSalesTermsDisabled: boolean;
        afterSalesReportDisabled: boolean;
    } | null;
}

export interface GlobalProvider {
    global: Global;
    hasGlobal: boolean;
}

export class DefaultGlobalProvider implements GlobalProvider {
    private _global: Global | null = null;
    public get global(): Global {
        return (this._global ??= {
            state: GLOBAL.state,
            inline: GLOBAL.inline ?? null,
            appraisalsUrl: GLOBAL.appraisals_url,
            appraiseUrl: GLOBAL.appraise_url ?? null,
            backUrl: GLOBAL.back_url,
            conclusionUrl: GLOBAL.conclusion_url ?? null,
            configurationUrl: GLOBAL.configuration_url ?? null,
            gateAllowsValidation: GLOBAL.gate_allows_validation ?? null,
            gateAllowsChangeAuditTrail: GLOBAL.gate_allows_change_audit_trail ?? null,
            gateAllowsTextAI: GLOBAL.gate_allows_text_ai ?? null,
            objectImageUrl: GLOBAL.object_image_url ?? null,
            detailName: GLOBAL.detail_name ?? null,
            userType: GLOBAL.user_type ?? null,
            userId: GLOBAL.user_id ?? null,
            originalUserId: GLOBAL.original_user_id ?? null,
            buildingInspectorSubscription: GLOBAL.building_inspector_subscription ?? false,
            buildingInspectorInfo: {
                singleCostsCents: GLOBAL.building_inspector_info?.single_costs_cents ?? 0,
                monthlyCostsCents: GLOBAL.building_inspector_info?.monthly_costs_cents ?? 0,
                termsUrl: GLOBAL.building_inspector_info?.terms_url ?? null,
            },
            sustainabilityInfo: {
                activeSubscriptionFrequency: GLOBAL.sustainability_info?.active_subscription_frequency ?? null,
                singleCostsCents: GLOBAL.sustainability_info?.single_costs_cents ?? 0,
                monthlyCostsCents: GLOBAL.sustainability_info?.monthly_costs_cents ?? 0,
                totalUsageCount: GLOBAL.sustainability_info?.total_usage_count ?? 0,
                appraisalUsageCount: GLOBAL.sustainability_info?.appraisal_usage_count ?? 0,
            },
            clientExtraFilesEnabled: GLOBAL.client_extra_files_enabled ?? null,
            questionUuid: GLOBAL.question_uuid ?? null,
            attachmentsCheckedBy: GLOBAL.attachments_checked_by ?? null,
            userWidgets: GLOBAL.user_widgets ?? [],
            objectEnergyLabel: GLOBAL.object_energy_label ?? null,
            externalAdviceCount: GLOBAL.external_advice_count ?? null,
            floorSorting: GLOBAL.floor_sorting ?? FloorSorting.TOP_DOWN,
            photoSorting: GLOBAL.photo_sorting ?? PhotoSorting.TAXAPI_ORDER,
            externalMacros: Object.fromEntries(
                Object.entries(GLOBAL.external_macros ?? {}).map(([id, val]) => [
                    id,
                    val.map((m) => apiMacroToMacro(m)),
                ])
            ),
            referenceSubscriptions: GLOBAL.reference_subscriptions ?? null,
            isReferencesComparisonModalEnabled: GLOBAL.is_references_comparison_modal_enabled ?? null,
            triggerableAutomatorGroups:
                GLOBAL.triggerable_automator_groups?.map((group) => ({
                    group: group.group,
                    references: group.references,
                    costLines: group.cost_lines.map((line) => apiAppraisalCostLineToAppraisalCostLine(line)),
                })) ?? null,
            isAttachmentPreviewWidgetEnabled: GLOBAL.is_attachment_preview_widget_enabled ?? null,
            userDistricts: GLOBAL.user_districts ?? [],
            appraisalDistrict: GLOBAL.appraisal_district ?? null,
            rateAdditionalKadasterExcerpt: GLOBAL.rate_additional_kadaster_excerpt ?? null,
            referencePreferences: GLOBAL.reference_preferences ?? null,
            coreTasksPreferences: GLOBAL.core_tasks_preferences ?? null,
        });
    }

    public get hasGlobal(): boolean {
        return typeof GLOBAL !== 'undefined';
    }
}
