import * as React from 'react';

import {Appraisal} from '../../../models/appraisal';
import {ContentPresenter} from './content_presenter';
import {ErrorBoundary} from '../../../components/error_boundary';
import {Global} from '../../../../business/global_provider';
import {History} from 'history';
import {PagePart} from '../../../models/page_part';
import {PagePartsSet} from '../../../models/page_parts_set';
import {QuestionContainer} from './questions/question_container';
import {QuestionSet} from '../../../models/question_set';
import {RenderingContextType} from '../../../enum/rendering_context_type';
import {SidebarItemForQuestionProvider} from '../../../business/sidebar_item_for_question_provider';
import {SidebarItemsProvider} from '../../../business/sidebar_items_provider';
import {ValidationError} from '../../../models/validation_error';
import {ValidationMessageMap} from '../../../business/validation/validation_message';
import {ValidationMessagesList} from './validation_messages_list';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../support/presenter/use_presenter';
import {QuestionRenderingData} from '../../../models/question_rendering_data';

interface OwnProps {
    history: History;
    uuid: string;
    global: Global;
    appraisal: Appraisal;
    onChange: (appraisal: Appraisal) => void;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;
    iteration?: string;
    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export const Content: React.FC<OwnProps> = observer(function Content(props) {
    const presenter = usePresenter(
        (container) =>
            new ContentPresenter(
                props.uuid,
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.childQuestionValidationProvider(
                    props.appraisal,
                    props.questionSet,
                    props.validationErrors
                ),
                props.history,
                new SidebarItemForQuestionProvider(
                    props.questionSet,
                    new SidebarItemsProvider(
                        container.business.sidebarTreeBuilder(
                            props.appraisal,
                            props.questionSet,
                            props.pagePartsSet,
                            props.renderingContext
                        )
                    )
                )
            )
    );

    if (presenter.question === undefined) {
        if (process.env.NODE_ENV === 'development') {
            console.warn('Not found in content.tsx (question === undefined)');
        }
        return (
            <div className="empty-message appraise">
                <h3>Helaas, deze pagina is niet gevonden (404)</h3>
                <span className="spacer-gradient">&nbsp;</span>
            </div>
        );
    }

    return (
        <ErrorBoundary>
            <span>
                {props.global.inline !== true ? <div className="card-header">{presenter.question.contents}</div> : null}
                <div className="card-body">
                    <ValidationMessagesList validationMessages={presenter.validationMessages} />
                    <QuestionContainer
                        key={presenter.question.uuid}
                        appraisal={props.appraisal}
                        onChange={props.onChange}
                        question={presenter.question}
                        questionSet={props.questionSet}
                        validationErrors={props.validationErrors}
                        validationMessages={props.validationMessages}
                        forceShowValidationMessages={props.forceShowValidationMessages}
                        iteration={props.iteration}
                        hiddenQuestionTypes={[]}
                        pagePartsSet={props.pagePartsSet}
                        activePagePart={props.activePagePart}
                        renderingContext={props.renderingContext}
                        questionRenderingData={props.questionRenderingData}
                    />
                </div>
            </span>
        </ErrorBoundary>
    );
});
