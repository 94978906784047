import * as React from 'react';

import {Appraisal} from '../../../models/appraisal';
import {FC} from 'react';
import {PagePartsSet} from '../../../models/page_parts_set';
import {QuestionSet} from '../../../models/question_set';
import {RenderingContextType} from '../../../enum/rendering_context_type';
import {SidebarPresenter} from './sidebar_presenter';
import {ValidationError} from '../../../models/validation_error';
import {observer} from 'mobx-react-lite';
import {usePresenter} from '../../../../support/presenter/use_presenter';
import {SidebarTabItem} from './sidebar_tabs/sidebar_tab_item';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet | null;
    validationErrors: ValidationError[];
    renderingContext: RenderingContextType;
}

export const SidebarTabs: FC<OwnProps> = observer(
    ({appraisal, questionSet, pagePartsSet, renderingContext, validationErrors}) => {
        const presenter = usePresenter(
            (component) =>
                new SidebarPresenter(
                    component.business.sidebarTreeBuilder(appraisal, questionSet, pagePartsSet, renderingContext)
                )
        );

        const sidebarItems = presenter.sidebarItems;

        return (
            <div className="card-progress-tabs">
                <div className="nav nav-tabs">
                    {sidebarItems.map((sidebarMenuItem) => (
                        <SidebarTabItem
                            key={sidebarMenuItem.link}
                            sidebarItem={sidebarMenuItem}
                            pagePartsSet={pagePartsSet}
                            appraisal={appraisal}
                            questionSet={questionSet}
                            validationErrors={validationErrors}
                            renderingContext={renderingContext}
                            widthPercentage={Math.round(10000 / sidebarItems.length) / 100}
                        />
                    ))}
                </div>
            </div>
        );
    }
);
